import { getToken } from "./apiHelper";
import api from "./api";

export const getWorkOrder = async (pageIndex, pageSize, body) => {
  try {
    let axiosConfig = {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
      params: {
        PageIndex: pageIndex,
        PageSize: pageSize,
      },
    };
    const response = await api.post(`/WorkOrders/getlist`, body, axiosConfig);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const addWorkOrder = async (data) => {
  try {
    const response = await api.post(`/WorkOrders`, data);

    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};
export const updateWorkOrder = async (data) => {
  try {
    const response = await api.put(`/WorkOrders`, data);

    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};
export const deleteWorkOrder = async (id) => {
  try {
    const response = await api.delete(`/WorkOrders/` + id);

    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};
export const addWorkOrderRoutes = async (data) => {
  try {
    const response = await api.post(`/WorkOrderRoutes`, data);

    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};
export const deleteWorkOrderRoutes = async (id) => {
  try {
    const response = await api.delete(`/WorkOrderRoutes/` + id);

    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};
export const deleteAllWorkOrderRoutes = async (id) => {
  try {
    const response = await api.delete(
      `/WorkOrderRoutes/DeleteRoutesByWorkOrderId`,
      {
        data: {
          workOrderId: id,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const updateWorkOrderRoutes = async (data) => {
  try {
    let axiosConfig = {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    };

    const response = await api.put(`/WorkOrderRoutes`, data, axiosConfig);

    return response.data;
  } catch (error) {
    console.error("Login error:", error);
    throw error;
  }
};
