import axios from "axios";
import { getToken } from "./apiHelper";
import api from "./api";

export const getPlanningList = async (pageIndex, pageSize, body) => {
  try {
    let axiosConfig = {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
      params: {
        PageIndex: pageIndex,
        PageSize: pageSize,
      },
    };
    const response = await api.post(
      `/planning/getlistvoucherrowwithdetails`,
      body,
      axiosConfig
    );
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};
export const addPlanningService = async (data) => {
  try {
    const response = await axios.post(`http://localhost:3000/planlama`, data);
    return response.data;
  } catch (error) {
    console.error("Login error:", error);
    throw error;
  }
};
export const updatePlanningService = async (data) => {
  try {
    const response = await axios.put(`http://localhost:3000/planlama`, data);

 
    return response.data;
  } catch (error) {
    console.error("Login error:", error);
    throw error;
  }
};

export const deletePlanningService = async (data) => {
  try {
    const response = await axios.delete(
      `http://localhost:3000/planlama/` + data.id 
    );
 
    return response.data;
  } catch (error) {
    console.error("Login error:", error);
    throw error;
  }
};
