import React, { useState, useEffect, useMemo } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import {
  addStockService,
  updateStockService,
} from "../../services/stocksServices";
import { useSelector } from "react-redux";
import { getCurrentsListService } from "../../services/currentsServices";
import { getCodesService } from "../../services/codesServices";

const defaultFormData = {
  id: null,
  unitCodeId: 0,
  currentId: null,
  typeCodeId: 0,
  sortCodeId: 0,
  qualityCodeId: 0,
  code: "",
  name: "",
  description: "",
};

const StockAddEdit = ({
  visible,
  setVisible,
  dataRow,
  onDialogClose,
  toast,
}) => {
  const isLoading = useSelector((state) => state.loading.isLoading);

  const defaultData = useMemo(() => defaultFormData, []);

  const [formData, setFormData] = useState({});
  const [cariData, setCariData] = useState([]);
  const [types, setTypes] = useState([]);
  const [units, setUnits] = useState([]);
  const [qualitys, setQualitys] = useState([]);
  const [sorts, setSorts] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});

  useEffect(() => {
    if (visible) {
      if (dataRow) {
        setFormData(dataRow);
      } else {
        setFormData(defaultData);
      }
      setValidationErrors({});
    }
  }, [dataRow, visible, defaultData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setValidationErrors({ ...validationErrors, [name]: null });
  };

  const handleDropdownChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
    setValidationErrors({ ...validationErrors, [name]: null });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const errors = validateForm(formData);
      if (Object.keys(errors).length > 0) {
        setValidationErrors(errors);
        return;
      }

      if (dataRow) {
        await updateStockService(formData);
      } else {
        await addStockService(formData);
      }
      toast.current.show({
        severity: "success",
        detail: dataRow
          ? "Stok Başarıyla Güncellendi"
          : "Stok Başarıyla Eklendi",
        life: 3000,
      });
      setVisible(false);
      onDialogClose();
    } catch (error) {
      console.error("Form gönderim hatası:", error);
    }
  };

  const fetchData = async () => {
    try { 
      const result = cariData.length <= 0 ? await getCurrentsListService(0, 1000) : { items: cariData };
      const resultTypes = types.length <= 0 ? await getCodesService(1) : { items: types };
      const resultSorts = sorts.length <= 0 ? await getCodesService(4) : { items: sorts };
      const resultQualitys = qualitys.length <= 0 ? await getCodesService(5) : { items: qualitys };
      const resultUnits = units.length <= 0 ? await getCodesService(6) : { items: units };

      setTypes(resultTypes.items);
      setSorts(resultSorts.items);
      setQualitys(resultQualitys.items);
      setUnits(resultUnits.items);
      setCariData(result.items);
    } catch (error) {
      console.error("Veri alınamadı:", error);
    }
  };

  useEffect(() => {
    if (visible) {
      fetchData();
    }
  }, [visible]);

  const typesOptions = types.map((type) => ({
    label: type.description,
    value: type.id,
  }));

  const unitsOptions = units.map((unit) => ({
    label: unit.description,
    value: unit.id,
  }));

  const qualitysOptions = qualitys.map((quality) => ({
    label: quality.description,
    value: quality.id,
  }));

  const sortsOptions = sorts.map((sort) => ({
    label: sort.description,
    value: sort.id,
  }));

  const validateForm = (data) => {
    let errors = {};

    if (!data.name) {
      errors.name = "Stok Adı gereklidir.";
    }

    if (data.typeCodeId === 0) {
      errors.typeCodeId = "Tip seçilmelidir.";
    }

    if (data.sortCodeId === 0) {
      errors.sortCodeId = "Tür seçilmelidir.";
    }

    if (data.qualityCodeId === 0) {
      errors.qualityCodeId = "Kalite seçilmelidir.";
    }

    if (data.unitCodeId === 0) {
      errors.unitCodeId = "Birim seçilmelidir.";
    }
 

    return errors;
  };

  return (
    <Dialog
      className="max-w-full w-11 md:w-11 xl:w-8"
      header={dataRow ? "Stok Güncelle" : "Stok Ekle"}
      visible={visible}
      onHide={() => setVisible(false)}
    >
      <form className="formgrid grid" onSubmit={handleSubmit}>
        <div className="col-12 md:col-4">
          <div className="p-inputgroup flex-1 w-12 pb-2">
            <span className="p-inputgroup-addon w-7rem">Stok Kodu</span>
            <InputText
              disabled
              id="code"
              name="code"
              value={formData.code}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-12 md:col-8">
          <div className="p-inputgroup flex-1 pb-2">
            <span className="p-inputgroup-addon w-7rem">Stok Adı</span>
            <InputText  disabled={isLoading}
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
            />
            {validationErrors.name && (
              <span className="p-inputgroup-addon w-10rem">
                <small className="p-error">{validationErrors.name}</small>
              </span>
            )}
          </div>
        </div>
        <div className="col-12 md:col-6">
          <div className="p-inputgroup flex-1 pb-2">
            <span className="p-inputgroup-addon w-7rem">Stok Türü</span>
            <Dropdown  disabled={isLoading}
              id="sortCodeId"
              name="sortCodeId"
              value={formData.sortCodeId}
              options={sortsOptions}
              onChange={(e) => handleDropdownChange("sortCodeId", e.value)}
              placeholder="Stok Türü Seçiniz"
            />
            {validationErrors.sortCodeId && (
              <span className="p-inputgroup-addon w-10rem">
              <small className="p-error">{validationErrors.sortCodeId}</small>
            </span> 
            )}
          </div>
        </div>
        <div className="col-12 md:col-6">
          <div className="p-inputgroup flex-1 pb-2">
            <span className="p-inputgroup-addon w-7rem">Stok Tipi</span>
            <Dropdown  disabled={isLoading}
              id="typeCodeId"
              name="typeCodeId"
              value={formData.typeCodeId}
              options={typesOptions}
              onChange={(e) => handleDropdownChange("typeCodeId", e.value)}
              placeholder="Stok Tipi Seçiniz"
            />
            {validationErrors.typeCodeId && (
               <span className="p-inputgroup-addon w-10rem">
               <small className="p-error">{validationErrors.typeCodeId}</small>
             </span>  
            )}
          </div>
        </div>
        <div className="col-12 md:col-6">
          <div className="p-inputgroup flex-1 pb-2">
            <span className="p-inputgroup-addon w-7rem">Kalite</span>
            <Dropdown  disabled={isLoading}
              id="qualityCodeId"
              name="qualityCodeId"
              value={formData.qualityCodeId}
              options={qualitysOptions}
              onChange={(e) => handleDropdownChange("qualityCodeId", e.value)}
              placeholder="Kalite Seçiniz"
            />
            {validationErrors.qualityCodeId && (
            <span className="p-inputgroup-addon w-10rem">
            <small className="p-error">{validationErrors.qualityCodeId}</small>
          </span>  
            )}
          </div>
        </div>
        <div className="col-12 md:col-6">
          <div className="p-inputgroup flex-1 pb-2">
            <span className="p-inputgroup-addon w-7rem">Birim</span>
            <Dropdown  disabled={isLoading}
              id="unitCodeId"
              name="unitCodeId"
              value={formData.unitCodeId}
              options={unitsOptions}
              onChange={(e) => handleDropdownChange("unitCodeId", e.value)}
              placeholder="Birim Seçiniz"
             
            />
            {validationErrors.unitCodeId && (
                <span className="p-inputgroup-addon w-10rem">
                <small className="p-error">{validationErrors.unitCodeId}</small>
              </span>  
            )}
          </div>
        </div>
        <div className="col-12">
          <div className="p-inputgroup flex-1 pb-2">
            <span className="p-inputgroup-addon w-7rem">Cari</span>
            <Dropdown  disabled={isLoading}
              id="currentId"
              name="currentId"
              value={formData.currentId}
              options={cariData.map((item) => ({
                label: item.title,
                value: item.id,
              }))}
              onChange={(e) => handleDropdownChange("currentId", e.value)}
              placeholder="Cari Seçiniz"
              filter
            />
            {validationErrors.currentId && (
                   <span className="p-inputgroup-addon  w-10rem">
                   <small className="p-error">{validationErrors.currentId}</small>
                 </span> 
            )}
          </div>
        </div>
        <div className="col-12 md:col-9">
          <div className="p-inputgroup flex-1 pb-2">
            <span className="p-inputgroup-addon w-7rem">Açıklama</span>
            <InputTextarea  disabled={isLoading}  
              className="border-round-lg border-noround-left"
              rows={2}
              autoResize
              id="description"
              name="description"
              value={formData.description}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="p-d-flex p-jc-end col-12 md:col-3">
          <Button
            className="w-full md:h-4rem"
            severity="success"
            label={dataRow ? "Stok Güncelle" : "Stok Ekle"}
            icon="pi pi-save"
            type="submit"
            outlined
            loading={isLoading}
          />
        </div>
      </form>
    </Dialog>
  );
};

export default StockAddEdit;
