import React, { useState, useEffect, useRef, useMemo } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { useSelector } from "react-redux";
import { Divider } from "primereact/divider";
import { Badge } from "primereact/badge";
import FlipMove from "react-flip-move";
import { getCurrentsListService } from "../../services/currentsServices";
import { getStationsListService } from "../../services/businessServices";

import { getCodesService } from "../../services/codesServices";
import { addOrderDefinitionDetailsService, addOrderDefinitionsService, deleteOrderDefinitionDetailsService, getIdOrderDefinitionService, updateOrderDefinitionDetailsService, updateOrderDefinitionsService } from "../../services/orderDefinitionsServices";
import { getStocksListService } from "../../services/stocksServices";
const defaultFormData = {
  id: 0,
  name: "",
  description: "",
  currentId: 0,
  stockId:"",
  definitionTypeCodeId: 0, 
  mainProcessCodeId: 0,
  details:[],
  mainProcessCodeId:0
};
const OrderDefinitionsAddEdit = ({
  visible,
  setVisible,
  dataRow,
  onDialogClose,
  toast,
}) => {
  const isLoading = useSelector((state) => state.loading.isLoading);

  const defaultData = useMemo(() => defaultFormData, []);
  const [onay, setOnay] = useState(false);
  const [edit, setEdit] = useState(false);
 
  const [orderDefinitionsGroups, setOrderDefinitionsGroups] = useState([]);
  const [formData, setFormData] = useState(defaultFormData);
  const [validationErrors, setValidationErrors] = useState({});
  const [cariData, setCariData] = useState([]);
  const [stokData, setStokData] = useState([]);
  const [items, setItems] = useState([]);
  const [types, setTypes] = useState([]); 
  const [stations, setStations] = useState([]); 
 
  useEffect(() => {
    if (visible) {
      if (dataRow) {
        console.log(dataRow)
        console.log(dataRow.currentId)
        setFormData(dataRow);
      if(dataRow.currentId){
        stockList(dataRow.currentId);
      }  
        setOnay(true);
      } else {
        setFormData(defaultData);
        setOnay(false);
      }
      setValidationErrors({});
    }
  }, [dataRow, visible,defaultData]);

  const handleOnayClick = async () => {
    const errors = validateForm(formData);
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }
    const filteredFormData = Object.fromEntries(
      Object.entries(formData).filter(
        ([key, value]) => value !== "" && value !== null
      )
    );
    if(edit==false){
      const response = await addOrderDefinitionsService(filteredFormData);
   
      setFormData((prevState) => ({
        ...prevState,
        id: response.id,
      }));
    }else{
      await updateOrderDefinitionsService(filteredFormData);
    }


    onDialogClose();
    setOnay(true); 
    setEdit(false)
  };
  const handleEditClick = async () => {
    setOnay(false); 
    setEdit(true)
  };
  const stockList = async (id) => {
    try {
      const result2 = await getStocksListService(0, 1000, {
        filter: {
          field: "currentId",
          operator: "eq",
          value: String(id),
          logic: "and",
          filters: [],
        },
      });

      setStokData(result2.items);
    } catch (error) {
      console.error("Veri alınamadı:", error);
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setValidationErrors({ ...validationErrors, [name]: null });
  };

  const handleDropdownChange = (name, value) => {
 
    setValidationErrors({ ...validationErrors, [name]: null });
    if (name === "currentId") {
      setFormData((prevState) => ({
        ...prevState,
        currentId: value,
      }));
      stockList(value);
    } else {
      setFormData({ ...formData, [name]: value });
    }
    if (name === "definitionTypeCodeId") {
      setFormData((prevState) => ({
        ...prevState,
        currentId: 0,
        stockId: "",
      }));
    }
  };

  const validateForm = (data) => {
    let errors = {};
    if (!data.name) {
      errors.name = "Boş bırakılamaz.";
    }
    if (!data.definitionTypeCodeId) {
      errors.definitionTypeCodeId = "Tip seçiniz!";
    }
    if (!data.mainProcessCodeId) {
      errors.mainProcessCodeId = "Ana İşlem seçiniz!";
    }
    if (formData.definitionTypeCodeId == 31) {
      if (!data.currentId) {
        errors.currentId = "Cari seçiniz.";
      }
      if (!data.stockId) {
        errors.stockId = "Stok seçiniz.";
      }
    }
    return errors;
  };
 

  const typesOptions = types.map((type) => ({
    label: type.description,
    value: type.id,
  }));
  const fetchData = async () => {
    try {
      const resultTypes =
        types.length <= 0 ? await getCodesService(8) : { items: types };
      setTypes(resultTypes.items);
      const resultStations = await getStationsListService(0,1000,{});
      setStations(resultStations.items); 
      const result = await getCurrentsListService(0, 1000, {}); // Sayfa indeksi ve satır sayısı
      //   const result2 = await getStocksListService(0, 1000, {}); // Sayfa indeksi ve satır sayısı
      setCariData(result.items);

      const resultGroups =
      orderDefinitionsGroups.length <= 0
        ? await getCodesService(10)
        : { items: orderDefinitionsGroups };

    setOrderDefinitionsGroups(resultGroups.items);
      //   setStokData(result2.items);
    } catch (error) {
      console.error("Veri alınamadı:", error);
    }
  };
  useEffect(() => {
    if (visible) {
      fetchData();
    }else{
      onDialogClose();
    }
  }, [visible]);
 
  const handleAddItem = async (box) => {
    const detailData = {
      orderDefinitionId:formData.id,
      sequence: (formData.details ? formData.details.length : 0) + 1,
      stationId:box.id 
    }
     await addOrderDefinitionDetailsService(detailData).then((sa)=>{
     
       console.log("sssss")
       console.log(sa)
       console.log(box)
      const newItem = {
        id: sa.id,
        orderDefinitionId:formData.id,
        name: box.name,
        sequence: sa.sequence,
        stationId:box.id,
        processDefinitionId:null,
        machineId:null,
        station :{
          id:box.id,
          name:box.name,
          processDefinitions:box.processDefinitions,
          machines:box.machines
        }
      };
   
      setFormData(prevFormData => ({
        ...prevFormData,
        details: [...prevFormData.details, newItem]
      }));
    });
  }; 
  const handleMachineChange = async (index, value) => {
    const updatedItems = [...items];
    updatedItems[index].machineId = value;
    setItems(updatedItems);
  };

  const removeItem =async (index,item) => {
    await deleteOrderDefinitionDetailsService(item.id)
    const removedSequence = item.sequence;
    const updatedDetails = [...formData.details];
    updatedDetails.splice(index, 1);


    // Kalan öğelerin sıralamalarını güncelleyin
  const updatedItems = updatedDetails.map((detail, idx) => {
    if (detail.sequence > removedSequence) {
      detail.sequence -= 1; 
    }
    return detail;
  });
    setFormData(prevFormData => ({
      ...prevFormData,
      details: updatedItems
    }));
  };

  const moveItem = async (fromIndex, toIndex) => {
    const updatedDetails = [...formData.details];
  const [movedItem] = updatedDetails.splice(fromIndex, 1);
  updatedDetails.splice(toIndex, 0, movedItem);

  // Get the two items that have swapped places
  const item1 = updatedDetails[toIndex];
  const item2 = updatedDetails[fromIndex];
  // Swap their sequence values
  const tempSequence = item1.sequence;
  item1.sequence = item2.sequence;
  item2.sequence = tempSequence;

  // Update the state
  setFormData(prevFormData => ({
    ...prevFormData,
    details: updatedDetails
  })); 

  const item1Request = {
    id: item1.id,
    orderDefinitionId:item1.orderDefinitionId, 
    sequence:item1.sequence,
    stationId:item1.stationId,
  };

   await updateOrderDefinitionDetailsService(item1Request)
   const item2Request = {
    id: item2.id,
    orderDefinitionId:item2.orderDefinitionId, 
    sequence:item2.sequence,
    stationId:item2.stationId,
  };
   await updateOrderDefinitionDetailsService(item2Request)
  };
  const processDefinitionChange = async (index, value) => {
    const updatedDetails = [...formData.details]; 
    const item1 = updatedDetails[index];  
    item1.processDefinitionId = value;
 
    setFormData(prevFormData => ({
      ...prevFormData,
      details: updatedDetails
    })); 
  
    const item1Request = {
      id: item1.id,
      orderDefinitionId:item1.orderDefinitionId, 
      sequence:item1.sequence,
      stationId:item1.stationId,
      machineId:item1.machineId ? item1.machineId:null,
      processDefinitionId:item1.processDefinitionId ? item1.processDefinitionId:null,
    };
  
     await updateOrderDefinitionDetailsService(item1Request)
 
  };
  const machineChange = async (index, value) => {
    const updatedDetails = [...formData.details]; 
    const item1 = updatedDetails[index];  
    item1.machineId = value;
 
    setFormData(prevFormData => ({
      ...prevFormData,
      details: updatedDetails
    })); 
  
    const item1Request = {
      id: item1.id,
      orderDefinitionId:item1.orderDefinitionId, 
      sequence:item1.sequence,
      stationId:item1.stationId,
      machineId:item1.machineId ? item1.machineId:null,
      processDefinitionId:item1.processDefinitionId ? item1.processDefinitionId:null,
    };
  
     await updateOrderDefinitionDetailsService(item1Request)
  
  };
  const selectedItemTemplate = (option, props) => {
    if (option) {
      return (
        <div className="text-xs	">
          <div>{option.label}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };
  const Rotasyon = <div className="w-full ">
    <Divider className="mt-0 mb-2 ">
      <span className="shadow-2 h-5rem w-10rem border-round-md p-1 bg-primary px-3">
        Rotasyon <i className="pi pi-sort-down" />
      </span>
    </Divider>
    <div className="surface-hover border-round-md mb-3 shadow-4 w-full">
      <div className="grid m-0">
        <div className="col-12 p-card md:col-2 pt-3 p-3 pb-3 border-noround-right">
          <div className="w-full">
            {stations.map((box, index) => (
              <Button
                key={index}
                label={box.name}
                className="w-full mb-2 text-left"
                icon="pi pi-plus"
                iconPos="right"
                loading={isLoading}
                outlined
                onClick={() => handleAddItem(box, index)} />
            ))}
          </div>
        </div>
        <div className="col-12 surface-hover border-noround-left p-card md:col-10">
          <div>
            <FlipMove
              duration={300}
              easing="ease-in-out"
              className="grid p-flex-wrap ml-0 mt-2 mb-2 mr-1 md:mr-0  "
            >
   {formData.details && formData.details.length > 0 && formData.details.sort((a, b) => a.sequence - b.sequence).map((item, index) => (
                <div
                  key={item.id}
                  className="col-12 sm:col-6 lg:col-4 xl:col-4 p-2 "
                >
                  <div className="relative p-4 border-1 surface-border surface-card border-round  h-16rem">
                    <Badge
                      className="absolute top-0 left-0 m-2"
                      severity="info"
                      value={item.sequence} />
                    <div className="flex flex-wrap align-items-center justify-content-center gap-2 mb-3">
                      <div className="flex align-items-center gap-2 ">
                        <span className="font-semibold  ">
                          {item.station.name}
                        </span>
                        {/* <i className="pi pi-tag"></i>*/}
                      </div>
                    </div>
                    {item.station.machines.length > 0 ? (
                                  <div className="p-inputgroup flex-1 pb-2 text-xs	">
                                    <span className="p-inputgroup-addon  w-4rem">
                                      Makine
                                    </span>
                                    <Dropdown
                                      value={item.machineId || ""}
                                      panelClassName="text-xs	"
                                      options={item.station.machines.map(
                                        (pd) => ({
                                          label: pd.name,
                                          value: pd.id,
                                        })
                                      )}
                                      onChange={(e) =>
                                        machineChange(index, e.value)
                                      }
                                      showClear={
                                        item.machineId != "" &&
                                        item.machineId != null
                                          ? true
                                          : false
                                      }
                                      emptyMessage="Veri Yok"
                                      disabled={isLoading}
                                      valueTemplate={selectedItemTemplate}
                                    />
                                  </div>
                                ) : null}

                                {item.station.processDefinitions.length > 0 ? (
                                  <div className="p-inputgroup flex-1 pb-2 text-xs	">
                                    <span className="p-inputgroup-addon  	 w-4rem">
                                      İşlem
                                    </span>
                                    <Dropdown
                                      panelClassName="text-xs	"
                                      value={item.processDefinitionId || ""}
                                      options={item.station.processDefinitions.map(
                                        (pd) => ({
                                          label: pd.name,
                                          value: pd.id,
                                        })
                                      )}
                                      onChange={(e) =>
                                        processDefinitionChange(index, e.value)
                                      }
                                      showClear={
                                        item.processDefinitionId != null &&
                                        item.processDefinitionId != ""
                                          ? true
                                          : false
                                      }
                                      disabled={isLoading}
                                      valueTemplate={selectedItemTemplate}
                                    />
                                  </div>
                                ) : null}
                    <Button 
                      icon="pi pi-times"    loading={isLoading}
                      text
                      className="absolute top-0 right-0 border-noround-left border-noround-bottom text-red-300"
                      onClick={() => removeItem(index, item)} />
                    <Button
                      icon="pi pi-arrow-left"    loading={isLoading}
                      text
                      className="absolute left-0 bottom-0 p-button-rounded p-button-sm"
                      onClick={() => moveItem(index, index - 1)}
                      disabled={index === 0} />
                    <Button
                      icon="pi pi-arrow-right"    loading={isLoading}
                      className="absolute right-0 bottom-0 p-button-rounded"
                      text
                      onClick={() => moveItem(index, index + 1)}
                      disabled={index === formData.details.length - 1} />
                  </div>
                </div>
              ))}
            </FlipMove>
          </div>
        </div>
      </div>
    </div>
  </div>;
  return (
    <Dialog
      className="max-w-full w-11 md:w-11 xl:w-8"
      header={"Sipariş Tanımı Oluştur"}
      visible={visible}
      onHide={() => setVisible(false)}
    >
      <div className="formgrid grid">
        <div className="col-12 md:col-3">
          <div className="p-inputgroup flex-1 pb-2">
            <span className="p-inputgroup-addon w-7rem md:w-5rem">Tip</span>
            <Dropdown
              disabled={onay}
              id="definitionTypeCodeId"
              name="definitionTypeCodeId"
              value={formData.definitionTypeCodeId}
              options={typesOptions}
              onChange={(e) =>
                handleDropdownChange("definitionTypeCodeId", e.value)
              }
              placeholder="Tip Seçiniz"
            />
            {validationErrors.definitionTypeCodeId && (
              <span className="p-inputgroup-addon w-7rem">
                <small className="p-error">
                  {validationErrors.definitionTypeCodeId}
                </small>
              </span>
            )}
          </div>
        </div>   
        <div className="col-12 md:col-4">
          <div className="p-inputgroup flex-1 pb-2">
            <span className="p-inputgroup-addon w-7rem  ">Ana İşlem</span>
            <Dropdown
              disabled={onay}
              id="mainProcessCodeId"
              name="mainProcessCodeId"
              value={formData.mainProcessCodeId}
              options={orderDefinitionsGroups.map((group) => ({
                label: group.description,
                value: group.id,
              }))}
              onChange={(e) =>
                handleDropdownChange("mainProcessCodeId", e.value)
              }
              placeholder="Grup Seçiniz"
            />
            {validationErrors.mainProcessCodeId && (
              <span className="p-inputgroup-addon w-10rem">
                <small className="p-error">
                  {validationErrors.mainProcessCodeId}
                </small>
              </span>
            )}
          </div>
        </div>
        <div className="col-12 md:col-5">
          <div className="p-inputgroup flex-1 pb-2">
            <span className="p-inputgroup-addon w-7rem md:w-5rem">Tanım</span>
            <InputText
              disabled={onay}
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
            />
            {validationErrors.name && (
              <span className="p-inputgroup-addon w-10rem">
                <small className="p-error">{validationErrors.name}</small>
              </span>
            )}
          </div>
        </div>
        {formData.definitionTypeCodeId === 31 ? (
          <div className="col-12 md:col-6">
            <div className="p-inputgroup flex-1 pb-2">
              <span className="p-inputgroup-addon w-7rem md:w-5rem">Cari</span>
              <Dropdown
                disabled={onay}
                id="currentId"
                name="currentId"
                value={formData.currentId}
                options={cariData.map((item) => ({
                  label: item.title,
                  value: item.id,
                }))}
                onChange={(e) => handleDropdownChange("currentId", e.value)}
                placeholder="Cari Seçiniz"
                filter
                emptyMessage="Cari bulunamadı!"
                emptyFilterMessage="Sonuç bulunamadı!"
              />
              {validationErrors.currentId && (
                <span className="p-inputgroup-addon w-10rem">
                  <small className="p-error">
                    {validationErrors.currentId}
                  </small>
                </span>
              )}
            </div>
          </div>
        ) : (
          <></>
        )}
        {formData.definitionTypeCodeId === 31 ? (
          <div className="col-12 md:col-6">
            <div className="p-inputgroup flex-1 pb-2">
              <span className="p-inputgroup-addon w-7rem md:w-5rem">Stok</span>
              <Dropdown
                disabled={formData.currentId==0 || formData.currentId=="" ? true :  onay}
                id="stockId"
                name="stockId"
                value={formData.stockId}
                options={stokData.map((item) => ({
                  label: item.name,
                  value: item.id,
                }))}
                onChange={(e) => handleDropdownChange("stockId", e.value)}
                placeholder="Stok Seçiniz"
                filter
                emptyMessage="Stok bulunamadı!"
                emptyFilterMessage="Sonuç bulunamadı!"
              />
              {validationErrors.stockId && (
                <span className="p-inputgroup-addon w-10rem">
                  <small className="p-error">{validationErrors.stockId}</small>
                </span>
              )}
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className="col-12 md:col-9">
          <div className="p-inputgroup flex-1 pb-2">
            <span className="p-inputgroup-addon w-7rem md:w-5rem">
              Açıklama
            </span>
            <InputText
              disabled={onay}
              id="description"
              name="description"
              value={formData.description?formData.description:""}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className=" flex col-12 md:col-3 gap-1">
        <Button
            className="w-full h-3rem"
            severity="success"
            label={onay ? "Onaylandı" : edit?"Güncelle":"Onayla"}
            icon={onay ? "pi pi-check" : "pi pi-sort-down-fill"}
            outlined
            loading={isLoading}
            onClick={handleOnayClick}
            disabled={onay}
          /> {onay ? <Button
            className="w-full h-3rem"
            severity="info"
            label="Düzenle"
            icon="pi pi-pencil"
            outlined
            loading={isLoading}
            onClick={handleEditClick}
            
          />:<></>}
        </div>
        {onay ? (
          Rotasyon 
        ) : (
         edit? Rotasyon: <></>
        ) }
      </div>
    </Dialog>
  );
};

export default OrderDefinitionsAddEdit;
