import { createSlice } from '@reduxjs/toolkit';

export const edatagridSlice = createSlice({
  name: 'egrid',
  initialState: {
    data: [],
    filteredData: [],
    filters: {
      search: '',
      category: ''
    }
  },
  reducers: {
    setFilter: (state, action) => {
      state.filters[action.payload.type] = action.payload.value;
      state.filteredData = state.data.filter(item => {
        return (
          item.name.includes(state.filters.search) &&
          (state.filters.category ? item.category === state.filters.category : true)
        );
      });
    },
    setData: (state, action) => {
      state.data = action.payload;
      state.filteredData = action.payload;
    }
  }
});

export const { setFilter, setData } = edatagridSlice.actions;

export default edatagridSlice.reducer;
