import { getToken } from "./apiHelper";
import api from "./api";
export const getCurrentsListService = async (pageIndex, pageSize, body) => {
  try {
    let axiosConfig = {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
      params: {
        PageIndex: pageIndex,
        PageSize: pageSize,
      },
    };
    const response = await api.post(`/currents/getlist`, body, axiosConfig);

    return response.data;
  } catch (error) {
    console.error("  error:", error);
    throw error;
  }
};

export const addCurrentService = async (data) => {
  try {
    let axiosConfig = {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    };

    const response = await api.post(`/currents`, data, axiosConfig);

    return response.data;
  } catch (error) {
    console.error("Login error:", error);
    throw error;
  }
};
export const updateCurrentService = async (data) => {
  try {
    let axiosConfig = {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    };

    const response = await api.put(`/currents`, data, axiosConfig);

    return response.data;
  } catch (error) {
    console.error("Login error:", error);
    throw error;
  }
};

export const deleteCurrentService = async (data) => {
  try {
    let axiosConfig = {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    };

    const response = await api.delete(`/currents/` + data.id, axiosConfig);

    return response.data;
  } catch (error) {
    console.error("Login error:", error);
    throw error;
  }
};
