import React, { useRef,useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../reducers/userSlice";
import { toggleSidebar } from "../../reducers/sidebarSlice";
import { toggleTheme } from "../../reducers/themeSlice";
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import useSize from "../common/useSize";
import ThemeSwitcher from "../root/ThemeSwitcher";
const Navi = () => {
  const menuRight = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const darkMode = useSelector((state) => state.theme.darkMode);
  const selectedMenuItem = useSelector(
    (state) => state.sidebar.selectedMenuItem
  );
  const windowsize = useSize();
  const [visible, setVisible] = useState(false);
  const handleLogout = () => {
    dispatch(logoutUser());
    navigate("/login");
  };
  const themeName = useSelector((state) => state.theme.themeName);
  const refreshPage = () => {
    window.parent.location = window.parent.location.href;
  };

  let items = [
    {
      label: "Dökümantasyon",
      items: [
        {
          label: "Yeni",
          icon: "pi pi-plus",
        },
        {
          label: "Nasıl Kullanılır?",
          icon: "pi pi-search",
        },
      ],
    },
    {
      separator: true,
    },
    {
      label: "Profil",
      items: [
        {
          label: "Hesap Ayarları",
          icon: "pi pi-cog",
        },

        {
          label: "Logout",
          icon: "pi pi-sign-out",
          command: handleLogout,
        },
      ],
    },
  ];

const changeThemeMode =()=>{
  dispatch(toggleTheme()) 
}

  return (
    <div className="flex justify-content-between align-items-center p-2">
        <ThemeSwitcher visible={visible} setVisible={setVisible} />
      <Button
        icon="pi pi-bars"
        text
        onClick={() => dispatch(toggleSidebar())}
      />
  
      <div style={{fontSize:"16px"}}>{ windowsize[0] >800 ?selectedMenuItem:''}</div>
      <div className="flex">
       {themeName === "bootstrap4" || themeName === "md" || themeName === "mdc"
     || themeName === "lara" ||  themeName === "soho" || themeName === "viva" ? <Button
     icon={darkMode ? "pi pi-moon" : "pi pi-sun"}
     text
     onClick={changeThemeMode}
   /> :<></>} 
         
       
        <Button icon="pi pi-bell" text  />
        <Button icon="pi pi-cog" text onClick={() => setVisible(true)} />
        <Button icon="pi pi-refresh" text onClick={refreshPage} />
        <div style={{ flexGrow: 0 }}>
          <Button
            icon="pi pi-user"
            text
            onClick={(event) => menuRight.current.toggle(event)}
          />

          <Menu
            model={items}
            popup
            ref={menuRight}
            id="popup_menu_right"
            className=" w-15rem "
          />
        </div>
      </div>
    </div>
  );
};

export default Navi;
