export const getSeverityStatuses = (status) => {
    switch (status) {
      case "Yeni Giriş":
        return "warning";
      case "Tebdil":
        return "info";
      case "İş Emri Oluşturuldu":
        return "success";
      default:
        return null;
    }
  };