import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { loadUserFromStorage } from '../../reducers/userSlice';
import AppIndex from './AppIndex';
import Login from '../auth/Login';
import PrivateRoute from './PrivateRoute';
import NotFound from '../common/NotFound';
import { PrimeReactContext } from 'primereact/api';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

function App() {
    const dispatch = useDispatch();
    const status = useSelector((state) => state.user.status);
    const bakim = useSelector((state) => state.loading.bakim);

    useEffect(() => {
        dispatch(loadUserFromStorage());
    }, [dispatch]);

    const { changeTheme } = useContext(PrimeReactContext);
    const darkMode = useSelector((state) => state.theme.darkMode);
    const themeName = useSelector((state) => state.theme.themeName);
    const themeColor = useSelector((state) => state.theme.themeColor);

    useEffect(() => {
        const newTheme = `${themeName}${
        themeName !== "mira" &&
        themeName !== "nano" &&
        themeName !== "saga" &&
        themeName !== "vela" &&
        themeName !== "arya"
          ? darkMode
            ? themeName !== "tailwind" && themeName !== "fluent"
              ? "-dark"
              : "-light"
            : "-light"
          : ""
      }${themeColor ? "-" + themeColor : ""}`;

      const themeLink = document.getElementById("theme-link");
      if (themeLink) {
        themeLink.href = `/themes/${newTheme}/theme.css`;
        themeLink.onload = () => {
          changeTheme("current-theme", newTheme, "theme-link", () => {});
        };
      }
    }, [darkMode, themeName, themeColor, changeTheme]);

    const refreshPage = () => {
      window.parent.location = window.parent.location.href;
    };

    if (status === 'loading') {
        return <div>Loading...</div>;
    }

    return (
        <Router>
            <Dialog
                visible={bakim}
                closable={false}
                closeOnEscape={false}
                showHeader={false}
                unstyled
            >
                <div>
                    <div className="w-28rem h-5rem p-3 p-card flex-column align-items-center justify-content-center text-align-center relative">
                        <div className="flex w-full align-items-center justify-content-center">
                            <div className="flex-column text-align-center">
                                <span>Server Bakıma Alındı.</span>
                                <br />
                                <span>Bir süre sonra tekrar deneyiniz.</span>
                            </div>
                            <Button icon="pi pi-refresh" text onClick={refreshPage} className="ml-auto gap-3">
                                Yenile
                            </Button>
                        </div>
                    </div>
                    <div className="bg-overlay"></div>
                </div>
            </Dialog>
            <Routes>
                <Route path="/" element={<Navigate to="/y/dashboard" />} />
                <Route path="/y" element={<Navigate to="/y/dashboard" />} />
                <Route path="*" element={<NotFound />} />
                <Route path="/login" element={<Login />} />
                <Route path="/y/*" element={<PrivateRoute><AppIndex /></PrivateRoute>} />
            </Routes>
        </Router>
    );
}

export default App;
