import React, {  useEffect } from "react";
import { Sidebar } from "primereact/sidebar";
import { Button } from "primereact/button";
import { useSelector, useDispatch } from "react-redux";
import {
  toggleTheme,
  toggleThemeColor,
  toggleThemeName,
} from "../../reducers/themeSlice";
import { ToggleButton } from "primereact/togglebutton";  

const ThemeSwitcher = ({ visible, setVisible }) => { 
  const darkMode = useSelector((state) => state.theme.darkMode);
  const themeName = useSelector((state) => state.theme.themeName);
  const themeColor = useSelector((state) => state.theme.themeColor);
  const dispatch = useDispatch();
 
  
   useEffect(() => {
    const themeSettings = {
      darkMode,
      themeName,
      themeColor
    };
    localStorage.setItem('theme', JSON.stringify(themeSettings));
  }, [darkMode, themeName, themeColor]);
 
 
  const themeChange = (name) => {
    if (themeName !== name) {
      if (
        name === "bootstrap4" ||
        name === "lara" ||
        name === "saga" ||
        name === "vela" ||
        name === "arya"
      ) {
        dispatch(toggleThemeColor("blue"));
      } else if (name === "md" || name === "mdc") {
        dispatch(toggleThemeColor("indigo"));
      } else {
        dispatch(toggleThemeColor(""));
      }
      dispatch(toggleThemeName(name));
    }
  };

  const changeThemeMode = () => {
    dispatch(toggleTheme());
  };

  const handleColor = (value) => {
    dispatch(toggleThemeColor(value));
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <Sidebar
      visible={visible}
      position="right"
      header
      onHide={() => setVisible(false)}
      content={({ closeIconRef, hide }) => (
        <div className="min-h-screen flex relative lg:static surface-ground m-2">
          <div id="app-sidebar-2" className="surface-section">
            <div className="h-full">
              <div className="flex align-items-center justify-content-between px-4 pt-3 flex-shrink-0">
                <span className="inline-flex align-items-center gap-2">
                  <span className="font-semibold text-2xl text-primary">
                    Etkili Yazılım
                  </span>
                </span>
                <span>
                  <Button
                    type="button"
                    ref={closeIconRef}
                    onClick={(e) => hide(e)}
                    icon="pi pi-times"
                    rounded
                    outlined
                    className="h-2rem w-2rem"
                  ></Button>
                </span>
              </div>
              <div className="overflow-x-hidden overflow-y-auto pb-6 p-1">
                <h3>Temalar</h3>
              
                <div>
           
                  <div className="grid">
                    <div className="col-6">
                      <Button
                        iconPos="right"
                        icon={themeName === "lara" ? "pi pi-check" : ""}
                        className="w-full  h-3rem"
                        label="Lara"
                        onClick={() => themeChange("lara")}
                      />
                    </div>
                    <div className="col-6">
                      <Button
                        iconPos="right"
                        icon={themeName === "bootstrap4" ? "pi pi-check" : ""}
                        className="w-full  h-3rem"
                        label="Bootstrap"
                        onClick={() => themeChange("bootstrap4")}
                      />
                    </div>
                    <div className="col-6">
                      <Button
                        iconPos="right"
                        icon={themeName === "md" ? "pi pi-check" : ""}
                        className="w-full  h-3rem"
                        label="MD"
                        onClick={() => themeChange("md")}
                      />
                    </div>
                    <div className="col-6">
                      <Button
                        iconPos="right"
                        icon={themeName === "mdc" ? "pi pi-check" : ""}
                        className="w-full  h-3rem"
                        label="MDC"
                        onClick={() => themeChange("mdc")}
                      />
                    </div>
                    <div className="col-6">
                      <Button
                        iconPos="right"
                        icon={themeName === "saga" ? "pi pi-check" : ""}
                        className="w-full  h-3rem"
                        label="Saga"
                        onClick={() => themeChange("saga")}
                      />
                    </div>
                    <div className="col-6">
                      <Button
                        iconPos="right"
                        icon={themeName === "vela" ? "pi pi-check" : ""}
                        className="w-full  h-3rem"
                        label="Vela"
                        onClick={() => themeChange("vela")}
                      />
                    </div>
                    <div className="col-6">
                      <Button
                        iconPos="right"
                        icon={themeName === "arya" ? "pi pi-check" : ""}
                        className="w-full  h-3rem"
                        label="Arya"
                        onClick={() => themeChange("arya")}
                      />
                    </div>
                    <div className="col-6">
                      <Button
                        iconPos="right"
                        icon={themeName === "tailwind" ? "pi pi-check" : ""}
                        className="w-full  h-3rem"
                        label="Tailwind"
                        onClick={() => themeChange("tailwind")}
                      />
                    </div>
                    <div className="col-6">
                      <Button
                        iconPos="right"
                        icon={themeName === "fluent" ? "pi pi-check" : ""}
                        className="w-full  h-3rem"
                        label="Fluent"
                        onClick={() => themeChange("fluent")}
                      />
                    </div>
                    <div className="col-6">
                      <Button
                        iconPos="right"
                        icon={themeName === "mira" ? "pi pi-check" : ""}
                        className="w-full  h-3rem"
                        label="Mira"
                        onClick={() => themeChange("mira")}
                      />
                    </div>
                    <div className="col-6">
                      <Button
                        iconPos="right"
                        icon={themeName === "nano" ? "pi pi-check" : ""}
                        className="w-full  h-3rem"
                        label="Nano"
                        onClick={() => themeChange("nano")}
                      />
                    </div>
                  </div>
                </div>
                {themeName === "bootstrap4" ||
                themeName === "md" ||
                themeName === "mdc" ||
                themeName === "lara" ||
                themeName === "soho" ||
                themeName === "viva" ? (
                   
                       
                         <div>
                         <h4>Tema Modu</h4>
                    <ToggleButton
                      onLabel="Karanlık Tema"
                      offLabel="Aydınlık Tema"
                      className="w-full h-3rem"
                      onIcon="pi pi-moon"
                      offIcon="pi pi-sun"
                      checked={darkMode}
                      onChange={changeThemeMode}
                    />
                  </div> 
                
                ) : null}
                {themeColor !== "" ? (
                  <div>

                  {/*  <h4>{capitalizeFirstLetter(themeName)} Renkleri</h4>*/ }
                    <h4>{capitalizeFirstLetter(themeName)} Teması Renkleri</h4>
                    <div className="grid align-items-center">
                      {themeName === "bootstrap4" ||
                      themeName === "lara" ||
                      themeName === "saga" ||
                      themeName === "vela" ||
                      themeName === "arya" ? (
                        <>
                          <div className="col-3 text-center">
                            <Button
                              value="blue"
                              onClick={() => handleColor("blue")}
                              icon={themeColor === "blue" ? "pi pi-check" : ""}
                              className="w-3rem h-3rem text-white border-0 border-circle bg-blue-500"
                            />
                          </div>
                          <div className="col-3 text-center">
                            <Button
                              value="purple"
                              onClick={() => handleColor("purple")}
                              icon={themeColor === "purple" ? "pi pi-check" : ""}
                              className="w-3rem h-3rem text-white border-0 border-circle bg-purple-500"
                            />
                          </div>
                        </>
                      ) : null}
                      {themeName === "md" ||
                      themeName === "mdc" ||
                      themeName === "lara" ? (
                        <div className="col-3 text-center">
                          <Button
                            value="indigo"
                            onClick={() => handleColor("indigo")}
                            icon={themeColor === "indigo" ? "pi pi-check" : " "}
                            className="w-3rem h-3rem text-white border-0 border-circle bg-indigo-500"
                          />
                        </div>
                      ) : null}
                      {themeName === "md" || themeName === "mdc" ? (
                        <div className="col-3 text-center ">
                          <Button
                            value="deeppurple"
                            onClick={() => handleColor("deeppurple")}
                            icon={
                              themeColor === "deeppurple" ? "pi pi-check" : " "
                            }  
                            className="w-3rem h-3rem text-white border-0 border-circle bg-purple-600"
                          />
                        </div>
                      ) : null}
                      {themeName === "lara" ? (
                        <div className="col-3 text-center">
                          <Button
                            value="teal"
                            onClick={() => handleColor("teal")}
                            icon={themeColor === "teal" ? "pi pi-check" : " "}
                            className="w-3rem h-3rem text-white border-0 border-circle bg-teal-500"
                          />
                        </div>
                      ) : null}
                      {themeName === "saga" ||
                      themeName === "vela" ||
                      themeName === "arya" ? (
                        <>
                          <div className="col-3 text-center">
                            <Button
                              value="green"
                              onClick={() => handleColor("green")}
                              icon={themeColor === "green" ? "pi pi-check" : ""}
                              className="w-3rem h-3rem text-white border-0 border-circle bg-green-500"
                            />
                          </div>
                          <div className="col-3 text-center">
                            <Button
                              value="orange"
                              onClick={() => handleColor("orange")}
                              icon={themeColor === "orange" ? "pi pi-check" : ""}
                              className="w-3rem h-3rem text-white border-0 border-circle bg-orange-500"
                            />
                          </div>
                        </>
                      ) : null}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      )}
    ></Sidebar>
  );
};

export default ThemeSwitcher;
