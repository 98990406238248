import React, { useEffect, useState, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode } from "primereact/api";
import {
 
  deleteStockService,
  getStocksListService,
} from "../../services/stocksServices";
import { InputText } from "primereact/inputtext";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { Button } from "primereact/button"; 
import { Toast } from "primereact/toast";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import StockAddEdit from "./ShipmentAddEdit";
import { useSelector } from "react-redux";
const Shipment = () => {
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [data, setData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    id: { value: null, matchMode: FilterMatchMode.CONTAINS },
    title: { value: null, matchMode: FilterMatchMode.CONTAINS },
    kod: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [visible, setVisible] = useState(false);
   
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };
  const clearFilter = () => {
    initFilters();
  };
  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      id: { value: null, matchMode: FilterMatchMode.CONTAINS },
      title: { value: null, matchMode: FilterMatchMode.CONTAINS },
      kod: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    setGlobalFilterValue("");
  };
  const renderHeader = () => {
    return (
      <div className="flex justify-content-between">
        <div className="flex  ">
          <Button
            severity="success"
            type="button"
            icon="pi pi-plus"
            label="Yeni"
            outlined
            onClick={() => handleAddClick()}
          />
        </div>{" "}
        <div className="flex ">
          <IconField iconPosition="left" className="pr-1  hidden md:block ">
            <InputIcon className="pi pi-search" />
            <InputText
              value={globalFilterValue}
              onChange={onGlobalFilterChange}
              placeholder="Global Arama"
            />
          </IconField>
          <Button
            type="button"
            icon="pi pi-filter-slash"
            outlined
            onClick={clearFilter}
          />
        </div>
      </div>
    );
  };
  const isLoading = useSelector((state) => state.loading.isLoading);
  const fetchData = async (first, rows) => {
    
    try {
      const result = await getStocksListService(first / rows, rows); // Sayfa indeksi ve satır sayısı
      setData(result.items);
      setTotalRecords(result.count); // Toplam kayıt sayısını güncelle
     
    } catch (error) {
      console.error("Veri alınamadı:", error);
    
    }
  };
  useEffect(() => {
    fetchData(first, rows);
    initFilters();
  }, [first, rows]);
  const [dataRow, setRowData] = useState(null);

  const handleCloseDialog = () => {
 
    fetchData(first, rows);
  };
  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          text
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success p-mr-2"
          onClick={() => handleEditClick(rowData)}
        />
        <Button
          text
          icon="pi pi-trash"
          className="p-button-rounded p-button-danger"
          onClick={() => handleDeleteClick(rowData)}
        />
      </React.Fragment>
    );
  };

  const handleEditClick = (rowData) => {
    setVisible(true);
    setRowData(rowData);
  };
  const handleAddClick = () => {
    setRowData(null);
    setVisible(true);
   
  };

  const handleDeleteClick = (rowData) => {
    confirmDialog({
      message: "Stoğu silmek üzeresin! Emin misin?",
      header: "Stok Siliniyor",
      icon: "pi pi-info-circle",
      defaultFocus: "reject",
      acceptClassName: "p-button-danger",
      position: "right",
      acceptLabel: "Evet",
      rejectLabel: "Hayır",
      accept,
      reject,
    });
    setRowData(rowData);
    handleCloseDialog();
  };
  const header = renderHeader();
  const toast = useRef(null);

  const accept = async () => {
 
    await deleteStockService(dataRow);
    fetchData(first, rows);
    toast.current.show({
      severity: "info",
    
      detail: "Stok Başarıyla Silindi",
      life: 3000,
    });
  };

  const reject = () => {
    toast.current.show({
      severity: "warn",
    
      detail: "Stok silme işlemi iptal edildi.",
      life: 3000,
    });
  };
  const Columns =[
    <Column
    field="code"
    header="Stok Kodu"
    filter={true}
    showFilterMenu={false}
    filterPlaceholder="Stok Kodu Ara"
    style={{ minWidth: "12rem" }}
  /> ,
  <Column
    field="name"
    header="Stok Adı"
    filterField="name"
    style={{ minWidth: "12rem" }}
    filter={true}
    showFilterMenu={false}
    filterPlaceholder="Stok Adı Ara"
  />,
  
  <Column
    field="currentId"
    header="currentId"
    filterField="Stok Carisi"
    style={{ minWidth: "12rem" }}
    filter={true}
    showFilterMenu={false}
    filterPlaceholder="Stok Carisi Ara"
  />,
  <Column
    header="/"
    alignHeader={"center"}
    align={"center"}
    headerStyle={{ width: "4rem", textAlign: "center" }}
    bodyStyle={{ textAlign: "center", overflow: "visible" }}
    body={actionBodyTemplate}
  />
  ]
  return (
    <div className="surface-card p-2 border-round-sm ">
      <Toast ref={toast} />
      <ConfirmDialog />
      <StockAddEdit
        setVisible={setVisible}
        visible={visible}
        dataRow={dataRow}
        onDialogClose={handleCloseDialog}
        toast = {toast}
      />
      <DataTable
        className=" w-auto   "
        scrollable={true}
        scrollHeight="65vh"
        size="small"
        showGridlines
        stripedRows
        resizableColumns
        columnResizeMode="fit"
        value={data}
        rows={20}
        dataKey="id"
        filters={filters}
        filterDisplay="row"
        loading={isLoading}
        paginator
        globalFilterFields={["id", "kod", "title"]}
        header={header}
        emptyMessage="Veri Yok"
      >
      {Columns}
      </DataTable>
    </div>
  );
};

export default Shipment;
