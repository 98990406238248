import React, { memo, useEffect, useState } from "react";
import { Divider } from "primereact/divider";
import Barcode from "react-barcode";
import logo from "../assets/logo2.png";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import QRCode from "react-qr-code";
import { Badge } from "primereact/badge";
import { InputText } from "primereact/inputtext";

const formatDate = (dateString) => {
  if (!dateString) return "";

  const date = new Date(dateString);

  return date.toLocaleDateString("tr-TR", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
};

const OrderPrint = React.forwardRef(({ data }, ref) => {
  const [expandedRows, setExpandedRows] = useState(null);

  useEffect(() => {
    if (
      data.order &&
      data.order.workOrders &&
      data.order.workOrders.length > 0 &&
      data.order?.workOrders[0]?.workOrderRoutes
    ) {
      setExpandedRows(data.order.workOrders[0].workOrderRoutes);
    }
  }, [data]);

  const rowExpansionTemplate = (rowData) => {
    return (
      <div className="py-3 border-700 border-bottom-1">
        <div className="flex grid">
          <div className="col-6">
            <strong>Sorumlu:</strong>
          </div>
        </div>
      </div>
    );
  };
  const stationMachineBody = (rowData) => {
    return (
      <div>
        <span className="font-bold">{rowData.station.name}</span>
        {rowData.machine ? <span> ({rowData.machine.name})</span> : null}
      </div>
    );
  };
  const orderDefinitionBody = (rowData) => {
    return (
      <div className="text-md">
        {rowData.station.name == "RAM" ? (
          <span> {data.order?.orderDefinition?.name}</span>
        ) : null}
      </div>
    );
  };
  const processDefinitionBody = (rowData) => {
    return (
      <div className="text-md">
        {rowData.processDefinition ? (
          <span> {rowData.processDefinition.name}</span>
        ) : null}
        {rowData.processDefinition && rowData.recipe ? <span> / </span> : null}
        {rowData.recipe ? <span> {rowData.recipe.name}</span> : null}
      </div>
    );
  };
  return (
    <div
      ref={ref}
      className="flex"
      style={{
        width: "210mm",
        height: "297mm",

        flexDirection: "column",
      }}
    >
      <div className="flex   m-0 border-double border-x-none border-top-none w-full">
        <div className="col-3  p-3  justify-content-center align-items-center">
          <img
            src={logo}
            alt="Logo"
            style={{ width: "100px", height: "auto" }}
          />
          
          <div className="font-bold p-text-center">REFAKAT KARTI / {data.order?.workOrders[0]?.mainProcessCode.description}</div> 
        </div>
        <div className="col-6 text-center align-content-center	  align-items-center justify-content-center">
      
         
          
          <span className="font-bold text-5xl pl-3 ">
            {
              //data.order?.workOrders[0]?.mainProcessCode.description
             // data.order?.orderDefinition?.name
            }
            
             {data.voucher.current.title}  
          </span>
          <div className="font-bold p-text-center">Sipariş Tarihi :   {formatDate(data.order?.orderDate)} </div> 
          
          
        </div>
        <div className="col-3 pr-3   ">
          {data.order &&
          data.order.workOrders &&
          data.order.workOrders.length > 0 ? (
            <div
              style={{
                height: "auto",
                margin: "0 auto",
                maxWidth: 64,
                width: "100%",
          
              }}
            >
              <QRCode
                size={256}
                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                value={data.order.workOrders[0]?.operationBarcode}
                viewBox={`0 0 256 256`}
              />
            </div>
          ) : (
            <></>
          )}
         
        </div>
      </div>
      <div style={{ height: "100px" }}></div>
      <div
        className="grid pl-3 align-content-center  "
        style={{ height: "250px" }}
      >
        <div className="col-12">
          <table className="w-full ">
            <tbody className="align-content-center">
              <tr className=" text-2xl  font-bold">
                <td className="w-3 pb-3">KALİTE :</td>
                <td className="pb-3">
                  {//data.voucher.current.title} &nbsp;&nbsp; /&nbsp;&nbsp;{" " 
                  }
                  {data.stock.name}
                </td>
              </tr> 

              <tr className=" text-2xl  ">
                <td className="  pb-3">PARTİ NO :</td>
                <td className="  pb-3">
                  {data.voucher.voucherNo + "/" + data.parcel}
                </td>
              </tr>
              <tr className=" text-3xl font-bold">
                <td className="  pb-3">İŞLEM / RENK :</td>
                <td className="  pb-3">
                  {
                    data.order?.orderDefinition?.name
                    //reçete varsa  renk kodu / adı yoksa orderdifinitionname gelecek
                  }
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div style={{ height: "80px" }}></div>
      <div className="grid pl-4 pr-1 pb-2 h-4rem border-double border-x-none border-top-none ">
        <table
          style={{
            width: "100%",
            borderCollapse: "collapse",
            tableLayout: "fixed",
          }}
        >
          <thead>
            <th className=" text-lg font-bold text-center">Miktar(MT)</th>
            <th className=" text-lg font-bold text-center">Miktar(KG)</th>
            <th className=" text-lg font-bold text-center">Miktar(T/Ç)</th>
            <th className=" text-lg font-bold text-center"></th>
            <th className=" text-lg font-bold text-center">İstenen En</th>
            <th className=" text-lg font-bold text-center">İstenen Gr</th>
            <th className="text-lg font-bold text-center">Ham Gr</th>
          </thead>
          <tbody>
            <tr>
              <td className=" text-xl font-bold  text-center">{data.meters}</td>
              <td className="text-xl  font-bold  text-center">{data.kilos}</td>
              <td className="text-xl  font-bold text-center">{data.rollCount}</td>
              <td className="text-xl  font-bold text-center"></td>
              <td className="text-xl  font-bold text-center">
                {data.order?.requestedWidth}
              </td>
              <td className="text-xl font-bold text-center">
                {data.order?.requestedGrammage}
              </td>
              <td className="text-xl font-bold text-center">
                {Math.floor((data.kilos / data.meters) * 1000)}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="grid pl-3 pt-3" style={{ height: "500px" }}>
        <div className="col-12  " style={{ height: "500px" }}>
          {data.order &&
          data.order.workOrders &&
          data.order.workOrders.length > 0 ? (
            <DataTable
       
            value={data.order?.workOrders[0].workOrderRoutes}
            size="large"
            expandedRows={expandedRows}
            onRowToggle={(e) => setExpandedRows(e.data)}
            rowExpansionTemplate={rowExpansionTemplate} 
          
          >
            <Column
              headerClassName="text-sm font-bold  bg-white border-bottom-1 border-700	border-top-none border-right-none	 "
              field="station.name"
              header="İstasyon Adı"
              className="border-none  text-xl"
              body={stationMachineBody}
            />
            <Column
              headerClassName="text-sm font-bold  bg-white border-bottom-1 border-700	border-top-none border-right-none	 "
              className="border-none"
              body={orderDefinitionBody}
              field="station.name"
              header="İşlem"
            />
            <Column
              headerClassName="text-sm font-bold  bg-white border-bottom-1 border-700	border-top-none border-right-none"
              className="border-none"
              body={processDefinitionBody}
              field="station.name"
              header="Ek İşlem"
            />
            <Column
              headerClassName="text-sm font-bold bg-white border-bottom-1 border-700	border-top-none border-right-none"
              className="border-none"
              field="description"
              header="Açıklama"
            />
          </DataTable>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="text-right pr-3">
        <div className="p-d-flex p-ai-center p-jc-between p-shadow-2 p-p-3 ">
          <div className="p-d-flex p-ai-center ">
            <div className="grid">
              <div className="col-9 h-4rem">
                <span className="p-text-bold">Onay</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="px-3 py-3 ">
        <div className="flex p-1 gap-1 mb-1 align-items-center h-3rem">
          <span className="flex align-items-center w-2rem  justify-content-center">
            KG
          </span>
          {Array.from({ length: 14 }, (_, index) => (
            <div
              className="flex align-items-center justify-content-center w-5rem h-3rem border-1 border-600"
              key={index}
            ></div>
          ))}
        </div>
        <div className="flex p-1 gap-1 mb-1  align-items-center h-3rem">
          <span className="flex align-items-center w-2rem   justify-content-center">
            MT
          </span>
          {Array.from({ length: 14 }, (_, index) => (
            <div
              className="flex align-items-center justify-content-center w-5rem h-3rem border-1 border-600"
              key={index}
            ></div>
          ))}
        </div>
        <div className="flex p-1 gap-1 mb-1  align-items-center h-3rem">
          <span className="flex align-items-center w-2rem  justify-content-center">
            KG
          </span>
          {Array.from({ length: 14 }, (_, index) => (
            <div
              className="flex align-items-center justify-content-center w-5rem h-3rem border-1 border-600"
              key={index}
            ></div>
          ))}
        </div>
        <div className="flex p-1 gap-1 mb-1  align-items-center h-3rem">
          <span className="flex align-items-center w-2rem justify-content-center">
            MT
          </span>
          {Array.from({ length: 14 }, (_, index) => (
            <div
              className="flex align-items-center justify-content-center w-5rem h-3rem border-1 border-600"
              key={index}
            ></div>
          ))}
        </div>
      </div>
      <div className="grid">
        <table className=" col-5 pl-5  ">
          <tbody className="text-xs   ">
            <td>Sipariş No : </td>
            <td>{data.order?.orderNo}</td>
            <td>Termin Tarihi :</td>
            <td>{formatDate(data.order?.deadline)}</td>
          </tbody>
        </table>

        <div className=" col-7 text-right pr-6">
          <span>BK/015/00</span>
        </div>
      </div>
    </div>
  );
});

export default OrderPrint;
