import { getToken } from "./apiHelper";
import api from './api'; 
export const getStocksListService = async (pageIndex, pageSize,body) => {
  try {
    let axiosConfig = {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
      params: {
        PageIndex: pageIndex,
        PageSize: pageSize,
      },
    };
  
    const response = await api.post(`/Stocks/GetList`,
      body
      ,  axiosConfig); 
 
    return response.data;
  } catch (error) { 
    throw error;
  }
};


export const addStockService = async (data) => {
 
  try {
    let axiosConfig = {
      headers: {
        Authorization: "Bearer " + getToken(),
      }, 
    };
  
    const response = await api.post(`/stocks`,data, axiosConfig); 
 
    return response.data;
  } catch (error) {
    console.error("Login error:", error);
    throw error;
  }
};
export const updateStockService = async (data) => {
  try {
    let axiosConfig = {
      headers: {
        Authorization: "Bearer " + getToken(),
      }, 
    };
  
    const response = await api.put(`/stocks`,data, axiosConfig); 
 
    return response.data;
  } catch (error) {
    console.error("Login error:", error);
    throw error;
  }
};

export const deleteStockService = async (data) => {
  try {
    let axiosConfig = {
      headers: {
        Authorization: "Bearer " + getToken(),
      } 
    };
  
    const response = await api.delete(`/stocks/`+data.id, axiosConfig); 
 
    return response.data;
  } catch (error) {
    console.error("Login error:", error);
    throw error;
  }
};