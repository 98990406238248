import React, { useEffect, useRef } from "react";
import Dashboard from "../dashboard/Dashboard";
import Stocks from "../stocks/Stocks";
import Currents from "../currents/Currents";
import SideBar from "../sidebar/Sidebar";
import Navi from "../navi/Navi";
import Footer from "../footer/Footer";
import { Routes, Route, useLocation } from "react-router-dom";
import LoadingIndicator from "../common/LoadingIndicator";
import Shipment from "../shipment/Shipment";
import AcceptGoods from "../acceptgoods/AcceptGoods";
import Planning from "../planning/Planning";
import { selectMenuItem } from "../../reducers/sidebarSlice";

import { useDispatch, useSelector } from "react-redux";
import OrderDefinitions from "../planning/OrderDefinitions";
import BusinessDefinitions from "../business/BusinessDefinitions";
import Recipes from "../planning/Recipes";
import { Toast } from "primereact/toast";
import { setToast } from "../../reducers/toastSlice";
export default function AppIndex() {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    const pathToMenuItem = {
      "/y/dashboard": "Gösterge Paneli",
      "/y/stocks": "Stok Yönetimi",
      "/y/currents": "Cari Yönetimi",
      "/y/acceptgoods": "Mal Kabul",
      "/y/shipment": "Sevkiyat",
      "/y/planning": "İş Emri Listesi",
      "/y/recipes": "Reçete Sistemi",
      "/y/orderdefinitions": "Sipariş Tanımları",
      "/y/businessdefinitions": "İşletme Tanımları",
    };

    const currentPath = location.pathname;
    const menuItem = pathToMenuItem[currentPath];

    if (menuItem) {
      dispatch(selectMenuItem(menuItem));
    }
  }, [location, dispatch]);

  const toastRef = useRef(null);
  const toast = useSelector((state) => state.toast.toast);
  useEffect(() => {
    if (toast) {
      toastRef.current.show(toast);
      dispatch(setToast(null));
    }
  }, [toast, dispatch]);

 
  return (
    <div className="flex h-screen  ">
     
      <LoadingIndicator />
      <Toast ref={toastRef} />
      <SideBar />
      <div className="flex flex-1 flex-column h-screen  w-screen w-2">
        <Navi />
        <div className="flex-1  p-2 overflow-auto">
          <Routes>
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="stocks" element={<Stocks />} />
            <Route path="currents" element={<Currents />} />
            <Route path="acceptgoods" element={<AcceptGoods />} />
            <Route path="shipment" element={<Shipment />} />
            <Route path="planning" element={<Planning />} />
            <Route path="recipes" element={<Recipes />} />
            <Route path="orderdefinitions" element={<OrderDefinitions />} />
            <Route
              path="businessdefinitions"
              element={<BusinessDefinitions />}
            />
          </Routes>
        </div>
        <Footer />
      </div>
    </div>
  );
}
