import { getToken } from "./apiHelper";
import api from "./api";

export const getRecipes = async (pageIndex, pageSize, body) => {
  try { 
    let axiosConfig = {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
      params: {
        PageIndex: pageIndex,
        PageSize: pageSize,
      },
    };
    const response = await api.post(
      `/recipes/getlist`,
      body,
      axiosConfig
    );
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const addRecipe = async (data) => {
  try {
    const response = await api.post(`/recipes`, data);

    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
}; 

export const updateRecipe = async (data) => {
  try {
    let axiosConfig = {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    };

    const response = await api.put(`/recipes`, data, axiosConfig);

    return response.data;
  } catch (error) {
    console.error("Login error:", error);
    throw error;
  }
};

export const deleteRecipe = async (id) => {
    try {
      const response = await api.delete(`/recipes/`+ id);
  
      return response.data;
    } catch (error) {
      console.error("error:", error);
      throw error;
    }
  };

  
export const addRecipeGroup = async (data) => {
  try {
    const response = await api.post(`/recipegroup`, data);

    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
}; 

export const updateRecipeGroup = async (data) => {
  try {
    let axiosConfig = {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    };

    const response = await api.put(`/recipegroup`, data, axiosConfig);

    return response.data;
  } catch (error) {
    console.error("Login error:", error);
    throw error;
  }
};

export const deleteRecipeGroup = async (id) => {
    try {
      const response = await api.delete(`/recipegroup/`+ id);
  
      return response.data;
    } catch (error) {
      console.error("error:", error);
      throw error;
    }
  };