import React, { useEffect, useState, useRef, useCallback } from "react"; 
import {
  deleteStockService,
  getStocksListService,
} from "../../services/stocksServices"; 
import { Button } from "primereact/button"; 
import { Toast } from "primereact/toast";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import StockAddEdit from "./StockAddEdit";
import { useSelector } from "react-redux"; 
import EDataTable from "../toolbox/EDataTable";
import { Skeleton } from "primereact/skeleton";
import GlobalFilter from "../toolbox/GlobalFilter";
import TitleFilter from "../toolbox/TitleFilter";
 
const Stocks = () => {
 
  const [first, setFirst] = useState(0);
  const [page, setPage] = useState(0);
  const loading = useSelector((state) => state.loading.isLoading);
  const [rows, setRows] = useState(10);
  const [data, setData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [filtreler, setFiltreler] = useState([]);  
  const sort = [
    {
      field: "code",
      dir: "desc",
    },
  ];
  const [filters, setFilters] = useState({sort})
  const [visible, setVisible] = useState(false);  
  const toast = useRef(null);
  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
    setPage(event.page);
  };
  
  const renderHeader = () => {
    return (
     <div>
       <div className="flex justify-content-between">
        <div className="flex  ">
          <Button
            severity="success"
            type="button"
            icon="pi pi-plus"
            label="Yeni"
            outlined
            onClick={() => handleAddClick()}
          />
        </div>
     
        <div className="flex  gap-1"> 
        <div className="  hidden sm:block w-12  " >
          <GlobalFilter
            service={getStocksListService}
            setData={setData}
            rows={rows}
            globalFilterFields={["code", "name", "current.title"]}
          />
        </div>
         <div>
         <Button
            type="button"
            icon="pi pi-refresh"
            outlined
            onClick={() => fetchData()}
          />
         </div>
        </div>
      </div>
      <div className=" block  pt-2 sm:hidden w-12 " >
          <GlobalFilter
            service={getStocksListService}
            setData={setData}
            rows={rows}
            globalFilterFields={["code", "name", "current.title"]}
          />
        </div>
     </div>
    );
  };

  const fetchData = useCallback(async () => {
    try {
      const result = await getStocksListService(page, rows, filters);
    
      setData(result.items);
      setTotalRecords(result.count);
    } catch (error) {
      console.error("Veri alınamadı:", error);
    }
  }, [page, rows, filters]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const [dataRow, setRowData] = useState(null);

  const handleCloseDialog = () => {
    fetchData();
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="flex">
        <Button
          text
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success p-mr-2"
          onClick={() => handleEditClick(rowData)}
        />
        <Button
          text
          icon="pi pi-trash"
          className="p-button-rounded p-button-danger"
          onClick={() => handleDeleteClick(rowData)}
        />
      </div>
    );
  };

  const handleEditClick = (rowData) => {
    setVisible(true);
    setRowData(rowData);
  };

  const handleAddClick = () => {
    setRowData(null);
    setVisible(true);
  };

  const handleDeleteClick = (rowData) => {
    confirmDialog({
      message: "Stoğu silmek üzeresin! Emin misin?",
      header: "Stok Siliniyor",
      icon: "pi pi-info-circle",
      defaultFocus: "reject",
      acceptClassName: "p-button-danger",
      position: "right",
      acceptLabel: "Evet",
      rejectLabel: "Hayır",
      accept:()=>accept(rowData),
      reject,
    });  
  };

  const accept = async (rowData) => {
    await deleteStockService(rowData);
    fetchData(first, rows);
    toast.current.show({
      severity: "info",
      detail: "Stok Başarıyla Silindi",
      life: 3000,
    });
  };

  const reject = () => {
    toast.current.show({
      severity: "warn",
      detail: "Stok silme işlemi iptal edildi.",
      life: 3000,
    });
  };


  const bodyTemplate = () => (rowData) => {
    return loading ? (
      <Skeleton width="100%" height="2rem" />
    ) : (
      <span>{rowData.current?rowData.current.title :""}</span>
    );
  };
  const columns = [
    {
      field: "code",
      header: "Stok Kodu",
      filter: true,
      showFilterMenu: false,
      showClearButton:false,
      filterPlaceholder: "Stok Kodu Ara",
      style: { minWidth: "12rem" },
      filterElement: (options) => ( 
        <TitleFilter
          {...options}
          placeholder="Kod Ara"
          field={"code"}  
           operator ="contains"  
          filtreler={filtreler}
          setFiltreler={setFiltreler} 
          setFilters={setFilters}
          sort={sort}
        />
      ),
    },
    {
      field: "name",
      header: "Stok Adı",
      filter: true,
      showFilterMenu: false,
      showClearButton:false,
      filterPlaceholder: "Stok Adı Ara",
      style: { minWidth: "12rem" }, 
       filterElement: (options) => ( 
        <TitleFilter
          {...options}
          placeholder="Adında Ara"
          field={"name"}  
           operator ="contains"  
          filtreler={filtreler}
          setFiltreler={setFiltreler} 
          setFilters={setFilters}
          sort={sort}
        />
      ),
    },
    {
      field: "current.title", 
      header: "Stok Carisi",
      filter: true,
      showFilterMenu: false,
      showClearButton:false, 
      style: { minWidth: "12rem" },
      body:bodyTemplate("current"),
      filterElement: (options) => ( 
       <TitleFilter
         {...options}
         placeholder="Kod Ara"
         field={"current.title"}  
          operator ="contains"  
         filtreler={filtreler}
         setFiltreler={setFiltreler} 
         setFilters={setFilters}
         sort={sort}
       />
     ),
    },
    {
      header: "/",
      alignHeader: "center",
      align: "center",  
      headerStyle: { width: "4rem", textAlign: "center" },
      bodyStyle: { textAlign: "center", overflow: "visible" },
      body: actionBodyTemplate,
    },
  ];

  const header = renderHeader();

  return (
    <div className="surface-card p-2 border-round-sm">
      <Toast ref={toast} />
      <ConfirmDialog />
      <StockAddEdit
        setVisible={setVisible}
        visible={visible}
        dataRow={dataRow}
        onDialogClose={handleCloseDialog}
        toast={toast}
      />
      <EDataTable
        data={data}
        columns={columns}
        totalRecords={totalRecords}
        first={first}
        rows={rows}
        onPageChange={onPageChange} 
        header={header}
        loading={loading}
        globalFilterFields={["id", "code", "name","current.title"]} 
      />
    </div>
  );
};

export default Stocks;
