import React from "react";
import { useSelector, useDispatch } from "react-redux"; 
import MenuPanel from "./MenuPanel";
import useSize from "../common/useSize";
import { Sidebar } from "primereact/sidebar";
import { toggleSidebar } from "../../reducers/sidebarSlice";

const SideBar = () => {
  const dispatch = useDispatch();

  const menuOpen = useSelector((state) => state.sidebar.menuOpen);
  const windowsize = useSize();

  return windowsize[0] >= 1000 ? (
    <div     className={` border-round-right  shadow-5 overflow-x-hidden overflow-auto transition-all transition-ease-in-out transition-duration-500 surface-card   ${
      menuOpen ? "menu-open" : "menu-closed"
    }`}
    >
      {MenuPanel()}
    </div>
  ) : (
    <Sidebar  
      className="overflow-auto"
      closeIcon
      content={MenuPanel()}
      visible={menuOpen}
      position="left"
      onHide={() => dispatch(toggleSidebar(false))}
    ></Sidebar>
  );
};

export default SideBar;
