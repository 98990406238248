import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { useSelector } from "react-redux";
import { Divider } from "primereact/divider";
import FlipMove from "react-flip-move";
import { useReactToPrint } from "react-to-print";

import {
  CreateOrderDefinitionWithDetails,
  getOrderDefinitionsService,
} from "../../services/orderDefinitionsServices";
import { getStationsListService } from "../../services/businessServices";
import {
  addWorkOrder,
  addWorkOrderRoutes,
  deleteAllWorkOrderRoutes,
  deleteWorkOrderRoutes,
  updateWorkOrderRoutes,
} from "../../services/workOrderServices";
import { Badge } from "primereact/badge";
import { ProgressSpinner } from "primereact/progressspinner";
import { ScrollPanel } from "primereact/scrollpanel";
import {
  addOrder,
  updateOrder,
  updateOrderOrderDefinition,
} from "../../services/orderServices";
import { Card } from "primereact/card";
import { setLoading } from "../../reducers/loadingSlice";
import { OverlayPanel } from "primereact/overlaypanel";
import OrderPrint from "../../printPages/OrderPrint";
import { createPortal } from "react-dom";
import { getCodesService } from "../../services/codesServices";

const defaultformdata = {
  id: null,
  kilos: 0,
  meters: 0,
  order: {
    id: "",
    currentId: 0,
    voucherRowId: "",
    orderDate: "",
    deadline: "",
    requestedWidth: 0,
    requestedGrammage: 0,
    orderDefinitionId: 0,
    orderNo: "",
    orderDefinition: [],
    workOrders: [
      {
        workOrderDate: "",
        deadline: "",
        orderId: "",
        
      },
    ],
  },
  parcel: 0,
  rollCount: 0,
  stock: { name: "" },
  stockId: "",
  voucher: {
    date: new Date(),
    current: { title: "" },
  },
  voucherId: "",
};
const PlanningAddEdit = ({
  visible,
  setVisible,
  dataRow,
  onDialogClose,
  toast,
}) => {
  const isLoading = useSelector((state) => state.loading.isLoading);
  const defaultData = useMemo(() => defaultformdata, []);
  const [validationErrors, setValidationErrors] = useState({});
  const [formData, setFormData] = useState(defaultformdata);
  const [orderDefinitionID, setOrderDefinitionID] = useState();
  const [orderDefinitions, setOrderDefinitions] = useState([]);
  const [odList, setOdList] = useState([]);
  const [selectedOrderDefinition, setSelectedOrderDefinition] = useState([]);
  const [stations, setStations] = useState([]);
  const [onay, setOnay] = useState(false);
  const [edit, setEdit] = useState(false);
  const [newOrderDefinition, setNewOrderDefinition] = useState("");
  const [newOrderDefinitionGroup, setNewOrderDefinitionGroup] = useState("");
  const [orderDefinitionsGroups, setOrderDefinitionsGroups] = useState([]);
  const changeNewOrderDefinition = (e) => {
    setNewOrderDefinition(e.target.value);
  };
  const changeNewOrderDefinitionGroup = (e) => {
    setNewOrderDefinitionGroup(e.value);
  };
  useEffect(() => {
    if (visible) {
      console.log(dataRow);
      setFormData(dataRow);
      if (dataRow.order) {
        setOnay(true);
        setOrderDefinitionID(dataRow.order?.orderDefinitionId);
        setNewOrderDefinitionGroup(dataRow.order?.workOrders[0].mainProcessCodeId)
      } else {
        setOnay(false);
        setOrderDefinitionID();
      }
      setEdit(false);
      setNewOrderDefinition("");
      setSelectedOrderDefinition();
      setValidationErrors({});
    }
  }, [dataRow, visible, defaultData]);
  const timerRef = useRef(null);
  const enGramajChange = (e) => {
    console.log(e);
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    const { name, value } = e.target;
    const newFormData = { ...formData };
    if (name == "order.requestedGrammage") {
      newFormData.order.requestedGrammage = value;
    } else {
      newFormData.order.requestedWidth = value;
    }

    setFormData(newFormData);
    timerRef.current = setTimeout(async () => {
      const newOrder = {
        id: formData.order.id,
        currentId: formData.order.currentId,
        voucherRowId: formData.order.voucherRowId,
        orderDate: formData.order.orderDate,
        deadline: formData.order.deadline,
        ...(formData.order.requestedWidth && {
          requestedWidth: formData.order.requestedWidth,
        }),
        ...(formData.order.requestedGrammage && {
          requestedGrammage: formData.order.requestedGrammage,
        }),
        ...(formData.order.orderDefinitionId && {
          orderDefinitionId: formData.order.orderDefinitionId,
        }),
      };
      await updateOrder(newOrder);
    }, 500);
  };

  const OrderDifinitionsChange = (name, value) => {
    setOrderDefinitionID(value);
    setValidationErrors((prevState) => ({
      ...prevState,
      [name]: null,
    }));
    const selectOrderDefinition = odList.items.find(
      (orderDef) => orderDef.id == value
    );
    setSelectedOrderDefinition(selectOrderDefinition);
    setNewOrderDefinitionGroup(selectOrderDefinition.mainProcessCodeId)
    console.log(selectOrderDefinition);
  };
  const fetchStationsList = async () => {
    try {
      const resultStations = await getStationsListService(0,1000);
      console.log(resultStations);
      return resultStations.items;
    } catch (error) {
      console.error("Stations listesi alınamadı:", error);
      throw error;
    }
  };

  const fetchOrderDefinitions = async (stockId) => {
    try {
      const resultOrderDefinitions = await getOrderDefinitionsService(0, 1000, {
        filter: {
          field: "definitionTypeCodeId",
          operator: "eq",
          value: "30",
          logic: "or",
          filters: [
            {
              field: "stockId",
              operator: "eq",
              value: stockId,
              logic: "and",
            },
          ],
        },
      });
      const formattedOrderDefinitions = resultOrderDefinitions.items.map(
        (item) => ({
          label: item.name,
          value: item.id,
        })
      );
      console.log(resultOrderDefinitions);
      setOdList(resultOrderDefinitions);
      setOrderDefinitions(formattedOrderDefinitions);
      return { resultOrderDefinitions, formattedOrderDefinitions };
    } catch (error) {
      console.error("Order definitions alınamadı:", error);
      throw error;
    }
  };
  const fetchData = useCallback(async () => {
    try {
      if (visible) {
        const stations = await fetchStationsList();
        setStations(stations);
        const resultGroups =
          orderDefinitionsGroups.length <= 0
            ? await getCodesService(10)
            : { items: orderDefinitionsGroups };

        setOrderDefinitionsGroups(resultGroups.items);
        await fetchOrderDefinitions(dataRow.stockId);
      }
    } catch (error) {
      console.error("Veri alınamadı:", error);
    }
  }, [visible]);
  const [newFetch, setNewFetch] = useState(false);
  useEffect(() => {
    if (visible) {
      fetchData();
      setNewFetch(true);
    } else {
      if (newFetch) {
        onDialogClose();
        setSelectedOrderDefinition();
        setNewFetch(false);
        setOnay(false);
      }
    }
  }, [visible]);
  const cancelSelected = () => {
    setSelectedOrderDefinition();
    setOrderDefinitionID(formData.order?.orderDefinitionId);
  };
  const okSelected = async () => {
    console.log(selectedOrderDefinition);
    if (selectedOrderDefinition.details.length > 0) {
      if (formData.order) {
        upOrderFormNew();
      } else {
        addOrderForm();
      }
      setOnay(true);
      setSelectedOrderDefinition();
    } else {
      toast.current.show({
        severity: "error",
        detail: "Rotasyonu olmayan İşlem Seçilemez",
        life: 3000,
      });
    }
  };
  const transformWorkOrderRoutes = (workOrderRoutes) => {
    return {
      name: newOrderDefinition,
      description: null,
      currentId: formData.voucher.currentId,
      stockId: formData.stockId,
      definitionTypeCodeId: 31,
      mainProcessCodeId: newOrderDefinitionGroup,
      details: workOrderRoutes.map((route) => ({
        sequence: route.sequenceNo,
        stationId: route.stationId,
  
        machineId: route.machineId ? route.machineId : null,
        description: route.description ? route.description : null,
        recipeId: route.recipeId ? route.recipeId : null,
        processDefinitionId: route.processDefinitionId
          ? route.processDefinitionId
          : null,
        orderDefinitionDetailNotes: route.notes
          ? route.notes.map((note) => ({
              stockId: formData.stockId,
              machineId: note.machineId ? note.machineId : null,
              noteTypeCodeId: note.noteTypeCodeId ? note.noteTypeCodeId : null,
              stationId: note.stationId ? note.stationId : null,
              note: note.note ? note.note : null,
            }))
          : [],
      })),
    };
  };

  const createOrderDefinition = async () => {
    if (newOrderDefinition.length > 0) {
      if (formData.order?.workOrders[0]?.workOrderRoutes?.length > 0) {
        const workOrderRoutes = formData.order.workOrders[0].workOrderRoutes;
        const newData = transformWorkOrderRoutes(workOrderRoutes);
        await CreateOrderDefinitionWithDetails(newData).then(
          async (newOrderDif) => {
            const orderData = {
              id: formData.order.id,
              currentId: formData.voucher.currentId,
              voucherRowId: formData.id,
              orderDate: formData.order.orderDate,
              deadline: formData.order.deadline,
              requestedWidth: formData.order?.requestedWidth || 0,
              requestedGrammage: formData.order?.requestedGrammage || 0,
              orderDefinitionId: newOrderDif.id,
            };
            await updateOrder(orderData).then(async (data) => {
              const updatedData = {
                ...formData.order,
                ...orderData,

                orderDefinition: newData,
              };
              setFormData((prevState) => ({
                ...prevState,
                order: updatedData,
              }));
              console.log(formData);
              await fetchOrderDefinitions(formData.stockId);
              setOrderDefinitionID(newOrderDif.id);
              setEdit(false);
              setNewOrderDefinition("");
            });
          }
        );
      } else {
        toast.current.show({
          severity: "warn",
          detail: "İstasyon sıralaması olmadan Tanım eklenemez!.",
          life: 3000,
        });
      }
    } else {
      toast.current.show({
        severity: "error",
        detail: "İşlem adı yazılmalıdır.",
        life: 3000,
      });
    }
  };
  const upOrderFormNew = async () => {
    const orderData = {
      orderId: formData.order.id,
      orderDefinitionId: orderDefinitionID,
      workOrderId: formData.order.workOrders[0].id,
    };
    await updateOrderOrderDefinition(orderData).then(async (data) => {
      console.log(data);

      setFormData((prevState) => ({
        ...prevState,
        order: {
          ...prevState.order,
          orderDefinitionId: orderDefinitionID,
          orderDefinition: selectedOrderDefinition,
          workOrders: [
            {
              ...prevState.order.workOrders[0],
              workOrderRoutes: data.workOrderRoutes, // Toplanan workOrderRoutes
            },
          ],
        },
      }));
    });
  };
  const addOrderForm = async () => {
    const orderData = {
      currentId: formData.voucher.currentId,
      voucherRowId: formData.id,
      orderDate: new Date().toISOString(),
      deadline: new Date(
        new Date().getTime() + 7 * 24 * 60 * 60 * 1000
      ).toISOString(),
      requestedWidth: formData.order?.requestedWidth || 0,
      requestedGrammage: formData.order?.requestedGrammage || 0,
      orderDefinitionId: orderDefinitionID,
    };
    await addOrder(orderData).then(async (data) => {
      console.log(data);
      setFormData((prevState) => ({
        ...prevState,
        order: {
          ...data,
          orderDefinitionId: orderDefinitionID,
          orderDefinition: selectedOrderDefinition,
        },
      }));
      const workOrderData = {
        workOrderDate: new Date().toISOString(),
        deadline: new Date(
          new Date().getTime() + 7 * 24 * 60 * 60 * 1000
        ).toISOString(),
        orderId: data.id,
        mainProcessCodeId: selectedOrderDefinition.mainProcessCodeId,
      };
      await addWorkOrder(workOrderData).then(async (wodata) => {
        console.log(wodata);

        const workorderRoute = [];
        for (const detail of selectedOrderDefinition.details) {
          const newWorkOrderRoutes = {
            workOrderId: wodata.id,
            stationId: detail.stationId,
            sequenceNo: detail.sequence,
            ...(detail.recipeId && { recipeId: detail.recipeId }),
            ...(detail.processDefinitionId && {
              processDefinitionId: detail.processDefinitionId,
            }),
          };

          const result = await addWorkOrderRoutes(newWorkOrderRoutes);
          console.log(result);
          result.station = detail.station;
          workorderRoute.push(result);
        }

        // workOrderRoutes tamamlandığında formData'yı güncelle
        setFormData((prevState) => ({
          ...prevState,
          order: {
            ...prevState.order,
            orderDefinition: selectedOrderDefinition,
            workOrders: [
              ...(prevState.order.workOrders || []),
              {
                ...wodata,
                workOrderRoutes: workorderRoute, // Toplanan workOrderRoutes
              },
            ],
          },
        }));
        setEdit(false);
      });
    });
  };
  const handleAddItem = async (box) => {
    const detailData = {
      workOrderId: formData.order.workOrders[0].id,
      sequenceNo: formData.order.workOrders[0].workOrderRoutes.length + 1,
      stationId: box.id,
    };
    await addWorkOrderRoutes(detailData).then((result) => {
      result.station = box;
      console.log(box);
      setFormData((prevState) => {
        const updatedWorkOrders = prevState.order.workOrders.map(
          (workOrder, index) => {
            if (index === 0) {
              return {
                ...workOrder,
                workOrderRoutes: [...workOrder.workOrderRoutes, result],
              };
            }
            return workOrder;
          }
        );

        return {
          ...prevState,
          order: {
            ...prevState.order,
            workOrders: updatedWorkOrders,
          },
        };
      });
    });
  };

  const removeItem = async (index, item) => {
    await deleteWorkOrderRoutes(item.id);

    setFormData((prevState) => {
      const updatedWorkOrders = prevState.order.workOrders.map(
        (workOrder, i) => {
          if (i === 0) {
            const updatedWorkOrderRoutes = [...workOrder.workOrderRoutes];
            updatedWorkOrderRoutes.splice(index, 1);
            return {
              ...workOrder,
              workOrderRoutes: updatedWorkOrderRoutes,
            };
          }
          return workOrder;
        }
      );

      return {
        ...prevState,
        order: {
          ...prevState.order,
          workOrders: updatedWorkOrders,
        },
      };
    });
  };

  const moveItem = async (fromIndex, toIndex) => {
    const updatedWorkOrderRoutes = [
      ...formData.order.workOrders[0].workOrderRoutes,
    ];
    const [movedItem] = updatedWorkOrderRoutes.splice(fromIndex, 1);
    updatedWorkOrderRoutes.splice(toIndex, 0, movedItem);

    // İlgili iki öğenin sequenceNo değerlerini güncelle
    const item1 = updatedWorkOrderRoutes[toIndex];
    const item2 = updatedWorkOrderRoutes[fromIndex];

    const tempSequence = item1.sequenceNo;
    item1.sequenceNo = item2.sequenceNo;
    item2.sequenceNo = tempSequence;

    // Durumu güncelle
    setFormData((prevFormData) => {
      const updatedWorkOrders = prevFormData.order.workOrders.map(
        (workOrder, i) => {
          if (i === 0) {
            return {
              ...workOrder,
              workOrderRoutes: updatedWorkOrderRoutes,
            };
          }
          return workOrder;
        }
      );

      return {
        ...prevFormData,
        order: {
          ...prevFormData.order,
          workOrders: updatedWorkOrders,
        },
      };
    });

    // Yer değiştirilen öğeleri güncelle
    const item1Request = {
      id: item1.id,
      workOrderId: item1.workOrderId,
      sequenceNo: item1.sequenceNo,
      stationId: item1.stationId,
      ...(item1.machineId && { machineId: item1.machineId }),
      ...(item1.recipeId && { recipeId: item1.recipeId }),
      // ...(item1.personelId && { personelId: item1.personelId }),
      ...(item1.description && { description: item1.description }),
      ...(item1.processDefinitionId && {
        processDefinitionId: item1.processDefinitionId,
      }),
    };

    const item2Request = {
      id: item2.id,
      workOrderId: item2.workOrderId,
      sequenceNo: item2.sequenceNo,
      stationId: item2.stationId,
      ...(item2.machineId && { machineId: item2.machineId }),
      ...(item2.recipeId && { recipeId: item2.recipeId }),
      ...(item2.personelId && { personelId: item2.personelId }),
      ...(item2.description && { description: item2.description }),
      ...(item2.processDefinitionId && {
        processDefinitionId: item2.processDefinitionId,
      }),
    };

    await updateWorkOrderRoutes(item1Request);
    await updateWorkOrderRoutes(item2Request);
  };
  const processDefinitionChange = async (index, value) => {
    const newFormData = { ...formData };
    newFormData.order.workOrders[0].workOrderRoutes = [
      ...newFormData.order.workOrders[0].workOrderRoutes,
    ];

    newFormData.order.workOrders[0].workOrderRoutes[index].processDefinitionId =
      value === undefined ? null : value;

              // Makineyi bulmak için station ve machines dizilerini kontrol et
  const station = newFormData.order.workOrders[0]?.workOrderRoutes[index]?.station;
  if (station && station.processDefinitions) {
    const foundProcess = station.processDefinitions.find(process => process.id === value);

    // Eğer makine bulunduysa, machineId'yi güncelle
    if (foundProcess) {
      newFormData.order.workOrders[0].workOrderRoutes[index].processDefinition = foundProcess;
    } 
  }
    setFormData(newFormData);
    const routes = newFormData.order.workOrders[0].workOrderRoutes[index];
    const iRequest = {
      id: routes.id,
      workOrderId: routes.workOrderId,
      sequenceNo: routes.sequenceNo,
      stationId: routes.stationId,
      machineId: routes.machineId ? routes.machineId : null,
      recipeId: routes.recipeId ? routes.recipeId : null,
      description: routes.description ? routes.description : null,
      personelId: routes.personelId ? routes.personelId : null,
      processDefinitionId: value ? value : null,
      mainProcessCodeId:routes.mainProcessCodeId ? routes.mainProcessCodeId : null
    };

    await updateWorkOrderRoutes(iRequest);
  };
  const machineChange = async (index, value) => {
    console.log(index + " / " + value);
    const newFormData = { ...formData };
    newFormData.order.workOrders[0].workOrderRoutes = [
      ...newFormData.order.workOrders[0].workOrderRoutes,
    ];
    newFormData.order.workOrders[0].workOrderRoutes[index].machineId =
      value === undefined ? null : value;

        // Makineyi bulmak için station ve machines dizilerini kontrol et
  const station = newFormData.order.workOrders[0]?.workOrderRoutes[index]?.station;
  if (station && station.machines) {
    const foundMachine = station.machines.find(machine => machine.id === value);

    // Eğer makine bulunduysa, machineId'yi güncelle
    if (foundMachine) {
      newFormData.order.workOrders[0].workOrderRoutes[index].machine = foundMachine;
    } 
  }
    setFormData(newFormData);
    const routes = newFormData.order.workOrders[0].workOrderRoutes[index];
    const iRequest = {
      id: routes.id,
      workOrderId: routes.workOrderId,
      sequenceNo: routes.sequenceNo,
      stationId: routes.stationId,
      machineId: value ? value : null,
      recipeId: routes.recipeId ? routes.recipeId : null,
      description: routes.description ? routes.description : null,
      personelId: routes.personelId ? routes.personelId : null,
      processDefinitionId: routes.processDefinitionId
        ? routes.processDefinitionId
        : null,
        mainProcessCodeId:routes.mainProcessCodeId ? routes.mainProcessCodeId : null
    };

    await updateWorkOrderRoutes(iRequest);
  };

  const selectedItemTemplate = (option, props) => {
    if (option) {
      return (
        <div className="text-xs	">
          <div>{option.label}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };
  const op = useRef(null);

  useEffect(() => {
    const areDetailsChanged = (details, routes) => {
      // details ve routes dizilerini sıralayalım
      const sortedDetails = [...details].sort(
        (a, b) => a.sequence - b.sequence
      );
      const sortedRoutes = [...routes].sort(
        (a, b) => a.sequenceNo - b.sequenceNo
      );

      if (sortedDetails.length !== sortedRoutes.length) {
        return true;
      }

      for (let i = 0; i < sortedDetails.length; i++) {
        const detail = sortedDetails[i];
        const route = sortedRoutes[i];

        const isEqualOrNull = (a, b) =>
          a === b || (a == null && b === 0) || (a === 0 && b == null);

        if (
          detail.processDefinitionId !== route.processDefinitionId ||
          !isEqualOrNull(detail.recipeId, route.recipeId) ||
          detail.sequence !== route.sequenceNo ||
          !isEqualOrNull(detail.stationId, route.stationId) ||
          !isEqualOrNull(detail.machineId, route.machineId)
        ) {
          return true;
        }
      }

      return false;
    };

    if (
      formData.order?.orderDefinition?.details &&
      formData.order?.workOrders?.[0]?.workOrderRoutes
    ) {
      if (
        areDetailsChanged(
          formData.order.orderDefinition.details,
          formData.order.workOrders[0].workOrderRoutes
        )
      ) {
        setEdit(true);
      } else {
        setEdit(false);
      }
    } else {
      // Eğer gerekli veriler mevcut değilse, edit durumunu false yapalım
      setEdit(false);
    }
  }, [
    formData.order?.orderDefinition?.details,
    formData.order?.workOrders?.[0]?.workOrderRoutes,
  ]);
 
  const printRef = useRef();

  // Yazdırma işlemini başlat

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    pageStyle: `
      @page {
        size: A4;
        margin: 0;
      }
      @media print {
        body {
          margin: 0;
          -webkit-print-color-adjust: exact;
        }
        div {
          width: 100%;
          height: 100%;
        }
      }
    `,
  });

  return (
    <Dialog
      className="max-w-full w-11 md:w-11 xl:w-8   "
      header={"İş Emri Oluştur"}
      visible={visible}
      onHide={() => setVisible(false)}
    >
      <div className="formgrid grid mt-3  ">
        <div className="col-12 md:col-3">
          <div className="p-inputgroup flex-1 w-12 pb-2">
            <span className="p-inputgroup-addon w-7rem md:w-4rem">Tarih</span>
            <InputText
              className="p-inputgroup"
              value={formData.voucher.date}
              disabled
            />
          </div>
        </div>
        <div className="col-12 md:col-5">
          <div className="p-inputgroup flex-1 pb-2">
            <span className="p-inputgroup-addon w-7rem md:w-4rem">Cari</span>
            <InputText
              id="currentId"
              name="currentId"
              value={formData.voucher.current.title}
              disabled
            />
          </div>
        </div>
        <div className="col-12 md:col-4 ">
          <div className="p-inputgroup flex-1 pb-2">
            <span className="p-inputgroup-addon w-7rem md:w-4rem">Stok</span>
            <InputText
              id="stok"
              name="stok"
              value={formData.stock.name}
              disabled
            />
          </div>
        </div>
        <div className="col-12 md:col-3">
          <div className="p-inputgroup flex-1 pb-2">
            <span className="p-inputgroup-addon w-7rem md:w-4rem">Parti</span>
            <InputText
              id="serial"
              name="serial"
              value={formData.voucher.voucherNo + " / " + formData.parcel}
              disabled
            />
          </div>
        </div>
        <div className="col-12 md:col-3  ">
          <div className="p-inputgroup flex-1 pb-2">
            <span className="p-inputgroup-addon w-7rem md:w-4rem">Metre</span>
            <InputText id="code" name="code" value={formData.meters} disabled />
          </div>
        </div>
        <div className="col-12 md:col-3  ">
          <div className="p-inputgroup flex-1 pb-2">
            <span className="p-inputgroup-addon w-7rem md:w-4rem">Kilo</span>
            <InputText id="code" name="code" value={formData.kilos} disabled />
          </div>
        </div>
        <div className="col-12 md:col-3  ">
          <div className="p-inputgroup flex-1 pb-2">
            <span className="p-inputgroup-addon  w-7rem md:w-4rem">Top</span>
            <InputText
              id="code"
              name="code"
              value={formData.rollCount}
              disabled
            />
          </div>
        </div>
        <Divider className="  mt-0 mb-2">
          İşlem Seç <i className="pi pi-sort-down" />
        </Divider>
        <div className="col-12 md:col-6  ">
          <div className="p-inputgroup flex-1 pb-2">
            <span className="p-inputgroup-addon  w-7rem md:w-4rem">İşlem</span>
            <Dropdown
              id="order.orderDefinitionId"
              name="order.orderDefinitionId"
              value={orderDefinitionID || ""}
              options={orderDefinitions}
              onChange={(e) =>
                OrderDifinitionsChange("orderDefinitionId", e.value)
              }
              filter
              disabled={isLoading}
            />
          </div>
        </div>
        <div className="col-6 md:col-3  ">
          <div className="p-inputgroup flex-1 pb-2">
            <span className="p-inputgroup-addon  w-5rem md:w-4rem "> En</span>
            <InputText
              id="order.requestedWidth"
              name="order.requestedWidth"
              value={formData.order?.requestedWidth || ""}
              onChange={enGramajChange}
              disabled={isLoading || formData.order ? false : true}
            />
          </div>
        </div>
        <div className="col-6 md:col-3  ">
          <div className="p-inputgroup flex-1 pb-2">
            <span className="p-inputgroup-addon  w-5rem md:w-4rem  ">
              Gramaj
            </span>
            <InputText
              id="order.requestedGrammage"
              name="order.requestedGrammage"
              value={formData.order?.requestedGrammage || ""}
              onChange={enGramajChange}
              disabled={isLoading || formData.order ? false : true}
            />
          </div>
        </div>
        {selectedOrderDefinition ? (
          <div className="w-full ">
            <Divider className="mt-0 mb-2 ">
              <span className="shadow-2 h-5rem w-10rem border-round-md p-1 bg-primary px-3">
                Sipariş Tanımı Görünümü <i className="pi pi-sort-down" />
              </span>
            </Divider>

            <div className="surface-hover border-round-md mb-3 shadow-4 w-full">
              <div className="grid m-0">
                <ScrollPanel className=" col-12  surface-hover border-noround-right p-card md:col-10  planningSbar">
                  <div className="grid max-h-20rem    ">
                    {selectedOrderDefinition.details &&
                      selectedOrderDefinition.details
                        .sort((a, b) => a.sequence - b.sequence)
                        .map((item, index) => (
                          <div
                            key={item.id}
                            className="col-12 sm:col-6 lg:col-4 xl:col-4 p-2 mt-2 "
                          >
                            <div className="relative p-4 border-1 surface-border  surface-card border-round h-16rem">
                              <Badge
                                className="absolute top-0 left-0 m-2"
                                severity="info"
                                value={item.sequence}
                              />
                              <div className="flex flex-wrap align-items-center justify-content-center gap-2 mb-3">
                                <div className="flex align-items-center gap-2">
                                  <span className="font-semibold">
                                    {item.station.name}
                                  </span>
                                </div>
                              </div>
                              {item.machine ? (
                                <div className="p-inputgroup flex-1 pb-2 text-xs	">
                                  <span className="p-inputgroup-addon  w-4rem">
                                    Makine
                                  </span>
                                  <InputText
                                    id="name"
                                    name="name"
                                    value={item.machine.name}
                                    disabled
                                  />
                                </div>
                              ) : null}

                              {item.processDefinition ? (
                                <div className="p-inputgroup flex-1 pb-2 text-xs	">
                                  <span className="p-inputgroup-addon  	 w-4rem">
                                    İşlem
                                  </span>
                                  <InputText
                                    id="name"
                                    name="name"
                                    value={item.processDefinition.name}
                                    disabled
                                  />
                                </div>
                              ) : null}
                            </div>
                          </div>
                        ))}
                  </div>
                </ScrollPanel>
                <div className="col-12 p-card md:col-2 pt-3 p-3 pb-3 border-noround-left ">
                  <div className="w-full ">
                    <Button
                      label="İşlemi Seç"
                      className="w-full mb-2 text-left"
                      icon="pi pi-check"
                      iconPos="right"
                      severity="success"
                      outlined
                      onClick={() => okSelected()}
                      loading={isLoading}
                    />
                    <Button
                      label="İptal"
                      className="w-full mb-2 text-left"
                      icon="pi pi-times"
                      iconPos="right"
                      severity="danger"
                      outlined
                      onClick={() => cancelSelected()}
                      loading={isLoading}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : onay ? (
          <div className="w-full ">
            <Divider className="mt-0 mb-2 ">
              <span className="shadow-2 h-5rem w-10rem border-round-md p-1 bg-primary px-3">
                Rotasyon <i className="pi pi-sort-down" />
              </span>
            </Divider>
            <div className="surface-hover border-round-md mb-3 shadow-4 w-full">
              <Dialog
                visible={isLoading}
                closable={false}
                closeOnEscape={false}
                showHeader={false}
                unstyled
              >
                <div>
                  <div className="w-16rem p-3 p-card flex-column align-items-center justify-content-center text-align-center">
                    <ProgressSpinner
                      className="text-align-center flex"
                      // style={{ width: "50px", height: "50px" }}
                      // strokeWidth="8"
                      // fill="var(--surface-ground)"
                      animationDuration=".5s"
                    />
                    <div className="flex w-full align-items-center justify-content-center">
                      <div className="flex-column text-align-center">
                        <span>İşlem Sürüyor.</span>
                        <br />
                        <span>Lütfen Bekleyin.</span>
                      </div>
                      {/*    <Button icon="pi pi-refresh" className="ml-auto gap-3" onClick={(e)=> setLoading(false) }>
        iptal
      </Button> */}
                    </div>
                  </div>
                  <div className="bg-overlay"></div>
                </div>
              </Dialog>

              <div className="grid m-0  ">
                <ScrollPanel className="   planningSbar col-12 p-card md:col-3     border-noround-right   ">
                  <div className="  ">
                    <div className=" max-h-20rem w-full pt-2 ">
                      {stations.map((box, index) => (
                        <Button
                          key={index}
                          label={box.name}
                          className="w-full mb-1 text-left"
                          icon="pi pi-plus"
                          iconPos="right"
                          outlined
                          onClick={() => handleAddItem(box, index)}
                        />
                      ))}
                    </div>
                  </div>
                </ScrollPanel>
                <ScrollPanel className="    planningSbar col-12   surface-hover border-noround-left p-card md:col-9">
                  <div className="max-h-20rem  ">
                    <div className="flex  ">
                      <div className="col-6 md:col-9 ">
                        <h3>İstasyon Sırası</h3>
                      </div>
                      <div className="col-6 md:col-3  ">
                        {edit ? (
                          <div className="p-inputgroup flex-1 pt-2">
                            <Button
                              label={"Tanım Ekle"}
                              className="w-full mb-1 text-left"
                              icon="pi pi-plus"
                              iconPos="right"
                              outlined
                              onClick={(e) => op.current.toggle(e)}
                            />
                            <OverlayPanel
                              ref={op}
                              showCloseIcon
                              closeOnEscape
                              dismissable={false}
                            >
                              <div className="  p-card">
                                <div className="col-12  ">
                                  <div className="p-inputgroup flex-1 ">
                                    <span className="p-inputgroup-addon  w-8rem   ">
                                      Ana Grup
                                    </span>
                                    <Dropdown
                                      id="sortCodeId"
                                      name="sortCodeId"
                                      value={newOrderDefinitionGroup}
                                      options={orderDefinitionsGroups.map((group) => ({
                                        label: group.description,
                                        value: group.id,
                                      }))}
                                      onChange={(e) =>
                                        changeNewOrderDefinitionGroup(e)
                                      }
                                    />
                                  </div>
                                </div>

                                <div className="col-12  ">
                                  <div className="p-inputgroup flex-1 ">
                                    <span className="p-inputgroup-addon  w-8rem   ">
                                      Tanım Adı
                                    </span>
                                    <InputText
                                      value={newOrderDefinition}
                                      onChange={changeNewOrderDefinition}
                                    />
                                  </div>
                                </div>
                                <div className="col-12   ">
                                  <div className="p-inputgroup flex-1  ">
                                    <Button
                                      label={"Tanım Olarak Ekle"}
                                      className="w-full mb-1 text-left"
                                      icon="pi pi-check"
                                      iconPos="right"
                                      severity="success"
                                      outlined
                                      onClick={() => createOrderDefinition()}
                                    />
                                  </div>
                                </div>
                              </div>
                            </OverlayPanel>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                    <FlipMove
                      duration={300}
                      easing="ease-in-out"
                      className="grid p-flex-wrap ml-0 mt-2 mb-2 mr-1 md:mr-0 "
                    >
                      {formData.order?.workOrders?.length > 0 &&
                        formData.order.workOrders[0]?.workOrderRoutes
                          .sort((a, b) => a.sequenceNo - b.sequenceNo)
                          .map((item, index) => (
                            <div
                              key={item.id}
                              className="col-12 sm:col-6 lg:col-4 xl:col-4 p-2 "
                            >
                              <div className="relative p-4 border-1 surface-border  surface-card border-round h-16rem">
                                <Badge
                                  className="absolute top-0 left-0 m-2"
                                  severity="info"
                                  value={item.sequenceNo}
                                />
                                <div className="flex flex-wrap align-items-center justify-content-center gap-2 mb-3">
                                  <div className="flex align-items-center gap-2">
                                    <span className="font-semibold">
                                      {item.station.name}
                                    </span>
                                  </div>
                                </div>
                                {item.station.machines.length > 0 ? (
                                  <div className="p-inputgroup flex-1 pb-2 text-xs	">
                                    <span className="p-inputgroup-addon  w-4rem">
                                      Makine
                                    </span>
                                    <Dropdown
                                      value={item.machineId || ""}
                                      panelClassName="text-xs	"
                                      options={item.station.machines.map(
                                        (pd) => ({
                                          label: pd.name,
                                          value: pd.id,
                                        })
                                      )}
                                      onChange={(e) =>
                                        machineChange(index, e.value)
                                      }
                                      showClear={
                                        item.machineId != "" &&
                                        item.machineId != null
                                          ? true
                                          : false
                                      }
                                      emptyMessage="Veri Yok"
                                      disabled={isLoading}
                                      valueTemplate={selectedItemTemplate}
                                    />
                                  </div>
                                ) : null}

                                {item.station.processDefinitions.length > 0 ? (
                                  <div className="p-inputgroup flex-1 pb-2 text-xs	">
                                    <span className="p-inputgroup-addon  	 w-4rem">
                                      İşlem
                                    </span>
                                    <Dropdown
                                      panelClassName="text-xs	"
                                      value={item.processDefinitionId || ""}
                                      options={item.station.processDefinitions.map(
                                        (pd) => ({
                                          label: pd.name,
                                          value: pd.id,
                                        })
                                      )}
                                      onChange={(e) =>
                                        processDefinitionChange(index, e.value)
                                      }
                                      showClear={
                                        item.processDefinitionId != null &&
                                        item.processDefinitionId != ""
                                          ? true
                                          : false
                                      }
                                      disabled={isLoading}
                                      valueTemplate={selectedItemTemplate}
                                    />
                                  </div>
                                ) : null}
                                <Button
                                  icon="pi pi-times"
                                  text
                                  className="absolute top-0 right-0 border-noround-left border-noround-bottom text-red-300"
                                  onClick={() => removeItem(index, item)}
                                />
                                <Button
                                  icon="pi pi-arrow-left"
                                  text
                                  className="absolute left-0 bottom-0 p-button-rounded p-button-sm"
                                  onClick={() => moveItem(index, index - 1)}
                                  disabled={index === 0}
                                />
                                <Button
                                  icon="pi pi-arrow-right"
                                  className="absolute right-0 bottom-0 p-button-rounded"
                                  text
                                  onClick={() => moveItem(index, index + 1)}
                                  disabled={
                                    index ===
                                    formData.order.workOrders[0]
                                      ?.workOrderRoutes.length -
                                      1
                                  }
                                />
                              </div>
                            </div>
                          ))}
                    </FlipMove>
                  </div>
                </ScrollPanel>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}

        <div className=" flex  w-full">
          <div className="p-d-flex p-jc-end col-6  ">
            <Button
              className="w-full "
              severity="danger"
              label={"Sil"}
              icon="pi pi-trash"
              outlined
              loading={isLoading}
              disabled={!onay}
            />
          </div>
          <div className="p-d-flex p-jc-end col-6 ">
            <Button
              className="w-full "
              severity="success"
              label={"Yazdır"}
              icon="pi pi-print"
              outlined
              loading={isLoading}
              onClick={handlePrint}
              disabled={!onay}
            />
          </div>
        </div>
      </div>
      <div className="p-col" style={{ display: " " }}>
        <OrderPrint ref={printRef} data={formData} />
      </div>
    </Dialog>
  );
};

export default PlanningAddEdit;
