import { createSlice,createAsyncThunk  } from '@reduxjs/toolkit';
export const loadUserFromStorage = createAsyncThunk(
  'user/loadUserFromStorage',
  async () => {
    // Kullanıcı bilgilerini yükleme işlemi burada
    const user = localStorage.getItem('user');
    return user ? JSON.parse(user) : null;
  }
);
export const userSlice = createSlice({
  name: "user",
  initialState: {
    user: null,
    isAuthenticated: true,
    status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
  },
  reducers: {
    loginUser: (state, action) => {
      state.isAuthenticated = true;
      state.user = action.payload;
      localStorage.setItem("user", JSON.stringify(action.payload));
    },
    logoutUser: (state) => {
      state.isAuthenticated = false;
      state.user = null;
      localStorage.removeItem("user");
    },
    
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadUserFromStorage.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(loadUserFromStorage.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.isAuthenticated = !!action.payload;
        state.user = action.payload;
      })
      .addCase(loadUserFromStorage.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
    },
});

export const { loginUser, logoutUser } = userSlice.actions;
export default userSlice.reducer;
