import { getToken } from "./apiHelper";
import api from "./api";

export const getOrderDefinitionsService = async (pageIndex, pageSize, body) => {
  try {
    let axiosConfig = {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
      params: {
        PageIndex: pageIndex,
        PageSize: pageSize,
      },
    };
    const response = await api.post(
      `/orderdefinitions/getlist`,
      body,
      axiosConfig
    );
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const addOrderDefinitionsService = async (data) => {
  try {
    const response = await api.post(`/orderdefinitions`, data);
    console.error("add");
    return response.data;
    
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};
export const updateOrderDefinitionsService = async (data) => {
  try {
    const response = await api.put(`/orderdefinitions`, data);
    console.error("update");
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};
export const deleteOrderDefinitionsService = async (id) => {
  try {
    const response = await api.delete(`/orderdefinitions/`+ id);

    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};
export const addOrderDefinitionDetailsService = async (data) => {
  try {
    const response = await api.post(`/OrderDefinitionDetails`, data);

    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};
export const deleteOrderDefinitionDetailsService = async (id) => {
  try {
    const response = await api.delete(`/OrderDefinitionDetails/`+ id);

    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const updateOrderDefinitionDetailsService = async (data) => {
  try {
    let axiosConfig = {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    };

    const response = await api.put(`/OrderDefinitionDetails`, data, axiosConfig);

    return response.data;
  } catch (error) {
    console.error("Login error:", error);
    throw error;
  }
};

export const getIdOrderDefinitionService = async (id) => {
  try {
    const response = await api.get(`/OrderDefinitions/`+id);

    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};
 
export const CreateOrderDefinitionWithDetails = async (data) => {
  try {
    const response = await api.post(`OrderDefinitions/CreateOrderDefinitionWithDetails`, 
      data
    );
   
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};