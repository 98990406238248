import React, { useState, useEffect, useMemo } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { addStockService, updateStockService } from "../../services/stocksServices";
import { useSelector } from "react-redux";
import { getCurrentsListService } from "../../services/currentsServices";
  const defaultFormData = {
    id: null,
    unitCodeId: 0,
    currentId: 0,
    typeCodeId: 0,
    sortCodeId: 0,
    qualityCodeId: 0,
    code: "",
    name: "",
    description: "",
  };
const ShipmentAddEdit = ({ visible, setVisible, dataRow, onDialogClose, toast }) => {
  const isLoading = useSelector((state) => state.loading.isLoading);

  const defaultData = useMemo(() => defaultFormData, []);


  const [formData, setFormData] = useState(defaultFormData);
  const [cariData, setCariData] = useState([]);

  useEffect(() => {
    if (visible) {
      if (dataRow) {
        setFormData(dataRow); 
      } else {
        setFormData(defaultData); 
      }
    }
  }, [dataRow, visible,defaultData]);

  const types = [
    { label: "tip 1", value: 1 },
    { label: "tip 2", value: 2 },
    { label: "tip 3", value: 3 },
  ];
  const units = [
    { label: "Birim 1", value: 1 },
    { label: "Birim 2", value: 2 },
    { label: "Birim 3", value: 3 },
  ];
  const qualitys = [
    { label: "kalite 1", value: 1 },
    { label: "kalite 2", value: 2 },
    { label: "kalite 3", value: 3 },
  ];
  const sorts = [
    { label: "tür 1", value: 1 },
    { label: "tür 2", value: 2 },
    { label: "tür 3", value: 3 },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleDropdownChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (dataRow) {
        await updateStockService(formData);
      } else {
        await addStockService(formData);
      }
      toast.current.show({
        severity: "success",
        detail: dataRow ? "Stok Başarıyla Güncellendi" : "Stok Başarıyla Eklendi",
        life: 3000,
      });
      setVisible(false);
      onDialogClose();
    } catch (error) {
      console.error("Form gönderim hatası:", error);
    }
  };

  const fetchData = async () => {
    try {
      const result = await getCurrentsListService(0, 1000); // Sayfa indeksi ve satır sayısı
      setCariData(result.items);
    } catch (error) {
      console.error("Veri alınamadı:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Dialog
      className="max-w-full w-11 md:w-11 xl:w-8"
      header={dataRow ? "Stok Güncelle" : "Stok Ekle"}
      visible={visible}
      onHide={() => setVisible(false)}
    >
      <form className="formgrid grid" onSubmit={handleSubmit}>
        <div className="col-12 md:col-4">
          <div className="p-inputgroup flex-1 w-12 pb-2">
            <span className="p-inputgroup-addon w-7rem">Stok Kodu</span>
            <InputText
              id="code"
              name="code"
              value={formData.code}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-12 md:col-8">
          <div className="p-inputgroup flex-1 pb-2">
            <span className="p-inputgroup-addon w-7rem">Stok Adı</span>
            <InputText
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-12 md:col-6">
          <div className="p-inputgroup flex-1 pb-2">
            <span className="p-inputgroup-addon w-7rem">Stok Türü</span>
            <Dropdown
              id="sortCodeId"
              name="sortCodeId"
              value={formData.sortCodeId}
              options={sorts}
              onChange={(e) => handleDropdownChange("sortCodeId", e.value)}
            />
          </div>
        </div>
        <div className="col-12 md:col-6">
          <div className="p-inputgroup flex-1 pb-2">
            <span className="p-inputgroup-addon w-7rem">Stok Tipi</span>
            <Dropdown
              id="typeCodeId"
              name="typeCodeId"
              value={formData.typeCodeId}
              options={types}
              onChange={(e) => handleDropdownChange("typeCodeId", e.value)}
            />
          </div>
        </div>
        <div className="col-12 md:col-6">
          <div className="p-inputgroup flex-1 pb-2">
            <span className="p-inputgroup-addon w-7rem">Kalite</span>
            <Dropdown
              id="qualityCodeId"
              name="qualityCodeId"
              value={formData.qualityCodeId}
              options={qualitys}
              onChange={(e) => handleDropdownChange("qualityCodeId", e.value)}
            />
          </div>
        </div>
        <div className="col-12 md:col-6">
          <div className="p-inputgroup flex-1 pb-2">
            <span className="p-inputgroup-addon w-7rem">Birim</span>
            <Dropdown
              id="unitCodeId"
              name="unitCodeId"
              value={formData.unitCodeId}
              options={units}
              onChange={(e) => handleDropdownChange("unitCodeId", e.value)}
            />
          </div>
        </div>
        <div className="col-12   ">
          <div className="p-inputgroup flex-1 pb-2">
            <span className="p-inputgroup-addon w-7rem">Cari</span>
            <Dropdown
              id="currentId"
              name="currentId"
              value={formData.currentId}
              options={cariData.map(item => ({ label: item.title, value: item.id }))}
              onChange={(e) => handleDropdownChange("currentId", e.value)}
              optionLabel="label"
              optionValue="value"
              placeholder="Cari Seç"
              filter 
            />
          </div>
        </div>
        <div className="col-12 md:col-9">
          <div className="p-inputgroup flex-1 pb-2">
            <span className="p-inputgroup-addon w-7rem">Açıklama</span>
            <InputTextarea
              className="border-round-lg border-noround-left"
              rows={2}
              autoResize
              id="description"
              name="description"
              value={formData.description}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="p-d-flex p-jc-end col-12 md:col-3">
          <Button
            className="w-full md:h-4rem"
            severity="success"
            label={dataRow ? "Stok Güncelle" : "Stok Ekle"}
            icon="pi pi-save"
            type="submit"
            outlined
            loading={isLoading}
          />
        </div>
      </form>
    </Dialog>
  );
};

export default ShipmentAddEdit;
