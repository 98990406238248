import React, { useState, useEffect, useMemo } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { useSelector } from "react-redux";
import { Divider } from "primereact/divider";
import { getCurrentsListService } from "../../services/currentsServices";
import { getStationsListService } from "../../services/businessServices";

import { getCodesService } from "../../services/codesServices";
import { addOrderDefinitionsService } from "../../services/orderDefinitionsServices";
import { getStocksListService } from "../../services/stocksServices";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
const defaultFormData = {
  id: 0,
  colorCode: "",

  colorName: "",
  description: "",
  tempreture: 0,

  minutes: 0,
  preFixation: false,
  sulphate: 0,
  stockId: "",
  stock: {
    currentId: null,
    current: { title: "" },
  },
  soda: 0,
  recipesTypeCodeId: 32,
  recipeDetails: [],
};
const RecipesAddEdit = ({
  visible,
  setVisible,
  dataRow,
  onDialogClose,
  toast,
}) => {
  const isLoading = useSelector((state) => state.loading.isLoading);

  const defaultData = useMemo(() => defaultFormData, []);
  const [onay, setOnay] = useState(false);

  const [formData, setFormData] = useState(defaultFormData);
  const [validationErrors, setValidationErrors] = useState({});
  const [cariData, setCariData] = useState([]);
  const [stokData, setStokData] = useState([]);
  const [items, setItems] = useState([]);
  const [types, setTypes] = useState([]);
  const [stations, setStations] = useState([]);

  useEffect(() => {
    if (visible) {
      if (dataRow) {
        setFormData(dataRow);
        setFormData((prevState) => ({
          ...prevState,
          recipesTypeCodeId: 33,
        }));
        stockList(dataRow.stock.currentId);
        setOnay(true);
      } else {
        setFormData(defaultData);
        setOnay(false);
      }
      setValidationErrors({});
    }
  }, [dataRow, visible, defaultData]);

  const handleOnayClick = async () => {
    const errors = validateForm(formData);
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }
    const filteredFormData = Object.fromEntries(
      Object.entries(formData).filter(
        ([key, value]) => value !== "" && value !== null
      )
    );
    const response = await addOrderDefinitionsService(filteredFormData);

    setFormData((prevState) => ({
      ...prevState,
      id: response.id,
    }));
    onDialogClose();
    setOnay(true);
  };
  const stockList = async (id) => {
    try {
      const result2 = await getStocksListService(0, 1000, {
        filter: {
          field: "currentId",
          operator: "eq",
          value: String(id),
          logic: "and",
          filters: [],
        },
      });

      setStokData(result2.items);
    } catch (error) {
      console.error("Veri alınamadı:", error);
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setValidationErrors({ ...validationErrors, [name]: null });
  };

  const handleDropdownChange = (name, value) => {

    setValidationErrors({ ...validationErrors, [name]: null });
    if (name === "currentId") {
      setFormData((prevState) => ({
        ...prevState,
        currentId: value,
      }));
      stockList(value);
    } else {
      setFormData({ ...formData, [name]: value });
    }
    if (name === "recipesTypeCodeId") {
      setFormData((prevState) => ({
        ...prevState,
        currentId: "",
        stockId: "",
      }));
    }
  };

  const validateForm = (data) => {
    let errors = {};
    if (!data.name) {
      errors.name = "Boş bırakılamaz.";
    }
    if (!data.recipesTypeCodeId) {
      errors.recipesTypeCodeId = "Tip seçiniz!";
    }

    if (formData.recipesTypeCodeId == 33) {
      if (!data.currentId) {
        errors.currentId = "Cari seçiniz.";
      }
      if (!data.stockId) {
        errors.stockId = "Stok seçiniz.";
      }
    }
    return errors;
  };

  const typesOptions = types.map((type) => ({
    label: type.description,
    value: type.id,
  }));
  const fetchData = async () => {
    try {
      const resultTypes =
        types.length <= 0 ? await getCodesService(9) : { items: types };
      setTypes(resultTypes.items);
      const resultStations = await getStationsListService();
      setStations(resultStations.items);
      const result = await getCurrentsListService(0, 1000, {}); // Sayfa indeksi ve satır sayısı
      //   const result2 = await getStocksListService(0, 1000, {}); // Sayfa indeksi ve satır sayısı
      setCariData(result.items);
      //   setStokData(result2.items);
    } catch (error) {
      console.error("Veri alınamadı:", error);
    }
  };
  useEffect(() => {
    if (visible) {
      fetchData();
    }
  }, [visible]);

  const headerTemplate = (data, options) => {
   
    return (
      <div className="flex justify-content-between align-items-center w-full">
        <div className="flex gap-1">
          <Button
            severity="success"
            label="Satır"
            icon="pi pi-plus"
            loading={isLoading}
          />
          {options.rowEditor?.editing ? (
            <>
              <Button
                icon="pi pi-save"
                text
                className="mr-2"
                onClick={(e) =>
                  options.rowEditor?.onSaveClick &&
                  options.rowEditor?.onSaveClick(e)
                }
              />
              <Button
                icon="pi pi-times"
                text
                onClick={(e) =>
                  options.rowEditor?.onCancelClick &&
                  options.rowEditor?.onCancelClick(e)
                }
                severity="warning"
              />
            </>
          ) : (
            <>
              <Button
                icon="pi pi-pencil"
                text
                className="mr-2"
                onClick={(e) =>
                  options.rowEditor?.onInitClick &&
                  options.rowEditor?.onInitClick(e)
                }
                severity="success"
              />
              <Button
                icon="pi pi-trash"
                text
                severity="danger"
              />
            </>
          )}
        </div>
  
        <div className="flex justify-content-between align-items-center w-full">
          <span className="font-bold mx-auto">{data.recipeGroup.name}</span>
          <span className="text-xl font-semibold ml-auto">
            {data.recipeGroup.description}
          </span>
        </div>
      </div>
    );
  };
  
const addGroup = ()=>{
  setFormData((prevState) => ({
    ...prevState,
    currentId: "",
    stockId: "",
  }));
}
  const actionHeaderTemplate = () => {
    return (
      <div className="flex gap-1">
        <Button
          severity="success"
          label="Yeni Grup Ekle"
          icon="pi pi-plus"
          loading={isLoading}
          onClick={addGroup}
        />
      </div>
    );
  };
  const onRowEditComplete = (e) => {
    let _formData = [...formData];
    let { newData, index } = e;

    _formData[index] = newData;

    setFormData(_formData);
  };

 
const textEditor = (options) => {
  return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
};
  const actionBodyTemplate =(rowData, options) => (
    <>
      {options.rowEditor?.editing ? (
        <>
          <Button
            icon="pi pi-save"
            text
            className="mr-2 "
            onClick={(e) =>
              options.rowEditor?.onSaveClick &&
              options.rowEditor?.onSaveClick(e)
            }
           
          />
          <Button
            icon="pi pi-times" text
            onClick={(e) =>
              options.rowEditor?.onCancelClick &&
              options.rowEditor?.onCancelClick(e)
            }
          
            severity="warning"
          />
        </>
      ) : (
        <>
          <Button
            icon="pi pi-pencil" text
         className="mr-2 "
            onClick={(e) =>
              options.rowEditor?.onInitClick &&
              options.rowEditor?.onInitClick(e)
            }
           
            severity="success"
          />
          <Button
            icon="pi pi-trash" text 
          
            severity="danger"
          
          />
        </>
      )}
    </>
  )

  return (
    <Dialog
      className="max-w-full w-11 md:w-11 xl:w-8"
      header={"Reçete Tanımı Oluştur"}
      visible={visible}
      onHide={() => setVisible(false)}
    >
      <div className="formgrid grid">
        <div className="col-12 md:col-5">
          <div className="p-inputgroup flex-1 pb-2">
            <span className="p-inputgroup-addon w-7rem ">Tip</span>
            <Dropdown
              disabled={onay}
              id="recipesTypeCodeId"
              name="recipesTypeCodeId"
              value={formData.recipesTypeCodeId}
              options={typesOptions}
              onChange={(e) =>
                handleDropdownChange("recipesTypeCodeId", e.value)
              }
              placeholder="Tip Seçiniz"
            />
            {validationErrors.recipesTypeCodeId && (
              <span className="p-inputgroup-addon w-10rem">
                <small className="p-error">
                  {validationErrors.recipesTypeCodeId}
                </small>
              </span>
            )}
          </div>
        </div>
        <div className="col-5" />
        <div className="col-12 md:col-4">
          <div className="p-inputgroup flex-1 pb-2">
            <span className="p-inputgroup-addon w-7rem ">Renk Kodu</span>
            <InputText
              disabled={onay}
              id="colorCode"
              name="colorCode"
              value={formData.colorCode}
              onChange={handleChange}
            />
            {validationErrors.colorCode && (
              <span className="p-inputgroup-addon w-10rem">
                <small className="p-error">{validationErrors.colorCode}</small>
              </span>
            )}
          </div>
        </div>
        <div className="col-12 md:col-8">
          <div className="p-inputgroup flex-1 pb-2">
            <span className="p-inputgroup-addon w-7rem ">Renk Adı</span>
            <InputText
              disabled={onay}
              id="colorName"
              name="colorName"
              value={formData.colorName}
              onChange={handleChange}
            />
            {validationErrors.colorName && (
              <span className="p-inputgroup-addon w-10rem">
                <small className="p-error">{validationErrors.colorName}</small>
              </span>
            )}
          </div>
        </div>
        {formData.recipesTypeCodeId === 33 ? (
          <div className="col-12 md:col-6">
            <div className="p-inputgroup flex-1 pb-2">
              <span className="p-inputgroup-addon w-7rem ">Cari</span>
              <Dropdown
                disabled={onay}
                id="currentId"
                name="currentId"
                value={formData.stock.currentId}
                options={cariData.map((item) => ({
                  label: item.title,
                  value: item.id,
                }))}
                onChange={(e) => handleDropdownChange("currentId", e.value)}
                placeholder="Cari Seçiniz"
                filter
                emptyMessage="Cari bulunamadı!"
                emptyFilterMessage="Sonuç bulunamadı!"
              />
              {validationErrors.currentId && (
                <span className="p-inputgroup-addon w-10rem">
                  <small className="p-error">
                    {validationErrors.currentId}
                  </small>
                </span>
              )}
            </div>
          </div>
        ) : (
          <></>
        )}
        {formData.recipesTypeCodeId === 33 ? (
          <div className="col-12 md:col-6">
            <div className="p-inputgroup flex-1 pb-2">
              <span className="p-inputgroup-addon w-7rem ">Stok</span>
              <Dropdown
                disabled={formData.currentId == 0 ? true : onay}
                id="stockId"
                name="stockId"
                value={formData.stockId}
                options={stokData.map((item) => ({
                  label: item.name,
                  value: item.id,
                }))}
                onChange={(e) => handleDropdownChange("stockId", e.value)}
                placeholder="Stok Seçiniz"
                filter
                emptyMessage="Stok bulunamadı!"
                emptyFilterMessage="Sonuç bulunamadı!"
              />
              {validationErrors.stockId && (
                <span className="p-inputgroup-addon w-10rem">
                  <small className="p-error">{validationErrors.stockId}</small>
                </span>
              )}
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className="col-12 md:col-9">
          <div className="p-inputgroup flex-1 pb-2">
            <span className="p-inputgroup-addon w-7rem ">Açıklama</span>
            <InputText
              disabled={onay}
              id="description"
              name="description"
              value={formData.description ? formData.description : ""}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-12 md:col-3">
          <Button
            className="w-full"
            severity="success"
            label={onay ? "Onaylandı" : "Onayla"}
            icon={onay ? "pi pi-check" : "pi pi-sort-down-fill"}
            outlined
            loading={isLoading}
            onClick={handleOnayClick}
            disabled={onay}
          />
        </div>
        {onay ? (
          <div className="w-full ">
            <Divider className="mt-0 mb-2 ">
              <span className="shadow-2 h-5rem w-10rem border-round-md p-1 bg-primary px-3">
                Reçete <i className="pi pi-sort-down" />
              </span>
            </Divider>

            <div className="surface-hover border-round-md mb-3 shadow-4 w-full">
              <div className="card">
                <DataTable
                rowHover
                  value={formData.recipeDetails}
                  rowGroupMode="subheader"
                  groupRowsBy="recipeGroup.name"
                  sortMode="single"
                  sortField="recipeGroup.name"
                  sortOrder={1}
                  scrollable
                  size="small"
                  showGridlines
                  scrollHeight="400px"
                  rowGroupHeaderTemplate={headerTemplate}
                  tableStyle={{ minWidth: "50rem" }}
                  dataKey="id"
                  onRowEditComplete={onRowEditComplete}
                  editMode="row"
                >
                  <Column
                    field="amount"
                    header="Miktar" editor={(options) => textEditor(options)}
                    align={"center"}
                    style={{ minWidth: "200px" }}
                  ></Column>
                  <Column
                    field="measurement"
                    header="Birim"  editor={(options) => textEditor(options)}
                    align={"center"}
                    style={{ minWidth: "200px" }}
                  ></Column>
                  <Column
                    field="stock.name"
                    header="Stok"  editor={(options) => textEditor(options)}
                    align={"center"}
                    style={{ minWidth: "200px" }}
                  ></Column>
                
                   
                    <Column
                    rowEditor
                    header={actionHeaderTemplate}
                    body={actionBodyTemplate}
                    exportable={false}
                    className="w-13rem "
                    align={"center"}
                  ></Column>
                </DataTable>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </Dialog>
  );
};

export default RecipesAddEdit;
