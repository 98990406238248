import { getToken } from "./apiHelper";
import api from "./api";

export const getOrders = async (pageIndex, pageSize, body) => {
    try {
      let axiosConfig = {
        headers: {
          Authorization: "Bearer " + getToken(),
        },
        params: {
          PageIndex: pageIndex,
          PageSize: pageSize,
        },
      };
      const response = await api.post(
        `/Orders/getlist`,
        body,
        axiosConfig
      );
      return response.data;
    } catch (error) {
      console.error("error:", error);
      throw error;
    }
  };
  
  export const addOrder = async (data) => {
    try {
      const response = await api.post(`/Orders`, data);
  
      return response.data;
      
    } catch (error) {
      console.error("error:", error);
      throw error;
    }
  };
  export const updateOrder = async (data) => {
    try {
      const response = await api.put(`/Orders`, data);
     
      return response.data;
    } catch (error) {
      console.error("error:", error);
      throw error;
    }
  };  
  export const updateOrderOrderDefinition = async (data) => {
    try {
      const response = await api.put(`Orders/UpdateOrderOrderDefinition`, 
        data
      );
     
      return response.data;
    } catch (error) {
      console.error("error:", error);
      throw error;
    }
  };
  export const deleteOrder = async (id) => {
    try {
      const response = await api.delete(`/Orders/`+ id);
  
      return response.data;
    } catch (error) {
      console.error("error:", error);
      throw error;
    }
  };