import React, { useState, useEffect, useMemo } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext"; 
import { useSelector } from "react-redux"; 
import { addMachineService, updateMachineService } from "../../services/businessServices";
const defaultFormData = {
  id: null, 
  name: "",
  capacity: 0,
  description: "",
  stationId: 0,
};

const MachineAddEdit = ({
  visible,
  setVisible,
  dataRow,
  onDialogClose,
  toast,
  stationId,
}) => {
  const isLoading = useSelector((state) => state.loading.isLoading);

  const defaultData = useMemo(() => defaultFormData, []);
   
  const [validationErrors, setValidationErrors] = useState({});
  const [formData, setFormData] = useState(defaultFormData);
  useEffect(() => {
    if (visible) {
      if (dataRow) {
        setFormData(dataRow);
      } else {
        setFormData({
            ...defaultFormData,
            stationId: stationId
          });
      }
      setValidationErrors({});
    }
  }, [dataRow, visible, defaultData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setValidationErrors({ ...validationErrors, [name]: null });
  };

  
 
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const errors = validateForm(formData);
      if (Object.keys(errors).length > 0) {
        setValidationErrors(errors);
        return;
      }
      
      if (dataRow) {
        await updateMachineService(formData);
      } else {
        await addMachineService(formData);
      }
      toast.current.show({
        severity: "success",

        detail: dataRow
          ? "Makina Başarıyla Güncellendi"
          : "Makina Başarıyla Eklendi",
        life: 3000,
      });
      setVisible(false);
      onDialogClose();
    } catch (error) {
      console.error("Form gönderim hatası:", error);
    }
  };

  
  const validateForm = (data) => {
    let errors = {};

    if (!data.name) {
      errors.name = "Boş bırakılamaz.";
    } 
   
    if (data.capacity === "" || data.capacity === null || data.capacity === undefined) {
      errors.capacity = "Boş bırakılamaz.";
    } else if (isNaN(data.capacity) || data.capacity < 0) {
      errors.capacity = "Geçersiz kapasite.";
    }
    return errors;
  };
  return (
    <Dialog
      className="  w-11 md:w-4  xl:w-4  "
      header={dataRow ? "Makina Güncelle" : "Makina Ekle"}
      visible={visible}
      onHide={() => setVisible(false)}
    >
      <form className="formgrid grid mt-3" onSubmit={handleSubmit}> 
        <div className="col-12 ">
          <div className="p-inputgroup flex-1 pb-2">
            <span className="p-inputgroup-addon   w-10rem">Makina Adı</span>
            <InputText
              disabled={isLoading}
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
            />
            {validationErrors.name && (
              <span className="p-inputgroup-addon w-10rem">
                <small className="p-error">{validationErrors.name}</small>
              </span>
            )}
          </div>
        </div> <div className="col-12 ">
          <div className="p-inputgroup flex-1 pb-2">
            <span className="p-inputgroup-addon   w-10rem">Kapasite</span>
            <InputText
              disabled={isLoading}
              id="capacity"
              name="capacity"
              value={formData.capacity}
              onChange={handleChange}
            />
            {validationErrors.capacity && (
              <span className="p-inputgroup-addon w-10rem">
                <small className="p-error">{validationErrors.capacity}</small>
              </span>
            )}
          </div>
        </div> <div className="col-12 ">
          <div className="p-inputgroup flex-1 pb-2">
            <span className="p-inputgroup-addon   w-10rem">Açıklama</span>
            <InputText
              disabled={isLoading}
              id="description"
              name="description"
              value={formData.description}
              onChange={handleChange}
            />
            {validationErrors.description && (
              <span className="p-inputgroup-addon w-10rem">
                <small className="p-error">{validationErrors.description}</small>
              </span>
            )}
          </div>
        </div> 
        <div className="  col-12    ">
          <Button
            className="w-full   "
            severity="success"
            label={dataRow ? "Güncelle" : "Ekle"}
            icon="pi pi-save"
            type="submit"
            outlined
            loading={isLoading}
          />
        </div>
      </form>
    </Dialog>
  );
};

export default MachineAddEdit;
