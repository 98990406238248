import { createSlice } from '@reduxjs/toolkit';

export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState: {
    menuOpen: false, 
    selectedMenuItem:'Gösterge Paneli', 
  },
  reducers: {
    toggleSidebar: (state) => {
      state.menuOpen = !state.menuOpen;
    }, 
    selectMenuItem: (state, action) => {
      state.selectedMenuItem = action.payload;  
    }, 
  },
});

export const { toggleSidebar,selectMenuItem  } = sidebarSlice.actions;

export default sidebarSlice.reducer;
