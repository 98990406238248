import { getToken, setToken } from "./apiHelper";
import api from "./api";
export const getAcceptGoodsService = async (pageIndex, pageSize, body) => {
  try {
 
    let axiosConfig = {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
      params: {
        PageIndex: pageIndex,
        PageSize: pageSize,
      },
    };
    const firstFilter = {
      field: "type",
      operator: "contains",
      value: "G",
      logic: "and",
    };

    const filterQuery = {
      sort: body.sort,
      filter: {
        ...firstFilter,
        filters: body.filter ? body.filter : [],
      },
    };
    const response = await api.post(`/vouchers/getlist`, body, axiosConfig);
    return response.data;
  } catch (error) {
    console.error("Login error:", error);
    throw error;
  }
};

export const addAcceptGoodsService = async (data) => {
  try {
    const response = await api.post(`/vouchers/CreateVoucherWithDetails`, data);

    return response.data;
  } catch (error) {
    console.error("Login error:", error);
    throw error;
  }
};
export const addAcceptGoodService = async (data) => {
  try {
    const response = await api.post(`/vouchers`, data);

    return response.data;
  } catch (error) {
    console.error("Login error:", error);
    throw error;
  }
};

export const deleteAcceptGoodService = async (data) => {
  try {
    let axiosConfig = {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    };

    const response = await api.delete(`/vouchers/` + data.id, axiosConfig);

    return response.data;
  } catch (error) {
    console.error("Login error:", error);
    throw error;
  }
};
export const getAcceptGoodsRowsService = async (voucherId) => {
  try {
    let axiosConfig = {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    };
    const response = await api.get(`/vouchers/${voucherId}`, axiosConfig);
   
    return response.data;
  } catch (error) {
    console.error("Login error:", error);
    throw error;
  }
};
export const addAcceptGoodsRowService = async (data) => {
  try {
    const response = await api.post(`/VoucherRows`, data);

    return response.data;
  } catch (error) {
    console.error("Login error:", error);
    throw error;
  }
};

export const updateAcceptGoodsRowService = async (data) => {
  try {
    let axiosConfig = {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    };

    const response = await api.put(`/VoucherRows`, data, axiosConfig);

    return response.data;
  } catch (error) {
    console.error("Login error:", error);
    throw error;
  }
};
export const deleteAcceptGoodsRowService = async (data) => {
  try {
    let axiosConfig = {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    };
    const response = await api.delete(`/VoucherRows/` + data.id, axiosConfig);
    return response.data;
  } catch (error) {
    console.error("Login error:", error);
    throw error;
  }
};
