 import { Dropdown } from "primereact/dropdown";
import React, { useState,useEffect  } from "react";
import { Chart } from "primereact/chart";
import StatCard from "./StatCard";
import { Card } from "primereact/card";
import { useSelector } from "react-redux"; 

const Dashboard = () => {
  const gostergeStatu = [
    { value: "Bügun", name: "Bügun" },
    { value: "Bu Hafta", name: "Bu Hafta" },
    { value: "Bu Ay", name: "Bu Ay" },
  ];
  const [selectedGostergeStat, setselectedGostergeStat] = useState(
    gostergeStatu[0].value
  );
 
  const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});

    useEffect(() => {
        const documentStyle = getComputedStyle(document.documentElement);
        const textColor = documentStyle.getPropertyValue('--text-color');
        const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
        const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
        const data = {
            labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
            datasets: [
                {
                    label: 'First Dataset',
                    data: [65, 59, 80, 81, 56, 55, 40],
                    fill: false,
                    borderColor: documentStyle.getPropertyValue('--blue-500'),
                    tension: 0.4
                },
                {
                    label: 'Second Dataset',
                    data: [28, 48, 40, 19, 86, 27, 90],
                    fill: false,
                    borderColor: documentStyle.getPropertyValue('--pink-500'),
                    tension: 0.4
                }
            ]
        };
        const options = {
            maintainAspectRatio: false,
            aspectRatio: 0.6,
            plugins: {
                legend: {
                    labels: {
                        color: textColor
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: textColorSecondary
                    },
                    grid: {
                        color: surfaceBorder
                    }
                },
                y: {
                    ticks: {
                        color: textColorSecondary
                    },
                    grid: {
                        color: surfaceBorder
                    }
                }
            }
        };

        setChartData(data);
        setChartOptions(options);
    }, []);
  return (
    <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
      <div className="flex-1   text-right  pb-2 ">
        <Dropdown
          value={selectedGostergeStat}
          onChange={(e) => setselectedGostergeStat(e.value)}
          options={gostergeStatu}
          optionLabel="name"
          style={{ textAlign: "left" }}
          className="w-full sm:w-9rem"
          checkmark={true}
          highlightOnSelect={false}
        />
      </div>
      <div className="grid">
        <div className="col-12 md:col-6 xl:col-3">
          <StatCard
            title="İş Emri"
            value="164"
            percentage="30"
            trend={1}
            icon={<i className="pi pi-user" style={{ fontSize: " 5rem" }}></i>}
          />
        </div>
        <div className="col-12 md:col-6 xl:col-3">
          <StatCard
            title="İş Emri"
            value="164"
            percentage="30"
            trend={1}
            icon={<i className="pi pi-user" style={{ fontSize: "5rem" }}></i>}
          />
        </div>
        <div className="col-12 md:col-6 xl:col-3">
          <StatCard
            title="İş Emri"
            value="164"
            percentage="30"
            trend={1}
            icon={<i className="pi pi-user" style={{ fontSize: "5rem" }}></i>}
          />
        </div>
        <div className="col-12 md:col-6 xl:col-3">
          <StatCard
            title="İş Emri"
            value="164"
            percentage="30"
            trend={1}
            icon={<i className="pi pi-user" style={{ fontSize: "5rem" }}></i>}
          />
        </div>
        <div className="col-12 md:col-9  ">
        <Card className="card h-auto">
          <div className="flex align-items-start justify-content-between mb-6">
            <span className="text-900 text-xl font-semibold">Bişiler gelecek</span>
          </div>
          
          <Chart  style={{height:"200px" , width:"100%"}} type="line" data={chartData} options={chartOptions} />
           
           
        </Card>
        </div>{" "}
        <div className="col-12 md:col-3  " >
          <StatCard style={{ height: "315px", width: "100%" }}
            title="İş Emri"
            value="164"
            percentage="30"
            trend={1}
            icon={<i className="pi pi-user" style={{ fontSize: "5rem" }}></i>}
          />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
