import React, { useRef, useState } from "react";
import { OverlayPanel } from "primereact/overlaypanel";
import MenuItem from "./MenuItem"; // MenuItem bileşenini içe aktar
import { useSelector } from "react-redux";
import { CSSTransition } from "react-transition-group";

const MenuGroup = ({ title, items, icon }) => {
  const op = useRef(null);
  const menuOpen = useSelector((state) => state.sidebar.menuOpen);

  const [isOpen, setIsOpen] = useState(false);
  const handleClick = (event) => {
    if (!menuOpen) {
      op.current.toggle(event);
    } else {
      setIsOpen(!isOpen);
    }
  };


  return (
    <div className={`${isOpen ? "surface-hover    border-0 surface-border" : ""}   `}>
      <div component="li" className="menu-item" onClick={handleClick}>
        <i
          className={icon}
          style={{ fontSize: "20px", marginRight: "10px" }}
        ></i>
       {menuOpen ?  <div className="flex w-full justify-content-between"><span
          className="menu-item-text w-full"
          style={{
          
            whiteSpace: "nowrap",
            transition: "max-width 0.3s, opacity 0.3s",
          }}
        >
          {title}
        </span>
       <i
          className={`pi pi-chevron-${isOpen ? "down" : "right"}`}
          style={{ marginLeft: "10px" }}
        ></i></div>:<></>} 
      </div>

      {menuOpen ? (
        <CSSTransition
          in={isOpen}
          timeout={300}
          classNames="menuGroup"
          unmountOnExit
        >
          <div className={`surface-hover border-0 surface-border  `}>
            {items.map((item, index) => (
              <MenuItem
                key={index}
                name={item.name}
                icon={item.icon}
                route={item.route}
              />
            ))}
          </div>
        </CSSTransition>
      ) : (
        <OverlayPanel     ref={op}>
          <div>
            {items.map((item, index) => (
              <MenuItem
                key={index}
                name={item.name}
                icon={item.icon}
                route={item.route}
                op={op}
              />
            ))}
          </div>
        </OverlayPanel>
      )}
    </div>
  );
};

export default MenuGroup;
