import React from "react";
import ReactDOM from "react-dom/client";
import App from "./components/root/App"; 
import "./index.scss";
 
import { Provider } from "react-redux";
import store from "./reducers/configureStore";
import { PrimeReactProvider } from "primereact/api";
import ConnectionAlert from "./components/common/ConnectionAlert";
import {  addLocale } from 'primereact/api';

addLocale('tr', {
    firstDayOfWeek: 1,
    closeText: 'kapat',
    prevText: 'geri',
    nextText: 'ileri',
    currentText: 'bugün',
    monthNames: [
        'Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran',
        'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'
    ],
    monthNamesShort: [
        'Oca', 'Şub', 'Mar', 'Nis', 'May', 'Haz',
        'Tem', 'Ağu', 'Eyl', 'Eki', 'Kas', 'Ara'
    ],
    dayNames: [
        'Pazar', 'Pazartesi', 'Salı', 'Çarşamba', 'Perşembe',
        'Cuma', 'Cumartesi'
    ],
    dayNamesShort: ['Pz', 'Pt', 'Sa', 'Ça', 'Pe', 'Cu', 'Ct'],
    dayNamesMin: ['Pz', 'Pt', 'Sa', 'Ça', 'Pe', 'Cu', 'Ct'],
    weekHeader: 'Hf',
    firstDay: 1,
    isRTL: false,
    showMonthAfterYear: false,
    yearSuffix: '',
    timeOnlyTitle: 'Zaman Seçiniz',
    timeText: 'Zaman',
    hourText: 'Saat',
    minuteText: 'Dakika',
    secondText: 'Saniye',
    ampm: false,
    month: 'Ay',
    week: 'Hafta',
    day: 'Gün',
    allDayText: 'Tüm Gün',
    today:"Bugün",
    clear:"Temizle",
});
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <PrimeReactProvider >
      <React.StrictMode>  <App /></React.StrictMode>
    
      <ConnectionAlert/>
    </PrimeReactProvider>
  </Provider>
);
