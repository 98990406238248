import axios from "axios";
import { setToken, API_URL, getToken } from "./apiHelper"; // Token yardımcı işlevleriniz
import { setLoading,setBakim } from "../reducers/loadingSlice";
import store from "../reducers/configureStore";
import { setToast } from "../reducers/toastSlice"; // Adjust the path as necessary
import { logoutUser } from "../reducers/userSlice";

const api = axios.create({
  baseURL: API_URL,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    "Content-Type": "application/json",
    "Accept-Language": "tr",
  },
});

const refreshToken = async () => {
  try {
    const response = await axios.get("/api/auth/refreshtoken");
    const newAccessToken = response.data.token;

    setToken(newAccessToken);
    return newAccessToken;
  } catch (error) {
    console.error("Refresh token error:", error);
    store.dispatch(logoutUser());
    throw error;
  }
};

api.interceptors.request.use(
  (config) => {
    
    store.dispatch(setLoading(true));
    const token = getToken();
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    console.log(config);
    console.log("API request to URL:", config.url);
    return config;
  },
  (error) => {
    console.log("api request error");
    store.dispatch(setLoading(false));
    return Promise.reject(error);
  }
);
api.interceptors.response.use(
  (response) => {
    console.log(response)
    setTimeout(() => {
      store.dispatch(setLoading(false));
  //    store.dispatch(
  //      setToast({
  //        severity: "success",
  //        summary: "Yükleme Başarılı",
  //        
  //        life: 3000,
  //      })
  //    );
    }, 500);
    console.log(" yüklendi?");
    return response;
  },
  async (error) => {
    store.dispatch(setLoading(false));
    console.log("api response error");
    if (error.code === "ERR_NETWORK") {
      store.dispatch(
        setToast({
          severity: "error",
          summary: error.code,
          detail: error.message,
          life: 3000,
        })
      );
    //  store.dispatch(setBakim(true))
    }else{
   //   store.dispatch(setBakim(false))
    }
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const newAccessToken = await refreshToken();
        originalRequest.headers["Authorization"] = "Bearer " + newAccessToken;
        store.dispatch(setLoading(false));
    
        return api(originalRequest);
      } catch (refreshError) {
        console.error("Token refresh failed:", refreshError);
        store.dispatch(setLoading(false));
        store.dispatch(
          setToast({
            severity: "error",
            summary: "Sunucu Hatası! " + error.response.status,
            detail: getErrorMsgByStatusCode(error),
            life: 3000,
          })
        );
        return Promise.reject(refreshError);
      }
    }

    store.dispatch(
      setToast({
        severity: "error",
        summary: "Sunucu Hatası! " + error.response.status,
        detail: getErrorMsgByStatusCode(error),
        life: 3000,
      })
    );
    store.dispatch(setLoading(false));
    return Promise.reject(error);
  }
);

const getErrorMsgByStatusCode = (error) => {
  console.log(error);
  switch (error.response.status) {
    case 400:
      return "Geçersiz istek. Lütfen girdiğiniz bilgileri kontrol edin.";
    case 401:
      return "Oturum zaman aşımına uğradı. Tekrar giriş yapınız.";
    case 403:
      return "Erişim Reddedildi. Bu işlemi gerçekleştirme yetkiniz yok.";
    case 404:
      return "Aradığınız sayfa bulunamadı. Lütfen URL'yi kontrol edin.";
    case 405:
      return "İzin verilmeyen yöntem. Lütfen farklı bir yöntem deneyin.";
    case 408:
      return "İstek zaman aşımına uğradı. Lütfen daha sonra tekrar deneyin.";
    case 429:
      return "Çok fazla istek gönderildi. Lütfen bir süre sonra tekrar deneyin.";
    case 500:
      return "Sunucu hatası. Lütfen daha sonra tekrar deneyiniz.";
    case 502:
      return "Kötü ağ geçidi. Sunucular arasında bir sorun oluştu.";
    case 503:
      return "Sunucuda bakım var! Daha sonra tekrar deneyiniz.";
    case 504:
      return "Ağ geçidi zaman aşımı. Sunucular arası bağlantı zaman aşımına uğradı.";
    default:
      return "Hata sebebi bilinmiyor. Servis sağlayıcınıza başvurun.";
  }
};
export default api;
