import React, { useRef } from "react";
import { Calendar } from "primereact/calendar";

const TitleFilterDate = (props) => {
  const { field, filtreler, setFilters, setFiltreler, sort } = props;
  const timerRef = useRef(null);

  const onFilterChange = (newValue) => {
    try {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }

      timerRef.current = setTimeout(async () => {
        let updatedFiltreler;
        if (newValue === "" || newValue === null || newValue === "NaN") {
          // Yeni değer boşsa mevcut filtreyi kaldır
          updatedFiltreler = filtreler.filter(
            (filter) => filter.field !== field
          );
        } else {
          // Yeni değer boş değilse mevcut filtreyi güncelle veya ekle
          const existingGteIndex = filtreler.findIndex(
            (filter) => filter.field === field && filter.operator === "gte"
          );
          const existingLteIndex = filtreler.findIndex(
            (filter) => filter.field === field && filter.operator === "lte"
          );

          const gteFilter = {
            field: field,
            operator: "gte",
            value: newValue.gte,
          };
          const lteFilter = {
            field: field,
            operator: "lte",
            value: newValue.lte,
          };

          updatedFiltreler = filtreler;
          if (existingGteIndex > -1) {
            updatedFiltreler[existingGteIndex] = gteFilter;
          } else {
            updatedFiltreler = [...updatedFiltreler, gteFilter];
          }

          if (existingLteIndex > -1) {
            updatedFiltreler[existingLteIndex] = lteFilter;
          } else {
            updatedFiltreler = [...updatedFiltreler, lteFilter];
          }
        }

        let filterQuery;
        if (updatedFiltreler.length === 0) {
          filterQuery = { sort }; // Hiç değer yoksa sadece sıralamayı gönder
        } else {
          const firstFilter = {
            field: updatedFiltreler[0].field,
            operator: updatedFiltreler[0].operator,
            value: updatedFiltreler[0].value,
            logic: "and",
          };

          const remainingFilters = updatedFiltreler.slice(1).map((filter) => ({
            field: filter.field,
            operator: filter.operator,
            value: filter.value,
            logic: "and",
          }));

          filterQuery = {
            sort: sort,
            filter: {
              ...firstFilter,
              filters: remainingFilters,
            },
          };
        }


        // const result = await service(0, rows, filterQuery);
        setFilters(filterQuery);
        setFiltreler(updatedFiltreler);
        // setData(result.items);
      }, 500);
    } catch (error) {
      console.error("Veri alınamadı:", error);
    }
  };

  const handleDateChange = (e) => {
    if (e.value) {
      const date = new Date(e.value);
      const startDate = new Date(date.setHours(0, 0, 0, 0)).toISOString();
      const endDate = new Date(date.setHours(23, 59, 59, 999)).toISOString();
      const newValue = { gte: startDate, lte: endDate };

      onFilterChange(newValue);
    } else {
      console.error("Geçersiz tarih değeri:", e.value);
    }
  };
    const handleClearButton = (e) => {
    
       
      const newValue = "";

      onFilterChange(newValue);
   
  };

    const dateFilterTemplate = (options) => {
        return (
        <Calendar 
            value={options.value}
            onChange={handleDateChange}
            dateFormat="dd/mm/yy"
            placeholder="Gün / Ay / Yıl"
            mask="99/99/9999"
            showButtonBar  
            onClearButtonClick={handleClearButton}
            locale="tr"
        />
        );
    };

  return <>{dateFilterTemplate({ value: null })}</>;
};

export default TitleFilterDate;
