import React, { useCallback, useEffect, useRef, useState } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { deleteMachineService, deleteProcessDefinition, deleteStationsService, getMachineService, getStationsListService } from "../../services/businessServices";
import { Button } from "primereact/button";
import StationAddEdit from "./StationAddEdit";
import { Toast } from "primereact/toast";
import { useSelector } from "react-redux";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import MachineAddEdit from "./MachineAddEdit";
import ProcessAddEdit from "./ProcessAddEdit";
import { deleteODGroup, getCodeGroups, getCodesService } from "../../services/codesServices";
import ODGroupAddEdit from "./ODGroupAddEdit";
const BusinessDefinitions = () => {
  const [machines, setMachines] = useState(null);
  const [process, setProcess] = useState(null);
  const toast = useRef(null);
  const [data, setData] = useState([]);
  const [codeGroupsData, setCodeGroupsData] = useState([]);
  
  const [visible, setVisible] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [visible3, setVisible3] = useState(false);
  const [visibleODGroup, setVisibleODGroup] = useState(false);
  const [dataRow, setRowData] = useState(null);
  const [dataRow2, setRowData2] = useState(null);   
  const [dataRow3, setRowData3] = useState(null);   
  const [dataRowODGroup, setRowDataODGroup] = useState(null);   

  const [selectedStation, setSelectedStation] = useState(null);
   const [page, setPage] = useState(0);
  const [rows, setRows] = useState(1000);  
  

  const fetchData = useCallback(async () => {
    try {
      const result = await getStationsListService(page, rows);
      setData(result.items);    
         const result2 = await getCodesService(10);
         setCodeGroupsData(result2.items); 
      console.log(result2)
    } catch (error) {
      console.error("Veri alınamadı:", error);
    }
  }, [page, rows]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);
 
 

  const onSelectStation = async (station) => {
    try {
      setSelectedStation(station);
     
        setMachines(station.machines); 
      
        setProcess(station.processDefinitions);
      
    } catch (error) {
      console.error("Veri alınamadı:", error);
    }
   
  }; 
  
  useEffect(() => {
    if (selectedStation) {
      const updatedStation = data.find(station => station.id === selectedStation.id);
      if (updatedStation) {
        setSelectedStation(updatedStation);
        setMachines(updatedStation.machines);
        setProcess(updatedStation.processDefinitions);
      }
    }
  }, [data]);
  const handleCloseDialog = () => {
    fetchData();  
  };
  const handleEditClick = (rowData,x) => {
    if(x==1){
      setVisible(true);
      setRowData(rowData);
    }else  if(x==2){
      setVisible2(true);
    setRowData2(rowData);
    }else  if(x==3){
      setVisible3(true);
    setRowData3(rowData);
    }else  if(x==4){
      setVisibleODGroup(true);
    setRowDataODGroup(rowData);
    }
    
  };
  const handleAddClick = (x) => {
    if(x==1){
      setRowData(null);
      setVisible(true);
    }else if(x==2){
      setRowData2(null);
      setVisible2(true);
    }else if(x==3){
      setRowData3(null);
      setVisible3(true);
    }else if(x==4){
      setRowDataODGroup(null);
      setVisibleODGroup(true);
    }
  
  }; 
  const handleCloseClick = () => { 
    setSelectedStation(null);
    setMachines(null); 
    setProcess(null); 
  
  }; 
  useEffect(() => {
    if (selectedStation === null) {
      handleCloseClick();
    }
  }, [selectedStation]);
  const handleDeleteClick = (rowData,x) => {
    let y;
    if(x==1){
      y ="İstasyon"
    }else if(x==2){
      y="Makine"
    }else if(x==3){
      y="İşlem"
    }
     else if(x==4){
      y="Ana İşlem"
    } 
    confirmDialog({
      message: y+" silinmek üzere! Emin misin?",
      header: y+" Siliniyor",
      icon: "pi pi-info-circle",
      defaultFocus: "reject",
      acceptClassName: "p-button-danger",
     
      acceptLabel: "Evet",
      rejectLabel: "Hayır",
      accept: () => accept(rowData,x),
      reject,
    });
  };
  
  const accept = async (rowData,x) => {
    let y;
    if(x==1){
      await deleteStationsService(rowData);
      y ="İstasyon"
    }else if(x==2){
      await deleteMachineService(rowData);
      y="Makine"
    }else if(x==3){
      y="İşlem"
      await deleteProcessDefinition(rowData);
    }else if(x==3){
      y="Ana İşlem"
     await deleteODGroup(rowData);
    }
    
    fetchData();
    toast.current.show({
      severity: "info",
      detail: y+" Başarıyla Silindi",
      life: 3000,
    });
  };
  const reject = () => {
    toast.current.show({
      severity: "warn",
      detail: "Silme işlemi iptal edildi.",
      life: 3000,
    });
  };
 
  
  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          text
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success p-mr-2"
          onClick={() => handleEditClick(rowData,1)}
        />
        <Button
          text
          icon="pi pi-trash"
          className="p-button-rounded p-button-danger"
          onClick={() => handleDeleteClick(rowData,1)}
        />
      </React.Fragment>
    );
  };
  const actionBodyMachineTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          text
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success p-mr-2"
          onClick={() => handleEditClick(rowData,2)}
        />
        <Button
          text
          icon="pi pi-trash"
          className="p-button-rounded p-button-danger"
          onClick={() => handleDeleteClick(rowData,2)}
        />
      </React.Fragment>
    );
  };
  const actionBodyProcessTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          text
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success p-mr-2"
          onClick={() => handleEditClick(rowData,3)}
        />
        <Button
          text
          icon="pi pi-trash"
          className="p-button-rounded p-button-danger"
          onClick={() => handleDeleteClick(rowData,3)}
        />
      </React.Fragment>
    );
  };
  const actionBodyODTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          text
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success p-mr-2"
          onClick={() => handleEditClick(rowData,4)}
        />
        <Button
          text
          icon="pi pi-trash"
          className="p-button-rounded p-button-danger"
          onClick={() => handleDeleteClick(rowData,4)}
        />
      </React.Fragment>
    );
  };
  const renderOrderDefinitionsGroupHeader = () => {
    return (
      <div className="flex justify-content-between">
        <div className="flex  ">
          <Button
            severity="success"
            type="button"
            icon="pi pi-plus"
            label="Ana İşlem Ekle"
            outlined
            onClick={() => handleAddClick(4)}
          />
        </div>
        
      </div>
    );
  };    
   const renderHeader = () => {
    return (
      <div className="flex justify-content-between">
        <div className="flex  ">
          <Button
            severity="success"
            type="button"
            icon="pi pi-plus"
            label="İstasyon Ekle"
            outlined
            onClick={() => handleAddClick(1)}
          />
        </div>
        
      </div>
    );
  };   
   const renderMachineHeader = () => {
    return (
      <div className="flex justify-content-between">
        <div className="flex  ">
          <Button
            severity="success"
            type="button"
            icon="pi pi-plus"
            label="Makina Ekle"
            outlined
            onClick={() => handleAddClick(2)}
          />
        </div>
        <span className="align-self-center">{ selectedStation?.name + " / Makineleri"}</span>
        <div className="flex  ">
          <Button
            severity="danger"
            type="button"
            icon="pi pi-times" 
            outlined
            onClick={() => handleCloseClick()}
          />
        </div>
      </div>
    );
  };  
   const renderProcessHeader = () => {
    return (
      <div className="flex justify-content-between">
        <div className="flex  ">
          <Button
            severity="success"
            type="button"
            icon="pi pi-plus"
            label="İşlem Ekle"
            outlined
            onClick={() => handleAddClick(3)}
          />
        </div>
        <span className="align-self-center">{ selectedStation?.name + " / İşlemleri"}</span>
        <div className="flex  ">
          <Button
            severity="danger"
            type="button"
            icon="pi pi-times" 
            outlined
            onClick={() => handleCloseClick()}
          />
        </div>
      </div>
    );
  };  
 
 
  return (
    <div className="p-grid fluid surface-card  h-full border-round-sm">
      <Toast ref={toast} />  <ConfirmDialog />
      <StationAddEdit
        setVisible={setVisible}
        visible={visible}
        dataRow={dataRow}
        onDialogClose={handleCloseDialog}
        toast={toast}
      />  
          <MachineAddEdit
        setVisible={setVisible2}
        visible={visible2}
        dataRow={dataRow2}
        stationId={selectedStation?.id}
        onDialogClose={handleCloseDialog}
        toast={toast}
      />  <ProcessAddEdit
        setVisible={setVisible3}
        visible={visible3}
        dataRow={dataRow3}
        stationId={selectedStation?.id}
        onDialogClose={handleCloseDialog}
        toast={toast}
      /> <ODGroupAddEdit
        setVisible={setVisibleODGroup}
        visible={visibleODGroup}
        dataRow={dataRowODGroup} 
        onDialogClose={handleCloseDialog}
        toast={toast}
      />
      <div className=" col-12  ">
        <TabView>
          <TabPanel header="İSTASYONLAR">
            <div className="grid">
              <div className={`col-12 md:${machines||process?"col-6":"col-12"}  `}>
                <DataTable
                  className=" w-auto  p-card"
                  size="small"
                  scrollHeight="70vh"scrollable
                  showGridlines
                  header={renderHeader()}
                  stripedRows
                  resizableColumns
                  columnResizeMode="fit"
                  rowHover
                  
                  dataKey="id"
                  emptyMessage="Veri Yok" 
                  value={data}
                  selectionMode="single"
                  selection={selectedStation}
                   onSelectionChange={(e) => onSelectStation(e.value)}
                >
                  <Column field="id" header="ID" className="w-1" align={"center"}></Column>
                  <Column field="name" header="İstasyon"></Column>
               
                     <Column   headerStyle={{ width: "4rem", textAlign: "center" }}  align={"center"}
                   header="/" body={actionBodyTemplate}/>
                </DataTable>
              </div>
              <div className="col-12 md:col-6">
              {machines && (
                  <DataTable
                  className=" w-auto  p-card "
                  size="small"
                  header={renderMachineHeader()}
                  scrollHeight="40vh"
                  scrollable
                  showGridlines 
                  stripedRows
                  resizableColumns
                  columnResizeMode="fit"
                  rowHover
                  
                  dataKey="id"
                  emptyMessage="Veri Yok" 
                  value={machines}
                  selectionMode="single" 
                >
                    <Column  field="id" header="ID" className="w-1" align={"center"}></Column>
                    <Column  field="name" header="Makina Adı"></Column>
                    <Column field="description" header="Açıklama"></Column>
                    <Column field="capacity" header="Kapasite"></Column>
                    <Column   headerStyle={{ width: "4rem", textAlign: "center" }} 
                  bodyStyle={{ textAlign: "center", overflow: "visible" }}  header="/" body={actionBodyMachineTemplate}></Column>
                  </DataTable>
                )}{process && (
                  <DataTable
                  className=" w-auto  p-card mt-3 "
                  size="small"
                  header={renderProcessHeader()}
                  scrollHeight="40vh"scrollable
                  showGridlines 
                  stripedRows
                  resizableColumns
                  columnResizeMode="fit"
                  rowHover
                  
                  dataKey="id"
                  emptyMessage="Veri Yok" 
                  value={process}
                  selectionMode="single" 
                >
                    <Column  field="id" header="ID" className="w-1" align={"center"}></Column>
                    <Column  field="name" header="Makina Adı"></Column>
                   
                    <Column   headerStyle={{ width: "4rem", textAlign: "center" }} 
                  bodyStyle={{ textAlign: "center", overflow: "visible" }}  header="/" body={actionBodyProcessTemplate}></Column>
                  </DataTable>
                )}
              </div>
            </div>
          </TabPanel>
          <TabPanel header="ANA İŞLEM KODLARI">
            <div className="grid">
              <div className={`col-12   `}>
                <DataTable
                  className=" w-auto  p-card"
                  size="small"
                  scrollHeight="70vh"scrollable
                  showGridlines
                  header={renderOrderDefinitionsGroupHeader()}
                  stripedRows
                  resizableColumns
                  columnResizeMode="fit"
                  rowHover
                  
                  dataKey="id"
                  emptyMessage="Veri Yok" 
                  value={codeGroupsData}
                  selectionMode="single"
                 
                >
                  <Column field="id" header="ID" className="w-1" align={"center"}></Column>
                  <Column field="description" header="Ana İşlem Adı"></Column>
               
                     <Column   headerStyle={{ width: "4rem", textAlign: "center" }}  align={"center"}
                   header="/" body={actionBodyODTemplate}/>
                </DataTable>
              </div>
              
            </div>
          </TabPanel>
        </TabView>
      </div>
    </div>
  );
};

export default BusinessDefinitions;
