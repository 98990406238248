import { getToken } from "./apiHelper";
import api from './api'; 
export const getStationsListService = async (page, rows) => {
  try {
    let axiosConfig = {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
      params: {
        PageIndex: page,
        PageSize: rows,
      },
    };
  
    const response = await api.post(`/stations/getlist`,{}, axiosConfig); 
 
    return response.data;
  } catch (error) {
    console.error("Login error:", error);
    throw error;
  }
};

export const addStationsService = async (data) => {
 
  try {
    let axiosConfig = {
      headers: {
        Authorization: "Bearer " + getToken(),
      }, 
    };
  
    const response = await api.post(`/stations`,data, axiosConfig); 
 
    return response.data;
  } catch (error) {
    console.error("Login error:", error);
    throw error;
  }
};
export const updateStationsService = async (data) => {
  try {
    let axiosConfig = {
      headers: {
        Authorization: "Bearer " + getToken(),
      }, 
    };
  
    const response = await api.put(`/stations`,data, axiosConfig); 
 
    return response.data;
  } catch (error) {
    console.error("Login error:", error);
    throw error;
  }
};

export const deleteStationsService = async (data) => {
  try {
    let axiosConfig = {
      headers: {
        Authorization: "Bearer " + getToken(),
      } 
    };
  
    const response = await api.delete(`/stations/`+data.id, axiosConfig); 
 
    return response.data;
  } catch (error) {
    console.error("Login error:", error);
    throw error;
  }
};

export const getMachineService = async (groupID) => {

    try {
      let axiosConfig = {
        headers: {
          Authorization: "Bearer " + getToken(),
        },
        params: {
          id: groupID, 
        },
      };
    
      const response = await api.get(`/machines`, axiosConfig); 
   
      return response.data;
    } catch (error) {
      console.error("Login error:", error);
      throw error;
    }
  };

  export const addMachineService = async (data) => {
 
    try {
      let axiosConfig = {
        headers: {
          Authorization: "Bearer " + getToken(),
        }, 
      };
    
      const response = await api.post(`/machines`,data, axiosConfig); 
   
      return response.data;
    } catch (error) {
      console.error("Login error:", error);
      throw error;
    }
  };
  export const updateMachineService = async (data) => {
    try {
      let axiosConfig = {
        headers: {
          Authorization: "Bearer " + getToken(),
        }, 
      };
    
      const response = await api.put(`/machines`,data, axiosConfig); 
   
      return response.data;
    } catch (error) {
      console.error("Login error:", error);
      throw error;
    }
  };
  
  export const deleteMachineService = async (data) => {
    try {
      let axiosConfig = {
        headers: {
          Authorization: "Bearer " + getToken(),
        } 
      };
    
      const response = await api.delete(`/machines/`+data.id, axiosConfig); 
   
      return response.data;
    } catch (error) {
      console.error("Login error:", error);
      throw error;
    }
  };
  export const addProcessDefinition = async (data) => {
 
    try {
      let axiosConfig = {
        headers: {
          Authorization: "Bearer " + getToken(),
        }, 
      };
    
      const response = await api.post(`/ProcessDefinitions`,data, axiosConfig); 
   
      return response.data;
    } catch (error) {
      console.error("Login error:", error);
      throw error;
    }
  };
  export const updateProcessDefinition = async (data) => {
    try {
      let axiosConfig = {
        headers: {
          Authorization: "Bearer " + getToken(),
        }, 
      };
    
      const response = await api.put(`/ProcessDefinitions`,data, axiosConfig); 
   
      return response.data;
    } catch (error) {
      console.error("Login error:", error);
      throw error;
    }
  };
  
  export const deleteProcessDefinition = async (data) => {
   
    try {
      let axiosConfig = {
        headers: {
          Authorization: "Bearer " + getToken(),
        } 
      };
    
      const response = await api.delete(`/ProcessDefinitions/`+data.id, axiosConfig); 
   
      return response.data;
    } catch (error) {
      console.error("Login error:", error);
      throw error;
    }
  };