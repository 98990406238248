import React, { useState, useEffect, useMemo } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext"; 
import { useSelector } from "react-redux"; 
import { addStationsService, updateStationsService } from "../../services/businessServices";
const defaultFormData = {
  id: null, 
  name: "",
};

const StationAddEdit = ({
  visible,
  setVisible,
  dataRow,
  onDialogClose,
  toast,
}) => {
  const isLoading = useSelector((state) => state.loading.isLoading);

  const defaultData = useMemo(() => defaultFormData, []);
   
  const [validationErrors, setValidationErrors] = useState({});
  const [formData, setFormData] = useState(defaultFormData);
  useEffect(() => {
    if (visible) {
      if (dataRow) {
        setFormData(dataRow);
      } else {
        setFormData(defaultData);
      }
      setValidationErrors({});
    }
  }, [dataRow, visible, defaultData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setValidationErrors({ ...validationErrors, [name]: null });
  };

  
 
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const errors = validateForm(formData);
      if (Object.keys(errors).length > 0) {
        setValidationErrors(errors);
        return;
      }

      if (dataRow) {
        await updateStationsService(formData);
      } else {
        await addStationsService(formData);
      }
      toast.current.show({
        severity: "success",

        detail: dataRow
          ? "İstasyon Başarıyla Güncellendi"
          : "İstasyon Başarıyla Eklendi",
        life: 3000,
      });
      setVisible(false);
      onDialogClose();
    } catch (error) {
      console.error("Form gönderim hatası:", error);
    }
  };

  
  const validateForm = (data) => {
    let errors = {};

    if (!data.name) {
      errors.name = "Boş bırakılamaz.";
    } 
    return errors;
  };
  return (
    <Dialog
      className="  w-11 md:w-4  xl:w-4  "
      header={dataRow ? "İstasyon Güncelle" : "İstasyon Ekle"}
      visible={visible}
      onHide={() => setVisible(false)}
    >
      <form className="formgrid grid mt-3" onSubmit={handleSubmit}> 
        <div className="col-12 ">
          <div className="p-inputgroup flex-1 pb-2">
            <span className="p-inputgroup-addon   w-10rem">İstasyon Adı</span>
            <InputText
              disabled={isLoading}
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
            />
            {validationErrors.name && (
              <span className="p-inputgroup-addon w-10rem">
                <small className="p-error">{validationErrors.name}</small>
              </span>
            )}
          </div>
        </div> 
        <div className="  col-12    ">
          <Button
            className="w-full   "
            severity="success"
            label={dataRow ? "İstasyon Güncelle" : "İstasyon Ekle"}
            icon="pi pi-save"
            type="submit"
            outlined
            loading={isLoading}
          />
        </div>
      </form>
    </Dialog>
  );
};

export default StationAddEdit;
