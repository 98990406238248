import React, { useRef, useState } from "react";
import { InputText } from "primereact/inputtext";
import { InputIcon } from "primereact/inputicon";
import { IconField } from "primereact/iconfield";

const GlobalFilter = (props) => {
  const {
    setData,
    service,
    rows,
    globalFilterFields,
  } = props;
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const timerRef = useRef(null);

  const onGlobalFilterChange = (e) => {
    const newValue = e.target.value;
    setGlobalFilterValue(newValue);

    try {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }

      timerRef.current = setTimeout(async () => {
        let filterQuery;

        if (newValue === '') {
          filterQuery = {}; // Hiç değer yoksa boş obje gönder
        } else {
          const globalFilters = globalFilterFields.map(field => ({
            field: field,
            operator: "contains",
            value: newValue,
            logic: "or",
          }));

          filterQuery = {
            filter: {
              field: globalFilterFields[0],
              operator: "contains",
              value: newValue,
              logic: "or",
              filters: globalFilters.slice(1),
            }
          };
        }


        const result = await service(0, rows, filterQuery);
        setData(result.items);
      }, 500);
    } catch (error) {
      console.error("Veri alınamadı:", error);
    }
  };

  return (
    <div>
          <IconField iconPosition="left" >
            <InputIcon className="pi pi-search" />
            <InputText className="w-12"
        value={globalFilterValue}
        onChange={onGlobalFilterChange}
        placeholder="Global Arama"
      /> 
          </IconField>
      
    </div>
  );
};

export default GlobalFilter;
