import React, { useRef } from "react";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";

const TitleFilter = (props) => {
  const {
    placeholder,
    field,
    filtreler,
    setFilters,
    setFiltreler,
    operator,
    sort,
  } = props;
  const timerRef = useRef(null);

  const onFilterChange = (newValue) => {
    try {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }

      timerRef.current = setTimeout(async () => {
        const newFilter = { field: field, value: newValue };
        const existingFilterIndex = filtreler.findIndex(
          (filter) => filter.field === field
        );

        let updatedFiltreler;
        if (newValue === "" || newValue === null || newValue === "NaN") {
          // Yeni değer boşsa mevcut filtreyi kaldır
          updatedFiltreler = filtreler.filter(
            (filter) => filter.field !== field
          );
        } else {
          // Yeni değer boş değilse mevcut filtreyi güncelle veya ekle
          updatedFiltreler =
            existingFilterIndex > -1
              ? filtreler.map((filter, index) =>
                  index === existingFilterIndex ? newFilter : filter
                )
              : [...filtreler, newFilter];
        }

        let filterQuery;
        if (updatedFiltreler.length === 0) {
          filterQuery = { sort }; // Hiç değer yoksa sadece sıralamayı gönder
        } else {
          const firstFilter = {
            field: updatedFiltreler[0].field,
            operator: operator,
            value: updatedFiltreler[0].value,
            logic: "and",
          };

          const remainingFilters = updatedFiltreler.slice(1).map((filter) => ({
            field: filter.field,
            operator: operator,
            value: filter.value,
            logic: "and",
          }));

          filterQuery = {
            sort: sort,
            filter: {
              ...firstFilter,
              filters: remainingFilters,
            },
          };
        }


        // const result = await service(0, rows, filterQuery);
        setFilters(filterQuery);
        setFiltreler(updatedFiltreler);
        // setData(result.items);
      }, 500);
    } catch (error) {
      console.error("Veri alınamadı:", error);
    }
  };

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    onFilterChange(newValue);
  };

  const handleNumberChange = (e) => {
    const floatValue = parseFloat(e.value);
    const stringValue = floatValue.toString();
    onFilterChange(stringValue);
  };

  return (
    <>
      {operator === "contains" ? (
        <InputText onChange={handleInputChange} placeholder={placeholder} />
      ) : (
        <InputNumber onChange={handleNumberChange} placeholder={placeholder} />
      )}
    </>
  );
};

export default TitleFilter;
