import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Paginator } from "primereact/paginator";
import { Skeleton } from "primereact/skeleton";

const EDataTable = ({
  data,
  columns,
  totalRecords,
  first,
  page,
  rows,
  onPageChange,
  header,
  loading,
  globalFilterFields,
  onSort,
  expandedRows,
  onRowToggle,
  rowExpansionTemplate,
  detail,
  ...props
}) => {
  const formatDate = (value) => {
    return new Date(value).toLocaleDateString("tr-US", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  };

  const getNestedValue = (obj, key) =>
    key.split(".").reduce((o, i) => (o ? o[i] : null), obj);

  const bodyTemplate = (key) => (rowData) => {
    const value = getNestedValue(rowData, key);
    return loading ? (
      <Skeleton width="100%" height="2rem" />
    ) : (
      <span>
        {key.includes("date") ? formatDate(value) : value != 0 ? value : ""}
      </span>
    );
  };
  const allowExpansion = (rowData) => {
    return rowData.order != null;
  };
 
  return (
    <div  >
      <DataTable
        className="w-auto"
        size="small"
        showGridlines
        stripedRows
        resizableColumns
        //scrollHeight="60vh"
       // scrollable
        columnResizeMode="fit"
        rowHover
        dataKey="id"
        emptyMessage="Veri Yok"
        expandedRows={expandedRows}
        onRowToggle={onRowToggle}
        rowExpansionTemplate={rowExpansionTemplate}
        value={data}
        rows={rows}
        first={first}
        paginator={false}
        filterDisplay="row"
        loading={loading}
        onSort={onSort}
        globalFilterFields={globalFilterFields}
        header={header}
        {...props}
      >
       
         {detail ? <Column expander={allowExpansion} style={{ width: "1rem" }} />:null} 
       
        {columns.map((col, index) => (
          <Column
            headerStyle={{ height: "50px" }}
            key={index}
            {...col}
            body={col.body || bodyTemplate(col.field)}
          />
        ))}
      </DataTable>
      <Paginator
        first={first}
        rows={rows}
        disabled={loading}
        totalRecords={totalRecords}
        rowsPerPageOptions={[5, 10, 50]}
        onPageChange={onPageChange}
      />
    </div>
  );
};

export default EDataTable;
