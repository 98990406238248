import { createSlice } from "@reduxjs/toolkit";

const themeFromStorage = localStorage.getItem("theme")
  ? JSON.parse(localStorage.getItem("theme"))
  : {
      darkMode: false,
      themeName: "lara",
      themeColor: "blue",
    };

const initialState = themeFromStorage;

const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    toggleTheme: (state) => {
      state.darkMode = !state.darkMode;
    },
    toggleThemeName: (state, action) => {
      state.themeName = action.payload;
    },
    toggleThemeColor: (state, action) => {
      state.themeColor = action.payload;
    },
  },
});

export const { toggleTheme, toggleThemeName, toggleThemeColor } = themeSlice.actions;

export default themeSlice.reducer;
