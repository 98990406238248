import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectMenuItem } from "../../reducers/sidebarSlice";
import { useNavigate } from "react-router-dom";
import { toggleSidebar } from "../../reducers/sidebarSlice";
import useSize from "../common/useSize";

const MenuItem = ({ name, icon, route, op }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedMenuItem = useSelector(
    (state) => state.sidebar.selectedMenuItem
  );
  const menuOpen = useSelector((state) => state.sidebar.menuOpen);
  const windowsize = useSize();
  const handleClick = () => {
    dispatch(selectMenuItem(name));
    navigate(route);
    if (windowsize[0] <= 1000) {
      dispatch(toggleSidebar());
    } else {
    }
  };
  const isSelected = selectedMenuItem === name;

  return (
    <div
      component="li"
      className={`menu-item ${isSelected ? "selected" : ""} `}
      onClick={handleClick}
    >
      <i className={icon} style={{ fontSize: "20px", marginRight: "10px" }}/>
      <span
        className="menu-item-text"
        style={{
          whiteSpace: "nowrap", 
        }}
      >
        {menuOpen ? name : op ? name : ""}
      </span>
    </div>
  );
};

export default MenuItem;
