// connectionSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isConnected: navigator.onLine,
};

const connectionSlice = createSlice({
  name: 'connection',
  initialState,
  reducers: {
    setConnectionStatus: (state, action) => {
      state.isConnected = action.payload;
    },
  },
});

export const { setConnectionStatus } = connectionSlice.actions;
export default connectionSlice.reducer;
