import axios from "axios";
import { API_URL, getToken } from "./apiHelper";

export const loginService = async (email, password) => {
  try {
    const response = await axios.post(`${API_URL}/auth/login`, {
      email,
      password,
    });

    return response.data;
  } catch (error) {
    console.error("Login error:", error);
    throw error;
  }
};
export const logoutService = async () => {
  try {
    const token = getToken();
    let axiosConfig = {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    };

    const response = await axios.put(
      `${API_URL}/auth/revoketoken`,
      token,
      axiosConfig
    );

    return response.data;
  } catch (error) {
    console.error("Login error:", error);
    throw error;
  }
};
