import { getToken } from "./apiHelper";
import api from './api'; 
 
    /* groupidler
    1 Stok Tipi // envanter - hizmet malı
    2 Cari Tipi 
    3 Cari Türü
    4 Stok Türü  //kumaş, iplik,boya,kimyasal,sarf malzeme,ambalaj
    5 Stok Kalite //Poly,Pamuk,Karışım, Katyonik,Keten,Viskos vb.
    6 Stok Birim  //kilo , metre, hacim vb.
    */
   
export const getCodesService = async (groupID) => {
 
    try {
      let axiosConfig = {
        headers: {
          Authorization: "Bearer " + getToken(),
        },
        params: {
          GroupId: groupID, 
        },
      };
    
      const response = await api.get(`/Codes/GetCodesByGroupId`, axiosConfig); 
   
      return response.data;
    } catch (error) {
    
      throw error;
    }
  };
  export const addODGroup = async (data) => {
 
    try {
      let axiosConfig = {
        headers: {
          Authorization: "Bearer " + getToken(),
        }, 
      };
    
      const response = await api.post(`/Codes`,data, axiosConfig); 
   
      return response.data;
    } catch (error) {
 
      throw error;
    }
  };
  export const updateODGroup = async (data) => {
    try {
      let axiosConfig = {
        headers: {
          Authorization: "Bearer " + getToken(),
        }, 
      };
    
      const response = await api.put(`/Codes`,data, axiosConfig); 
   
      return response.data;
    } catch (error) {
     
      throw error;
    }
  };
  
  export const deleteODGroup = async (data) => {
   
    try {
      let axiosConfig = {
        headers: {
          Authorization: "Bearer " + getToken(),
        } 
      };
    
      const response = await api.delete(`/Codes/`+data.id, axiosConfig); 
   
      return response.data;
    } catch (error) {
       
      throw error;
    }
  };