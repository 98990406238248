import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { FloatLabel } from "primereact/floatlabel";
import { Password } from "primereact/password";
import { Checkbox } from "primereact/checkbox";
import { ProgressSpinner } from "primereact/progressspinner";

import { loginUser } from "../../reducers/userSlice";
import { loginService } from "../../services/authServices";
import { setToast } from "../../reducers/toastSlice";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);

  const passwordRef = useRef(null);

  const [loading, setLoading] = useState(false);
  
  useEffect(() => {
    if (isAuthenticated) {
      navigate("/y/dashboard");
    }
  }, [isAuthenticated, navigate]);
  useEffect(() => {
    const rememberedEmail = localStorage.getItem("rememberedEmail");
    if (rememberedEmail) {
      setEmail(rememberedEmail);
      setRememberMe(true);
      if (passwordRef.current) {
        passwordRef.current.focus();
      }
    }
  }, []);
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const response = await loginService(email, password);

      const token = response.accessToken.token;

      localStorage.setItem("accessToken", token);
      dispatch(loginUser(response));

      if (rememberMe) {
        localStorage.setItem("rememberedEmail", email);
      } else {
        localStorage.removeItem("rememberedEmail");
      }
      dispatch(
        setToast({
          severity: "success",
          summary: "Giriş Başarılı!",
          detail: "Giriş yaptınız. Tadını çıkarın.",
          life: 3000,
        })
      );

      navigate("/y/dashboard");
    } catch (error) {
      dispatch(
        setToast({
          severity: "warn",
          summary: "Giriş Başarısız!",
          detail: "Bilgilerinizi kontrol edip tekrar deneyiniz.",
          life: 3000,
        })
      );
    }

    setLoading(false);
  };
  const toastRef = useRef(null);
  const toast = useSelector((state) => state.toast.toast);
  useEffect(() => {
    if (toast) {
      toastRef.current.show(toast);
      dispatch(setToast(null));
    }
  }, [toast, dispatch]);
  return (
    <div
      className="flex justify-content-center align-items-center h-screen w-screen"
      style={{
        /*backgroundImage: `url(${process.env.PUBLIC_URL + "/bg.jpg"})`,*/
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >  <Toast ref={toastRef} />;
      {loading && (
        <div className="flex fixed  border-primary-100 border-3 border-round-md z-1 h-6rem w-6rem bg-white justify-content-center align-items-center    ">
          <ProgressSpinner
            style={{ width: "50px", height: "50px" }}
            strokeWidth="8"
            fill="var(--surface-ground)"
            animationDuration=".5s"
          />
        </div>
      )}
    
      <div className="grid surface-ground text-color m-4 border-round-xl">
        <div className="  col-12 md:col-6   border-round-xl align-items-center justify-content-center     font-bold  px-5 py-3   ">
          <form onSubmit={handleSubmit}>
            <h1>Giriş Yap</h1>
            <div className="    pt-2 pb-3">
              <FloatLabel disabled={loading}>
                <InputText
                  id="userid"
                  className="w-full"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <label htmlFor="username">Kullanıcı Adı</label>
              </FloatLabel>
            </div>
            <div className="  pt-3 pb-5 ">
              <FloatLabel disabled={loading}>
                <Password
                  id="password"
                  ref={passwordRef}
                  inputStyle={{ width: "100%" }}
                  style={{ width: "100%" }}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  feedback={false}
                  toggleMask
                  inputClassName="w-full"
                  className="  w-full"
                  pt={{ iconField: { root: { className: "w-full" } } }}
                />
                <label htmlFor="password">Şifre</label>
              </FloatLabel>
            </div>
            <div className="flex justify-content-end align-items-center w-full ">
              <label className="mr-2">Beni Hatırla</label>
              <Checkbox
                disabled={loading}
                onChange={(e) => setRememberMe(e.checked)}
                checked={rememberMe}
              />
            </div>
            <div className="  pt-5 pb-5 ">
              <Button
                className="w-full"
                label="Giriş Yap"
                icon="pi pi-angle-right"
                iconPos="right"
                onClick={handleSubmit}
                disabled={loading}
              ></Button>
            </div>
          </form>
          <div className="flex justify-content-end align-items-center w-full ">
            <span className="text-sm">Şifreni mi unuttun?</span>{" "}
            <Button
              disabled={loading}
              outlined
              severity="secondary"
              className="text-sm ml-3"
              label="Şifreni yenile"
            />
          </div>
        </div>
        <div className="col-12 md:col-6 bg-primary border-round-xl px-5 py-3 flex align-items-center justify-content-center">
          <div className="text-center">
            <h1>Hoş Geldiniz!</h1>
            <div>
              Bu alanda logonuz ve kısa bir bilgilendirme metni bulunabilir.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
