import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setConnectionStatus } from "../../reducers/connectionSlice";
import { Button } from "primereact/button";
import { Message } from "primereact/message";

const ConnectionAlert = () => {
  const dispatch = useDispatch();
  const isConnected = useSelector((state) => state.connection.isConnected);

  useEffect(() => {
    const handleOnline = () => {
      dispatch(setConnectionStatus(true));
    };

    const handleOffline = () => {
      dispatch(setConnectionStatus(false));
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    // Cleanup function
    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, [dispatch]);

  const refreshPage = () => {
    window.location.reload();
  };

  return (
    !isConnected && (
    <div className="fixed  w-full   align-items-center justify-content-center     bottom-0 text-center    ">
      <div class="flex justify-content-center flex-wrap ">
        <div
          className="  w-11 border-round-xl m-4 p-3"
          style={{ backgroundColor: "rgba(251, 65, 48, 0.6)" }}
        >
          <span className="text-white h-3rem  text-xl">
            İnternet bağlantınız yok. Lütfen bağlantınızı kontrol edip tekrar
            deneyin. <Button
        icon="pi pi-refresh"  text
        className="  text-white" size="medium"   
        onClick={refreshPage}
      />  
          </span>
        </div>
      </div> 
    </div>
       )
  );
};

export default ConnectionAlert;
