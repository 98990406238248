import React, { useEffect, useState, useRef, useCallback } from "react";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { ConfirmDialog } from "primereact/confirmdialog";
import { useSelector } from "react-redux";
import EDataTable from "../toolbox/EDataTable";
import RecipesAddEdit from "./RecipesAddEdit"
import GlobalFilter from "../toolbox/GlobalFilter";
import TitleFilter from "../toolbox/TitleFilter";
import { Skeleton } from "primereact/skeleton";
import { getRecipes } from "../../services/recipesServices";
const Recipes = () => {
  const [first, setFirst] = useState(0);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState(10);
  const [data, setData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [dataRow, setRowData] = useState(null);
  const sort = [
    {
      field: "id",
      dir: "desc",
    },
  ];
  const [filters, setFilters] = useState({ sort });
  const [visible, setVisible] = useState(false);
  const [filtreler, setFiltreler] = useState([]);
  const isLoading = useSelector((state) => state.loading.isLoading);
  const renderHeader = () => {
    return (
      <div>
        <div className="flex justify-content-between">
          <div className="flex  ">
            <Button
              severity="success"
              type="button"
              icon="pi pi-plus"
              label="Yeni"
              outlined
              onClick={() => handleAddClick()}
            />
          </div>

          <div className="flex  gap-1">
            <div className="  hidden sm:block w-12  ">
              <GlobalFilter
                service={getRecipes}
                setData={setData}
                rows={rows}
                globalFilterFields={[
                  "colorCode",
                  "colorName",
                  "description",
                  "stock.current.title",
                  "stock.name",
                ]}
              />
            </div>
            <div>
              <Button
                type="button"
                icon="pi pi-refresh"
                outlined
                onClick={() => fetchData()}
              />
            </div>
          </div>
        </div>
        <div className=" block  pt-2 sm:hidden w-12 ">
          <GlobalFilter
            service={getRecipes}
            setData={setData}
            rows={rows}
            globalFilterFields={[
              "colorCode",
              "colorName",
              "description",
              "stock.current.title",
              "stock.name",
            ]}
          />
        </div>
      </div>
    );
  };
  const fetchData = useCallback(async () => {
    try {
      const result = await getRecipes(page, rows, filters);
      setData(result.items);
      setTotalRecords(result.count);
    } catch (error) {
      console.error("Veri alınamadı:", error);
    }
  }, [page, rows, filters]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleCloseDialog = () => {
    fetchData();
  };
  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          text
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success p-mr-2"
          onClick={() => handleEditClick(rowData)}
        />
      </React.Fragment>
    );
  };

  const handleEditClick = (rowData) => {
    setVisible(true);
    setRowData(rowData);
  };
  const handleAddClick = () => {
    setRowData(null);
    setVisible(true);
  };
  const header = renderHeader();
  const toast = useRef(null);

  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
    setPage(event.page)
  };
  const bodyTemplate = (key) => (rowData) => {
    const getNestedValue = (obj, key) => key.split('.').reduce((o, i) => (o ? o[i] : null), obj);

  return isLoading ? (
    <Skeleton width="100%" height="2rem" />
  ) : (
    <span>{getNestedValue(rowData, key)}</span>
  );
  };
  const columns = [
    {
      field: "colorCode",
      header: "Renk Kodu",
      filter: true,
      showClearButton: false,
      showFilterMenu: false,
      style: { minWidth: "12rem" }, 
      filterElement: (options) => (
        <TitleFilter
          {...options}
          placeholder="Kod Ara"
          field={"colorCode"}  
           operator ="contains"  
          filtreler={filtreler}
          setFiltreler={setFiltreler} 
          setFilters={setFilters}
          sort={sort}
        />
      ),
    }, {
      field: "colorName",
      header: "Renk Adı",
      filter: true,
      showClearButton: false,
      showFilterMenu: false,
      style: { minWidth: "12rem" }, 
      filterElement: (options) => (
        <TitleFilter
          {...options}
          placeholder="Renk Ara"
          field={"colorName"}  
           operator ="contains"  
          filtreler={filtreler}
          setFiltreler={setFiltreler} 
          setFilters={setFilters}
          sort={sort}
        />
      ),
    },
    {
      field: "description",
      header: "Açıklama",
      style: { minWidth: "12rem" },
      filter: true,
      showClearButton: false,
      showFilterMenu: false, 
      filterElement: (options) => (
        <TitleFilter
          {...options}
          placeholder="Açıklama Ara"
          field={"description"}  
           operator ="contains"  
          filtreler={filtreler}
          setFiltreler={setFiltreler} 
          setFilters={setFilters}
          sort={sort}
        />
      ),
    },
    {
      field: "stock.name",
      header: "Stok",
      showClearButton: false,
      style: { minWidth: "12rem" },
      filter: true,
      showFilterMenu: false,
      body:bodyTemplate("stock.name"),
      filterElement: (options) => (
        <TitleFilter
          {...options}
          placeholder="Stok Ara"
          field={"stock.name"}  
           operator ="contains"  
          filtreler={filtreler}
          setFiltreler={setFiltreler} 
          setFilters={setFilters}
          sort={sort}
        />
      ),
    },
    {
      field: "stock.current.title",
      header: "Cari",
      showClearButton: false,
      style: { minWidth: "12rem" },
      filter: true,
      showFilterMenu: false,
      body:bodyTemplate("stock.current.title"),
      filterElement: (options) => (
        <TitleFilter
          {...options}
          placeholder="Cari Ara"
          field={"stock.current.title"}  
           operator ="contains"  
          filtreler={filtreler}
          setFiltreler={setFiltreler} 
          setFilters={setFilters}
          sort={sort}
        />
      ),
    },
      

    {
      header: "/",
      alignHeader: "center",
      align: "center",
      headerStyle: { width: "4rem", textAlign: "center" },
      bodyStyle: { textAlign: "center", overflow: "visible" },
      body: actionBodyTemplate,
    },
  ];

  return (
    <div className="surface-card p-2 border-round-sm ">
      <Toast ref={toast} />
      <ConfirmDialog />
      <RecipesAddEdit
        setVisible={setVisible}
        visible={visible}
        dataRow={dataRow}
        onDialogClose={handleCloseDialog}
        toast={toast}
      />
      <EDataTable
        data={data}
        columns={columns}
        totalRecords={totalRecords}
        first={first}
        rows={rows}
        onPageChange={onPageChange}
        header={header}
        loading={isLoading}
      />
    </div>
  );
};

export default Recipes;
