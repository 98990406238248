import { configureStore } from '@reduxjs/toolkit';
import sidebarReducer from './sidebarSlice.js';
import themeReducer from './themeSlice.js';
import userReducer from './userSlice.js'; 
import edatagridReducer from './edatagridSlice.js'; 
import loadingReducer from './loadingSlice.js'; 
import toastReducer from './toastSlice.js';
import connectionReducer from './connectionSlice.js';
const store = configureStore({
  reducer: { 
    sidebar: sidebarReducer,
    theme: themeReducer,
    user: userReducer, 
    egrid: edatagridReducer,
    loading: loadingReducer,
    toast:toastReducer,
    connection:connectionReducer
  },
});

export default store;
