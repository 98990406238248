import React from 'react'; 

const Footer = () => {
  return (
    <div className='flex shadow-6 surface-card border-round ml-2 mr-2	border-noround-bottom  align-items-center justify-content-end pr-2 h-2rem' style={{
       
      
    }}>
         Design by Etkili Yazılım
    </div>
  );
};

export default Footer;
