import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import MenuItem from "../toolbox/MenuItem"; 
import MenuGroup from "../toolbox/MenuGroup";

const MenuPanel = () => {
  const menuOpen = useSelector((state) => state.sidebar.menuOpen);
 
 
  
  const [text, setText] = useState("B");
  const [fade, setFade] = useState(false);

  useEffect(() => {
    if (menuOpen) {
      setFade(false);
      setTimeout(() => {
        setText("BERP");
        setFade(true);
      }, 300);
    } else {
      setFade(false);
      setTimeout(() => {
        setText("B");
        setFade(true);
      }, 300);
    }
  }, [menuOpen]);


  const menuItems = [
    { name: "Gösterge Paneli", icon: "pi pi-table", route: "/y/dashboard" },
    {
      group: "Tanımlamalar",
      icon: "pi pi-pen-to-square",
      items: [ 
        { name: "Stok Yönetimi", icon: "pi pi-inbox", route: "/y/stocks" },
    { name: "Cari Yönetimi", icon: "pi pi-briefcase", route: "/y/currents" },
    { name: "İşletme Tanımları", icon: "pi pi-briefcase", route: "/y/businessdefinitions" },
      ],
    },
    { name: "Mal Kabul", icon: "pi pi-box", route: "/y/acceptgoods" },
    { name: "Sevkiyat", icon: "pi pi-truck", route: "/y/shipment" },
   
    {
      group: "Planlama",
      icon: "pi pi-chart-pie",
      items: [ 
        { name: "İş Emri Listesi", icon: "pi pi-sitemap", route: "/y/planning" }, 
        { name: "Reçete Sistemi", icon: "pi pi-receipt", route: "/y/recipes" }, 
        { name: "Sipariş Tanımları", icon: "pi pi-folder", route: "/y/orderdefinitions" }, 
      ],
    }, {
      group: "Finans",
      icon: "pi pi-calculator",
      items: [ 
        { name: "Tahsilat", icon: "pi pi-dollar", route: "/y/collection" }, 
        { name: "Tediye", icon: "pi pi-wallet", route: "/y/payment" }, 
        { name: "Cari Ekstre", icon: "pi pi-list-check", route: "/y/extract" }, 
      ],
    }, 
    { name: "Laboratuvar", icon: "pi pi-tags", route: "/y/lab" },
    { name: "Boya Mutfağı", icon: "pi pi-palette", route: "/y/paintkitchen" },
    { name: "SatınAlma", icon: "pi pi-shopping-bag", route: "/y/buyying" },
    { name: "Kullanıcı Yönetimi", icon: "pi pi-users", route: "/y/users" },
  ];
  return (
    <div>
      <div className="flex justify-content-center align-items-center w-full h-5rem "
     
      >
        <div className="transition-container">
        <h1 className={`transition-text ${fade ? 'transition-text-enter' : ''}`}>
          {text}
        </h1>
      </div>
      </div>

      {menuItems.map((item, index) => (
        item.group ? (
          <MenuGroup key={index} title={item.group} items={item.items}  icon={item.icon} />
        ) : (
          <MenuItem key={index} name={item.name} icon={item.icon} route={item.route} />
        )
      ))}
    </div>
  );
};
export default MenuPanel;
