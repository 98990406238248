import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import {
  addCurrentService,
  updateCurrentService,
} from "../../services/currentsServices";
import { useSelector } from "react-redux";
import { getCodesService } from "../../services/codesServices";
const defaultFormData = {
  id: null,
  code: "",
  sortCodeId: 0,
  typeCodeId: 0,
  title: "",
  address: "",
  phone: "",
  email: "",
};

const CurrentAddEdit = ({
  visible,
  setVisible,
  dataRow,
  onDialogClose,
  toast,
}) => {
  const isLoading = useSelector((state) => state.loading.isLoading); 
  const defaultData = useMemo(() => defaultFormData, []);
  const [types, setTypes] = useState([]);
  const [sorts, setSorts] = useState([]); 
  const [validationErrors, setValidationErrors] = useState({});
  const [formData, setFormData] = useState(defaultFormData);
  useEffect(() => {
    if (visible) {
      if (dataRow) {
        setFormData(dataRow);
      } else {
        setFormData(defaultData);
      }
      setValidationErrors({});
    }
  }, [dataRow, visible, defaultData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setValidationErrors({ ...validationErrors, [name]: null });
  };

  const handleDropdownChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
    setValidationErrors({ ...validationErrors, [name]: null });
  };

  
  const fetchData = useCallback(async () => {
    try { if (visible) {
      const resultTypes = types.length <= 0 ? await getCodesService(2) : { items: types };
      const resultSorts = sorts.length <= 0 ? await getCodesService(3) : { items: sorts };
      setTypes(resultTypes.items);
      setSorts(resultSorts.items);
    }
     
    } catch (error) {
      console.error("Veri alınamadı:", error);
    }
  }, [visible,types,sorts]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  
 
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const errors = validateForm(formData);
      if (Object.keys(errors).length > 0) {
        setValidationErrors(errors);
        return;
      }

      if (dataRow) {
        await updateCurrentService(formData);
      } else {
        await addCurrentService(formData);
      }
      toast.current.show({
        severity: "success",

        detail: dataRow
          ? "Cari Başarıyla Güncellendi"
          : "Cari Başarıyla Eklendi",
        life: 3000,
      }); 
      setVisible(false);
      onDialogClose();
    } catch (error) {
      console.error("Form gönderim hatası:", error);
    }
  };

  const typesOptions = types.map((type) => ({
    label: type.description,
    value: type.id,
  }));

  const sortsOptions = sorts.map((sort) => ({
    label: sort.description,
    value: sort.id,
  }));

  const validateForm = (data) => {
    let errors = {};

    if (!data.title) {
      errors.title = "Ünvan gereklidir.";
    }

    if (data.typeCodeId === 0) {
      errors.typeCodeId = "Tip seçilmelidir.";
    }

    if (data.sortCodeId === 0) {
      errors.sortCodeId = "Tür seçilmelidir.";
    }
    return errors;
  };
  return (
    <Dialog
      className="max-w-full w-11 md:w-11  xl:w-8  "
      header={dataRow ? "Cari Güncelle" : "Cari Ekle"}
      visible={visible}
      onHide={() => setVisible(false)}
    >
      <form className="formgrid grid" onSubmit={handleSubmit}>
        <div className="col-12 md:col-4 ">
          <div className="p-inputgroup flex-1 w-12  pb-2  ">
            <span className="p-inputgroup-addon w-7rem">Cari Kodu</span>
            <InputText
              disabled
              id="code"
              name="code"
              value={formData.code}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-12 md:col-8 ">
          <div className="p-inputgroup flex-1 pb-2">
            <span className="p-inputgroup-addon   w-7rem">Cari Ünvan</span>
            <InputText
              disabled={isLoading}
              id="title"
              name="title"
              value={formData.title}
              onChange={handleChange}
            />
            {validationErrors.title && (
              <span className="p-inputgroup-addon w-10rem">
                <small className="p-error">{validationErrors.title}</small>
              </span>
            )}
          </div>
        </div>
        <div className="col-12 md:col-6 ">
          <div className="p-inputgroup flex-1 pb-2">
            <span className="p-inputgroup-addon   w-7rem">Cari Türü</span>
            <Dropdown
              disabled={isLoading}
              id="sortCodeId"
              name="sortCodeId"
              value={formData.sortCodeId}
              options={sortsOptions}
              onChange={(e) => handleDropdownChange("sortCodeId", e.value)}
            />
            {validationErrors.sortCodeId && (
              <span className="p-inputgroup-addon w-10rem">
                <small className="p-error">{validationErrors.sortCodeId}</small>
              </span>
            )}
          </div>
        </div>
        <div className="col-12 md:col-6 ">
          <div className="p-inputgroup flex-1 pb-2">
            <span className="p-inputgroup-addon w-7rem">Cari Tipi</span>
            <Dropdown
              disabled={isLoading}
              id="typeCodeId"
              name="typeCodeId"
              value={formData.typeCodeId}
              options={typesOptions}
              onChange={(e) => handleDropdownChange("typeCodeId", e.value)}
            />
            {validationErrors.typeCodeId && (
              <span className="p-inputgroup-addon w-10rem">
                <small className="p-error">{validationErrors.typeCodeId}</small>
              </span>
            )}
          </div>
        </div>
        <div className="col-12 md:col-6 ">
          <div className="p-inputgroup flex-1 pb-2 ">
            <span className="p-inputgroup-addon  w-7rem">Telefon</span>
            <InputText
              disabled={isLoading}
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-12 md:col-6 ">
          <div className="p-inputgroup flex-1 pb-2">
            <span className="p-inputgroup-addon w-7rem">Email</span>
            <InputText
              disabled={isLoading}
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-12 md:col-9 ">
          <div className="p-inputgroup flex-1 pb-2 ">
            <span className="p-inputgroup-addon w-7rem ">Adres</span>

            <InputTextarea
              disabled={isLoading}
              className="border-round-lg border-noround-left"
              rows={2}
              autoResize
              id="address"
              name="address"
              value={formData.address}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="p-d-flex p-jc-end col-12 md:col-3  ">
          <Button
            className="w-full md:h-4rem "
            severity="success"
            label={dataRow ? "Cari Güncelle" : "Cari Ekle"}
            icon="pi pi-save"
            type="submit"
            outlined
            loading={isLoading}
          />
        </div>
      </form>
    </Dialog>
  );
};

export default CurrentAddEdit;
