import React, { useEffect, useState, useRef, useCallback } from "react";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";

import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import PlanningAddEdit from "./PlanningAddEdit";
import { getPlanningList } from "../../services/planningServices";
import { useSelector } from "react-redux";
import EDataTable from "../toolbox/EDataTable";
import TitleFilterDate from "../toolbox/TitleFilterDate";
import TitleFilter from "../toolbox/TitleFilter";
import GlobalFilter from "../toolbox/GlobalFilter";
import { Skeleton } from "primereact/skeleton";
import { Tag } from "primereact/tag";
import TitleFilterStatus from "../toolbox/TitleFilterStatus";
import { getSeverityStatuses } from "../toolbox/planningStatuses";
import { Badge } from "primereact/badge";
import { InputText } from "primereact/inputtext";
const Planning = () => {
  const [filtreler, setFiltreler] = useState([]);
  const [first, setFirst] = useState(0);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState(10);
  const [data, setData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const sort = [
    {
      field: "id",
      dir: "desc",
    },
  ];
  const [filters, setFilters] = useState({ sort });
  const [visible, setVisible] = useState(false);
  const isLoading = useSelector((state) => state.loading.isLoading);

  const [dataRow, setRowData] = useState(null);

  const renderHeader = () => {
    return (
      <div>
        <div className="flex justify-content-between">
          <div className="flex  ">
            {/* <Button
              severity="success"
              type="button"
              icon="pi pi-plus"
              label="Yeni"
              outlined
              onClick={() => handleAddClick()}
            />*/}
          </div>

          <div className="flex  gap-1">
            <div className="  hidden sm:block w-12  ">
              <GlobalFilter
                service={getPlanningList}
                setData={setData}
                rows={rows}
                globalFilterFields={["current.title", "serial"]}
              />
            </div>
            <div>
              <Button
                type="button"
                icon="pi pi-refresh"
                outlined
                onClick={() => fetchData()}
              />
            </div>
          </div>
        </div>
        <div className=" block  pt-2 sm:hidden w-12 ">
          <GlobalFilter
            service={getPlanningList}
            setData={setData}
            rows={rows}
            globalFilterFields={["current.title", "serial"]}
          />
        </div>
      </div>
    );
  };

  const fetchData = useCallback(async () => {
    try {
      const result = await getPlanningList(page, rows, filters);
      console.log(result);
      setData(result.items);
      setTotalRecords(result.count); 
    } catch (error) {
      console.error("Veri alınamadı:", error);
    }
  }, [page, rows, filters]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);
  const onPageChange = (event) => {
    setFirst(event.first);
    setPage(event.page);
    setRows(event.rows);
  };
  const toast = useRef(null);

  const handleCloseDialog = () => {
     fetchData();
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          text
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success "
          onClick={() => handleEditClick(rowData)}
        />
      </React.Fragment>
    );
  };

  const handleEditClick = (rowData) => {
    setVisible(true);
    setRowData(rowData);
  };

  const partiBody = (rowData) => {
    return isLoading ? (
      <Skeleton width="100%" height="2rem" />
    ) : (
      <span>
        {rowData.voucher.voucherNo} / {rowData.parcel}
      </span>
    );
  };

  const statusBodyTemplate = (rowData) => {
    let a = "Yeni Giriş";

    if (rowData.order != null) {
      a = "İş Emri Oluşturuldu";
    }
   // else{
   //   if(rowData.voucher.sortCodeId=="29")
   //   {
   //     a = "Tebdil Giriş";
   //   } else if(rowData.voucher.sortCodeId=="28")
   //     {
   //       a = "Ham Giriş";
   //     }
   // }
   // console.log(rowData.voucher.sortCodeId)
    return isLoading ? (
      <Skeleton width="100%" height="2rem" />
    ) : (
      <span >
        <Tag
          className="w-full p-2"
          value={a}
          severity={getSeverityStatuses(a)}
        />
      </span>
    );
  };

  const [expandedRows, setExpandedRows] = useState(null);

  const rowExpansionTemplate = (data) => {

    return (
      <div className="p-2">
        {data && data.order && data.order.workOrders ? (
          data.order.workOrders.map((workOrder) => (
            <div key={workOrder.id}>
              <div className="grid    ">
                <div className="col-6 md:col-4   ">
                  <div className="p-inputgroup flex-1 pb-2">
                    <span className="p-inputgroup-addon   w-8rem">
                      İşletme Barkod
                    </span>
                    <InputText
                      disabled={true}
                      id="description"
                      name="description"
                      value={workOrder.operationBarcode}
                    />
                  </div>
                </div>
                <div className="col-6 md:col-4   ">
                  <div className="p-inputgroup flex-1 pb-2">
                    <span className="p-inputgroup-addon   w-8rem">
                      Sipariş No
                    </span>
                    <InputText
                      disabled={true}
                      id="description"
                      name="description"
                      value={data.order.orderNo}
                    />
                  </div>
                </div>
                <div className="col-6 md:col-4   ">
                  <div className="p-inputgroup flex-1 pb-2">
                    <span className="p-inputgroup-addon   w-8rem">
                      İş Emri Tarihi
                    </span>
                    <InputText
                      disabled={true}
                      id="description"
                      name="description"
                      value={workOrder.workOrderDate}
                    />
                  </div>
                </div>
                <div className="col-6 md:col-4   ">
                  <div className="p-inputgroup flex-1 pb-2">
                    <span className="p-inputgroup-addon   w-8rem">
                      Termin Tarihi
                    </span>
                    <InputText
                      disabled={true}
                      id="description"
                      name="description"
                      value={workOrder.deadline}
                    />
                  </div>
                </div>
                <div className="col-6 md:col-4   ">
                  <div className="p-inputgroup flex-1 pb-2">
                    <span className="p-inputgroup-addon   w-8rem">
                      İstenilen En
                    </span>
                    <InputText
                      disabled={true}
                      value={data.order.requestedGrammage}
                    />
                  </div>
                </div>

                <div className="col-6 md:col-4   ">
                  <div className="p-inputgroup flex-1 pb-2">
                    <span className="p-inputgroup-addon   w-8rem">
                      İstenilen Gramaj
                    </span>
                    <InputText
                      disabled={true}
                      value={data.order.requestedWidth}
                    />
                  </div>
                </div>
              </div>
              {workOrder.workOrderRoutes &&
                workOrder.workOrderRoutes.length > 0 && (
                  <div>
                    <div className="grid p-flex-wrap  ">
                      {workOrder.workOrderRoutes
                        .sort((a, b) => a.sequenceNo - b.sequenceNo)
                        .map((item) => (
                          <div
                            key={item.id}
                            className="col-3   p-2 "
                          >
                            <div className="relative surface-hover p-card p-4 border-1 surface-border surface-card border-round h-8rem">
                              <Badge
                                className="absolute top-0 left-0 m-2"
                                severity="info"
                                value={item.sequenceNo}
                              />
                              <div className="flex flex-wrap align-items-center justify-content-center gap-2 ">
                                <div className="flex align-items-center gap-2 ">
                                  <span className="font-semibold  ">
                                    {item.station.name}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
            </div>
          ))
        ) : (
          <span>Hata oluştu tekrar deneyin!.</span>
        )}
      </div>
    );
  };

  const columns = [
    {
      field: "voucher.date",
      header: "Tarih",
      filter: true,
      showClearButton: false,
      showFilterMenu: false,
      style: { width: "9rem" },
      filterElement: (options) => (
        <TitleFilterDate
          {...options}
          placeholder="Tarih Ara"
          field={"voucher.date"}
          operator="eq"
          filtreler={filtreler}
          setFiltreler={setFiltreler}
          setFilters={setFilters}
          sort={sort}
        />
      ),
    },
    {
      field: "voucher.current.title",
      header: "Cari",
      showClearButton: false,
      style: { minWidth: "12rem" },
      filter: true,
      align: "center",
      showFilterMenu: false,
      filterElement: (options) => (
        <TitleFilter
          {...options}
          placeholder="Cari Ara"
          field={"voucher.current.title"}
          operator="contains"
          filtreler={filtreler}
          setFiltreler={setFiltreler}
          setFilters={setFilters}
          sort={sort}
        />
      ),
    },
    {
      field: "combinedField",
      header: "Belge No / Parti",
      style: {   width: "14rem" },
      filter: true,
      align: "left",
      showClearButton: false,
      showFilterMenu: false,
      body: (rowData) => partiBody(rowData),
      filterElement: (options) => (
        <div className="flex gap-1">
          <TitleFilter
            {...options}
            placeholder="Belge"
            field={"voucher.voucherNo"}
            operator="contains"
            filtreler={filtreler}
            setFiltreler={setFiltreler}
            setFilters={setFilters}
            sort={sort}
          />

          <TitleFilter
            {...options}
            placeholder="Parti"
            field={"parcel"}
            operator="eq"
            filtreler={filtreler}
            setFiltreler={setFiltreler}
            setFilters={setFilters}
            sort={sort}
          />
        </div>
      ),
    },
    {
      field: "stock.name",
      header: "Stok",
      align: "center",
      showClearButton: false,
      style: { minWidth: "4rem" },
      filter: true,
      showFilterMenu: false,
      filterElement: (options) => (
        <TitleFilter
          {...options}
          placeholder="Stok Ara"
          field={"stockId"}
          operator="contains"
          filtreler={filtreler}
          setFiltreler={setFiltreler}
          setFilters={setFilters}
          sort={sort}
        />
      ),
    },
    {
      field: "meters",
      header: "Metre",
      align: "center",
      showClearButton: false,
      style: { width: "6rem" },
      filter: true,
      showFilterMenu: false,
      filterElement: (options) => (
        <TitleFilter
          {...options}
          field={"meters"}
          operator="eq"
          filtreler={filtreler}
          setFiltreler={setFiltreler}
          setFilters={setFilters}
          sort={sort}
        />
      ),
    },
    {
      field: "kilos",
      header: "Kilo",
      align: "center",
      showClearButton: false,
      style: { width: "6rem" },
      filter: true,
      showFilterMenu: false,
      filterElement: (options) => (
        <TitleFilter
          {...options}
          field={"kilos"}
          operator="eq"
          filtreler={filtreler}
          setFiltreler={setFiltreler}
          setFilters={setFilters}
          sort={sort}
        />
      ),
    },
    {
      field: "rollCount",
      header: "Top",
      align: "center",
      filterField: "top",
      showClearButton: false,
      style: { width: "6rem" },
      filter: true,
      showFilterMenu: false,
      filterElement: (options) => (
        <TitleFilter
          {...options}
          field={"rollCount"}
          operator="eq"
          filtreler={filtreler}
          setFiltreler={setFiltreler}
          setFilters={setFilters}
          sort={sort}
        />
      ),
    },
    {
      field: "order.orderDefinition.name",
      header: "İşlem",
      align: "center",
      filterField: "islem",
      showClearButton: false,
      style: { minWidth: "4rem" },
      filter: true,
      showFilterMenu: false,
      filterElement: (options) => (
        <TitleFilter
          {...options}
          placeholder="İşlem Ara"
          field={"order.orderNo"}
          operator="contains"
          filtreler={filtreler}
          setFiltreler={setFiltreler}
          setFilters={setFilters}
          sort={sort}
        />
      ),
    },

    {
      field: "status",
      header: "Durum",
      align: "center",
      filterField: "status",
      showClearButton: false,
      filter: true,
      showFilterMenu: false,
      style: { width: "4rem" },
      body: (rowData) => statusBodyTemplate(rowData),
      filterElement: (options) => (
        <TitleFilterStatus
          {...options}
          placeholder="Durum seçin"
          field="status"
          filtreler={filtreler}
          setFiltreler={setFiltreler}
          setFilters={setFilters}
          sort={sort}
        />
      ),
    },
    {
      header: "/",
      alignHeader: "center",
      align: "center",
      headerStyle: { width: "4rem", textAlign: "center" },
      bodyStyle: { textAlign: "center", overflow: "visible" },
      body: actionBodyTemplate,
    },
  ];

  return (
    <div className="surface-card p-2 border-round-sm ">
      <Toast ref={toast} />
      <ConfirmDialog />
      <PlanningAddEdit
        setVisible={setVisible}
        visible={visible}
        dataRow={dataRow}
        onDialogClose={handleCloseDialog}
        toast={toast}
      />
      <EDataTable
        data={data}
        columns={columns}
        totalRecords={totalRecords}
        first={first}
        rows={rows}
        onPageChange={onPageChange}
        header={renderHeader()}
        loading={isLoading}
        expandedRows={expandedRows}
        onRowToggle={(e) => setExpandedRows(e.data)}
        detail={true}
        rowExpansionTemplate={rowExpansionTemplate}
      />
    </div>
  );
};

export default Planning;
