import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  bakim:false
};

const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
     setBakim: (state, action) => {
      state.bakim = action.payload;
    },
  },
});

export const { setLoading,setBakim } = loadingSlice.actions;
export default loadingSlice.reducer;
