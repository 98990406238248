import React, { useEffect, useState, useRef, useCallback } from "react"; 
import {
  deleteCurrentService,
  getCurrentsListService,
} from "../../services/currentsServices"; 
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import CurrentAddEdit from "./CurrentAddEdit";
import { useSelector } from "react-redux";
import EDataTable from "../toolbox/EDataTable";
import TitleFilter from "../toolbox/TitleFilter";
import GlobalFilter from "../toolbox/GlobalFilter";
const Currents = () => {
 
  const [first, setFirst] = useState(0);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState(10);
  const [data, setData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const sort = [
    {
      field: "code",
      dir: "desc",
    },
  ];
  const [filters, setFilters] = useState({sort})
  const [visible, setVisible] = useState(false);
  const [filtreler, setFiltreler] = useState([]);
  const onPageChange = (event) => {
    setFirst(event.first);
    setPage(event.page);
    setRows(event.rows);
  }; 
 
  const renderHeader = () => {
    return (
     <div>
       <div className="flex justify-content-between">
        <div className="flex  ">
          <Button
            severity="success"
            type="button"
            icon="pi pi-plus"
            label="Yeni"
            outlined
            onClick={() => handleAddClick()}
          />
        </div>
     
        <div className="flex  gap-1"> 
        <div className="  hidden sm:block w-12  " >
          <GlobalFilter
            service={getCurrentsListService}
            setData={setData}
            rows={rows}
            globalFilterFields={["title", "phone", "code"]}
          />
        </div>
         <div>
         <Button
            type="button"
            icon="pi pi-refresh"
            outlined
            onClick={() => fetchData()}
          />
         </div>
        </div>
      </div>
      <div className=" block  pt-2 sm:hidden w-12 " >
          <GlobalFilter
            service={getCurrentsListService}
            setData={setData}
            rows={rows}
            globalFilterFields={["title", "phone", "code"]}
          />
        </div>
     </div>
    );
  };


  const fetchData = useCallback(async () => {
    try {
      const result = await getCurrentsListService(page, rows, filters);
      setData(result.items);
      setTotalRecords(result.count);
    } catch (error) {
      console.error("Veri alınamadı:", error);
    }
  }, [page, rows, filters]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const [dataRow, setRowData] = useState(null);

  const handleCloseDialog = () => {
    fetchData();
  };
  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          text
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success p-mr-2"
          onClick={() => handleEditClick(rowData)}
        />
        <Button
          text
          icon="pi pi-trash"
          className="p-button-rounded p-button-danger"
          onClick={() => handleDeleteClick(rowData)}
        />
      </React.Fragment>
    );
  };
    const isLoading = useSelector((state) => state.loading.isLoading);
  const header = renderHeader();
  const toast = useRef(null);
  const handleEditClick = (rowData) => {
    setVisible(true);
    setRowData(rowData);
  };
  const handleAddClick = () => {
    setRowData(null);
    setVisible(true);
  };

  const handleDeleteClick = (rowData) => {
    confirmDialog({
      message: "Cariyi silmek üzeresin! Emin misin?",
      header: "Cari Siliniyor",
      icon: "pi pi-info-circle",
      defaultFocus: "reject",
      acceptClassName: "p-button-danger",
      position: "right",
      acceptLabel: "Evet",
      rejectLabel: "Hayır",
      accept: () => accept(rowData),
      reject,
    });
  };


  const accept = async (rowData) => {
    await deleteCurrentService(rowData);
    fetchData();
    toast.current.show({
      severity: "info",

      detail: "Cari Başarıyla Silindi",
      life: 3000,
    });
  };


  const reject = () => {
    toast.current.show({
      severity: "warn",
      detail: "Cari silme işlemi iptal edildi.",
      life: 3000,
    });
  };

  const columns = [
    {
      field: "code",
      header: "Cari Kodu",
      filter: true,
      showFilterMenu: false,
      showClearButton: false,
      style: { minWidth: "12rem" },
      filterElement: (options) => (
        <TitleFilter
          {...options}
          placeholder="Kod Ara"
          field={"code"}  
           operator ="contains"  
          filtreler={filtreler}
          setFiltreler={setFiltreler} 
          setFilters={setFilters}
          sort={sort}
        />
      ),
    },
    {
      field: "title",
      header: "Cari Ünvan",
      filterField: "title",
      style: { minWidth: "12rem" },
      filter: true,
      showFilterMenu: false,
      showClearButton: false,
      filterElement: (options) => (
        <TitleFilter
          {...options}
          placeholder="Ünvan Ara"
          field={"title"}
          operator ="contains"  
          filtreler={filtreler}
          setFiltreler={setFiltreler}
          setFilters={setFilters}
          sort={sort}
        />
      ),
    },
    {
      field: "phone",
      header: "Telefon",
      filterField: "phone",
      style: { minWidth: "12rem" },
      filter: true,
      showFilterMenu: false,
      showClearButton: false,
      filterElement: (options) => (
        <TitleFilter
          {...options}
          placeholder="Telefon Ara"
          field={"phone"}
          operator ="contains"  
          filtreler={filtreler}
          setFiltreler={setFiltreler}
          setFilters={setFilters}
          sort={sort}
        />
      ),
    },
    {
      field: "email",
      header: "E-Mail",
      filterField: "email",
      style: { minWidth: "12rem" },
      filter: true,
      showFilterMenu: false,
      showClearButton: false,
      filterElement: (options) => (
        <TitleFilter
          {...options}
          placeholder="Email Ara"
          field={"email"}
          operator ="contains"  
          filtreler={filtreler}
          setFiltreler={setFiltreler}
          setFilters={setFilters}
          sort={sort}
        />
      ),
    },
    {
      header: "/",
      alignHeader: "center",
      align: "center",
      headerStyle: { width: "4rem", textAlign: "center" },
      bodyStyle: { textAlign: "center", overflow: "visible" },
      body: actionBodyTemplate,
    },
  ];

  return (
    <div className="surface-card p-2 border-round-sm ">
      <Toast ref={toast} />
      <ConfirmDialog />
      <CurrentAddEdit
        setVisible={setVisible}
        visible={visible}
        dataRow={dataRow}
        onDialogClose={handleCloseDialog}
        toast={toast}
      />
      <EDataTable
        data={data}
        columns={columns}
        totalRecords={totalRecords}
        first={first}
        rows={rows}
        onPageChange={onPageChange} 
        header={header}
        loading={isLoading}
      />
    </div>
  );
};

export default Currents;
