import React from "react";
import { Card } from "primereact/card";
import SkeletonCard from "../common/SkeletonCard";
import { useSelector } from "react-redux";

const StatCard = ({ title, value, percentage, trend, icon, style }) => {

  const loading =useSelector((state) => state.loading.isLoading)

  return (
    
   <div className="flex-auto m-0">
     {loading?<SkeletonCard/>:
      <Card 
        className=" overflow-hidden  p-shadow-4 p-mb-3 p-p-3"  style={style}
   
      >
            <div className="card h-full">
            <span className="font-semibold text-ml">{title}</span>
            <div className="flex justify-content-between align-items-start mt-3">
                <div className="w-6"><span className="text-4xl font-bold text-900">
                    {value}
                </span> 
                <div className="text-green-500">
                    <span className="font-medium">
                    {percentage}%
                    </span>
                    {trend > 0 ? "↑" : "↓"}
                </div></div>
                <div className="w-2">{icon}</div>
                
            </div>
           
          </div>
      </Card>}
    </div>
  );
};
export default StatCard;
