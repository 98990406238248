import React, { useRef, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { Tag } from "primereact/tag";
import { getSeverityStatuses } from "./planningStatuses";

const TitleFilterStatus = (props) => {
  const {
    placeholder,
 
    filtreler,
    setFilters,
    setFiltreler, 
    sort,
  } = props;
  const timerRef = useRef(null);

  const onFilterChange = (newValue) => {
    try {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }

      timerRef.current = setTimeout(async () => {

      let field;
      let operator;
        if(newValue=="Yeni Giriş"){
          field ="order";
          operator="isnull"
        }else if(newValue=="İş Emri Oluşturuldu"){
          field ="order";
          operator="isnotnull"
        }else{
          
        }
        const newFilter = { field: field, value: newValue };
        const existingFilterIndex = filtreler.findIndex(
          (filter) => filter.field === field
        );

        let updatedFiltreler;

        if (newValue === "" || newValue === null || newValue === "NaN"|| newValue===undefined) {
          // Yeni değer boşsa mevcut filtreyi kaldır
          updatedFiltreler = filtreler.filter(
            (filter) => filter.field !== "order"
          );
        } else {
          // Yeni değer boş değilse mevcut filtreyi güncelle veya ekle
          updatedFiltreler =
            existingFilterIndex > -1
              ? filtreler.map((filter, index) =>
                  index === existingFilterIndex ? newFilter : filter
                )
              : [...filtreler, newFilter];
        }

        let filterQuery;
        if (updatedFiltreler.length === 0) {
          filterQuery = { sort }; // Hiç değer yoksa sadece sıralamayı gönder
        } else {
          const firstFilter = {
            field: updatedFiltreler[0].field,
            operator: operator,
          
            logic: "and",
          };

          const remainingFilters = updatedFiltreler.slice(1).map((filter) => ({
            field: filter.field,
            operator: operator,
            value: filter.value,
            logic: "and",
          }));

          filterQuery = {
            sort: sort,
            filter: {
              ...firstFilter,
              filters: remainingFilters,
            },
          };
        }


        // const result = await service(0, rows, filterQuery);
        setFilters(filterQuery);
        setFiltreler(updatedFiltreler);
        // setData(result.items);
      }, 500);
    } catch (error) {
      console.error("Veri alınamadı:", error);
    }
  };

  const handleDropdownChange = (e) => {
    setSelectedStatus(e.value);
    const newValue = e.value;
 
    onFilterChange(newValue);
  };

  const [selectedStatus, setSelectedStatus] = useState(null);
  const status = ["Yeni Giriş", "İş Emri Oluşturuldu"];

  const statusItemTemplate = (option) => {
    const severityClass = getSeverityStatuses(option);
    return (
      <div 
      className={`  p-tag ${severityClass}`} 
      style={{ backgroundColor:"lightblue", display: 'inline-block', padding: '0.5rem', borderRadius: '4px' }} 
    >
      {option}
    </div>
    );
  };
  const selectedValueTemplate = (option, props) => {
    const severityClass = getSeverityStatuses(option);
    if (option) {
        return (
          <div 
          className={` p-1 px-3 m-0 p-tag ${severityClass}`}
          style={{  display: 'inline-block',   borderRadius: '4px' }} 
 
         >
          {option}
        </div>
        );
    }

    return <span>{props.placeholder}</span>;
};
  return (
    <Dropdown
      showClear
      value={selectedStatus}
      options={status} 
      onChange={handleDropdownChange}
      placeholder={placeholder}
      className="w-12rem"
      
    />
  );
};

export default TitleFilterStatus;
