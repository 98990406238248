// src/components/NotFound.js
import React from 'react';
import { Button } from 'primereact/button'; 

const NotFound = () => {
 

    return (
        <div style={{ textAlign: 'center', marginTop: '50px' }}>
            <h1>404 - Sayfa Bulunamadı</h1>
            <p>Üzgünüz, aradığınız sayfa mevcut değil.</p>
            <Button label="Önceki Sayfaya Dön"  />
        </div>
    );
};

export default NotFound;
