import React, { useEffect, useState, useRef } from "react";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import AcceptGoodsAddEdit from "./AcceptGoodsAddEdit";
import { deleteAcceptGoodService, getAcceptGoodsService } from "../../services/acceptGoodsServices";
import { useSelector } from "react-redux";
import TitleFilter from "../toolbox/TitleFilter";
import EDataTable from "../toolbox/EDataTable";
import { Skeleton } from "primereact/skeleton";
import GlobalFilter from "../toolbox/GlobalFilter";
import TitleFilterDate from "../toolbox/TitleFilterDate";

const AcceptGoods = () => {  
  const [filtreler, setFiltreler] = useState([]);
  const [first, setFirst] = useState(0);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState(10);
  const [data, setData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const sort = [
    {
      field: "createdDate",
      dir: "desc",
    },
  ];
  const [filters, setFilters] = useState({sort})
  const [visible, setVisible] = useState(false);
  const isLoading = useSelector((state) => state.loading.isLoading);
 
  const [dataRow, setRowData] = useState(null);

  
 
  const renderHeader = () => {
    return (
     <div>
       <div className="flex justify-content-between">
        <div className="flex  ">
          <Button
            severity="success"
            type="button"
            icon="pi pi-plus"
            label="Yeni"
            outlined
            onClick={() => handleAddClick()}
          />
        </div>
     
        <div className="flex  gap-1"> 
        <div className="  hidden sm:block w-12  " >
          <GlobalFilter
            service={getAcceptGoodsService}
            setData={setData}
            rows={rows}
            globalFilterFields={["current.title", "serial"]}
          />
        </div>
         <div>
         <Button
            type="button"
            icon="pi pi-refresh"
            outlined
            onClick={() => fetchData()}
          />
         </div>
        </div>
      </div>
      <div className=" block  pt-2 sm:hidden w-12 " >
          <GlobalFilter
            service={getAcceptGoodsService}
            setData={setData}
            rows={rows}
            globalFilterFields={["current.title", "serial"]}
          />
        </div>
     </div>
    );
  };

  const onPageChange = (event) => { 
    setFirst(event.first);
    setPage(event.page);
    setRows(event.rows); 
  };

  const fetchData = async () => {
    try {
      const result = await getAcceptGoodsService(page, rows, filters);
       setData(result.items);   
       console.log(result)
      setTotalRecords(result.count); // Adjust based on your API response structure
    } catch (error) {
      console.error("Veri alınamadı:", error);
    }
  };

  useEffect(() => {
    fetchData(); 
  }, [rows,page,filters ]);
  const header = renderHeader();
  const toast = useRef(null);
 

  const handleCloseDialog = () => {
    fetchData();
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          text
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success "
          onClick={() => handleEditClick(rowData)}
        />  <Button
          text
          icon="pi pi-trash"
          className="p-button-rounded p-button-danger  "
          onClick={() => handleRemoveClick(rowData)}
        />
      </React.Fragment>
    );
  };

  const handleEditClick = (rowData) => {
    setVisible(true);
    setRowData(rowData);
  };  
  const handleRemoveClick =async (rowData) => {
    confirmDialog({
      message: "Girişi silmek üzeresin! Emin misin?",
      header: "Giriş Siliniyor",
      icon: "pi pi-info-circle",
      defaultFocus: "reject",
      acceptClassName: "p-button-danger",
      position: "right",
      acceptLabel: "Evet",
      rejectLabel: "Hayır",
      accept: () => accept(rowData),
      reject,
    });
    
  
  };

  const accept = async (rowData) => {
    await deleteAcceptGoodService(rowData);
    handleCloseDialog();
    toast.current.show({
      severity: "info",

      detail: "Giriş Başarıyla Silindi",
      life: 3000,
    });
  }; 

  const reject = () => {
    toast.current.show({
      severity: "warn",
      detail: "Giriş silme işlemi iptal edildi.",
      life: 3000,
    });
  };
  const handleAddClick = () => {
    setRowData(null);
    setVisible(true);
  };
  const bodyTemplate = (key) => (rowData) => {
    const getNestedValue = (obj, key) => key.split('.').reduce((o, i) => (o ? o[i] : null), obj);

  return isLoading ? (
    <Skeleton width="100%" height="2rem" />
  ) : (
    <span>{getNestedValue(rowData, key)}</span>
  );
  };
 
  const columns = [
    
    {
      field: "date",
      header: "Tarih",  
      filterField: "date",
      style: { minWidth: "12rem" }, 
      filter: true,
      showFilterMenu: false,
      showClearButton: false, 
      filterElement: (options) => (
        <TitleFilterDate
          {...options}
          placeholder="Tarih Ara"
          field={"date"}
          operator ="eq" 
          filtreler={filtreler}
          setFiltreler={setFiltreler}
          setFilters={setFilters} 
          sort={sort}
        />
      ),
    },
    {
      field: "current.title", 
      header: "Cari",
      filter: true,
      showFilterMenu: false,
      showClearButton:false, 
      style: { minWidth: "12rem" },
      body:bodyTemplate("current.title"),
      filterElement: (options) => (
        <TitleFilter
          {...options}
          placeholder="Cari Ara"
          field={"current.title"}
             operator ="contains" 
          filtreler={filtreler}
          setFiltreler={setFiltreler}
          setFilters={setFilters}
          sort={sort}
        />
      ),
    },
    {
      field: "voucherNo",
      header: "Belge No",
      filterField: "voucherNo",
      style: { minWidth: "12rem" },
      filter: true,
      showFilterMenu: false,
      showClearButton: false,
      filterElement: (options) => (
        <TitleFilter
          {...options}
          placeholder="Belge Ara"
          field={"voucherNo"}
             operator ="eq" 
          filtreler={filtreler}
          setFiltreler={setFiltreler}
          setFilters={setFilters}
          sort={sort}
        />
      ),
    },
    {
      field: "sort.description",
      header: "Giriş Türü",
      filterField: "sort.description",
      style: { minWidth: "12rem" },
      filter: true,
      showFilterMenu: false,
      showClearButton: false,
      body:bodyTemplate("sort.description"),
      filterElement: (options) => (
        <TitleFilter
          {...options}
          placeholder="Tür Ara"
          field={"sort.description"}  
             operator ="contains" 
          filtreler={filtreler}
          setFiltreler={setFiltreler}
          setFilters={setFilters}
          sort={sort}
        />
      ),
    },
    {
      header: "/",
      alignHeader: "center",
      align: "center",
      headerStyle: { width: "4rem", textAlign: "center" },
      bodyStyle: { textAlign: "center", overflow: "visible" },
      body: actionBodyTemplate,
    },
  ];

  return (
    <div className="surface-card p-2 border-round-sm">
      <Toast ref={toast} />
      <ConfirmDialog />
      <AcceptGoodsAddEdit
        setVisible={setVisible}
        visible={visible}
        data={dataRow}
        onDialogClose={handleCloseDialog}
        toast={toast}
      />
      <EDataTable
        data={data}
        columns={columns}
        totalRecords={totalRecords}
        first={first}
        rows={rows}
        onPageChange={onPageChange} 
        header={header}
        loading={isLoading}
      />
    </div>
  );
};

export default AcceptGoods;
