import React, { useState, useEffect, useMemo } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Divider } from "primereact/divider";
import {
  addAcceptGoodService,
  addAcceptGoodsRowService,
  addAcceptGoodsService,
  deleteAcceptGoodService,
  deleteAcceptGoodsRowService,
  getAcceptGoodsRowsService,
  updateAcceptGoodsRowService,
} from "../../services/acceptGoodsServices";
import { getCurrentsListService } from "../../services/currentsServices";
import { getStocksListService } from "../../services/stocksServices";
import { useSelector } from "react-redux";
import { getCodesService } from "../../services/codesServices";
import { InputNumber } from "primereact/inputnumber";
import { confirmDialog } from "primereact/confirmdialog";
const defaultFormData = {
  id: null,
  currentId: 0,
  date: new Date().toISOString(),
  serial: "GR",
  voucherNo: 1,
  sortCodeId: 0,
  type: "G",
  voucherRows: [],
};

const defaultPartiData = {
  voucherId: null,
  stockId: null,
  stock: { name: null },
  parcel: null,
  rollCount: null,
  meters: null,
  kilos: null,
  details: [{}],
};

const AcceptGoodsAddEdit = ({
  visible,
  setVisible,
  data,
  onDialogClose,
  toast,
}) => {
  const isLoading = useSelector((state) => state.loading.isLoading);
  const defaultData = useMemo(() => defaultFormData, []);
  const defaultParti = useMemo(() => defaultPartiData, []);
  const [validationErrors, setValidationErrors] = useState({});
  const [validationErrors2, setValidationErrors2] = useState({});

  const [formData, setFormData] = useState(defaultFormData);
  const [cariData, setCariData] = useState([]);
  const [stokData, setStokData] = useState([]);
  const [editRow, setEditRow] = useState(false);
  const [onay, setOnay] = useState(false);
  
  const [partiRow, setPartiRow] = useState(defaultPartiData);
  const [cID, setCID] = useState("0");
  const [voucherId ,setVoucherId] = useState("0");

  
  useEffect(() => {
    if (visible) {
      if (data) {
        setFormData(data);
        setVoucherId(data.id);
      
        setOnay(true);
        stockList(data.currentId);
      } else {
        setFormData(defaultData);
        setOnay(false);
      }
      setPartiRow(defaultParti);
      setEditRow(false);
      setValidationErrors({});
      setValidationErrors2({});
    }
  }, [data, visible, defaultData, defaultParti]);

 
  const [sorts, setSorts] = useState([]);
  const handlePartiChange = (e, name) => {
    const floatValue = parseFloat(e);
    setPartiRow({ ...partiRow, [name]: floatValue });
    setValidationErrors2({ ...validationErrors2, [name]: null });
  };
  const handleDropdownPartiChange = (e) => {
    const { name, value } = e.target;

    const selectedStock = stokData.find((stock) => stock.id === value); // Seçili stok bilgilerini al
    setValidationErrors2({ ...validationErrors2, [name]: null });
    setPartiRow({
      ...partiRow,
      stockId: value,
      stock: { name: selectedStock ? selectedStock.name : "" }, // Stok adını burada ayarla
    });
    setPartiRow((prevState) => ({
      ...prevState,
      voucherId: voucherId,
    }));
  };

  const handleDropdownChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
    setValidationErrors({ ...validationErrors, [name]: null });
    if (name === "currentId") {
      setCID(String(value));
    }
    setPartiRow((prevState) => ({
      ...prevState,
      voucherId: voucherId,
    }));
  };

  const handleDateChange = (e) => {
    const { value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      date: value,
    }));
  };

 
  const sortsOptions = sorts.map((sort) => ({
    label: sort.description,
    value: sort.id,
  }));
  const handleRemoveVoucher =async () => {
    confirmDialog({
      message: "Girişi silmek üzeresin! Emin misin?",
      header: "Giriş Siliniyor",
      icon: "pi pi-info-circle",
      defaultFocus: "reject",
      acceptClassName: "p-button-danger",
      position: "center",
      acceptLabel: "Evet",
      rejectLabel: "Hayır",
      accept,
      reject,
    });
    
  
  };

  const accept = async () => {
    await deleteAcceptGoodService(formData);
    onDialogClose();
    toast.current.show({
      severity: "info",

      detail: "Giriş Başarıyla Silindi",
      life: 3000,
    });
    setVisible(false);
  }; 

  const reject = () => {
    toast.current.show({
      severity: "warn",
      detail: "Giriş silme işlemi iptal edildi.",
      life: 3000,
    });
  };
  const fetchData = async () => {
    try {
      const resultSorts =
        sorts.length <= 0 ? await getCodesService(7) : { items: sorts };

      setSorts(resultSorts.items);
      const result = await getCurrentsListService(0, 1000, {}); // Sayfa indeksi ve satır sayısı
      //   const result2 = await getStocksListService(0, 1000, {}); // Sayfa indeksi ve satır sayısı
      setCariData(result.items);
      //   setStokData(result2.items);
    } catch (error) {
      console.error("Veri alınamadı:", error);
    }
  };

  const voucherRowList = async ()=>{
  
    const result =await getAcceptGoodsRowsService(voucherId);
    setFormData((p)=>({
      ...p,
      voucherRows:result.voucherRows
    }))
  }
  const stockNameTemplate = (rowData) => {
    return rowData.stock ? rowData.stock.name : "";
  };
  const removeDetail = async (rowData, index) => {
    
      await deleteAcceptGoodsRowService(rowData);
      onDialogClose();
    
      voucherRowList(); 
  };

  const addDetail = async () => {
  
    const errors2 = validateVoicherRow(partiRow);
    if (Object.keys(errors2).length > 0) {
      setValidationErrors2(errors2);
      return;
    }
    const result = await addAcceptGoodsRowService(partiRow).then(voucherRowList());
   
    onDialogClose();
    voucherRowList();
    setEditRow(false);
    setPartiRow(defaultPartiData);
  };
  // parti güncelleme
  const handlePartiUpdate = async () => {
    await updateAcceptGoodsRowService(partiRow);
    onDialogClose();
    voucherRowList();

    setEditRow(false);
    setPartiRow(defaultPartiData);
  };
  const handleEditClick = (rowData) => {
    setValidationErrors2({});
    setEditRow(true);
    setPartiRow(rowData);
  };
  const handleRefreshClick = () => {
    setFormData(defaultData);

    setPartiRow(defaultParti);
    setOnay(false);
    setValidationErrors({});
    setValidationErrors2({});
  };
  const handleOnayClick = async () => {
    const errors = validateVoicher(formData);
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }

    const response = await addAcceptGoodService(formData);
    setVoucherId(response.id);
    setFormData((prevState) => ({
      ...prevState,
      id: response.id,
    }));
    await stockList(cID);
    onDialogClose();
    setOnay(true);
   
      setPartiRow((prevState) => ({
        ...prevState,
        voucherId: response.id,
      }));
   
  };

  const stockList = async (id) => {
    try {
      const result2 = await getStocksListService(0, 1000, {
        filter: {
          field: "currentId",
          operator: "eq",
          value: String(id),
          logic: "and",
          filters: [],
        },
      });

      setStokData(result2.items);
    } catch (error) {
      console.error("Veri alınamadı:", error);
    }
  };
  const handleCancelClick = () => {
    setEditRow(false);
    setPartiRow(defaultPartiData);
  };

  const actionPartiRowTemplate = (rowData, { rowIndex }) => {
    return (
      <React.Fragment>
        <Button
          text
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success p-mr-2"
          onClick={() => handleEditClick(rowData)}
        />
        <Button
          text
          icon="pi pi-trash"
          className="p-button-rounded p-button-danger"
          onClick={() => removeDetail(rowData, rowIndex)}
        />
      </React.Fragment>
    );
  };

  useEffect(() => {
    fetchData();
  }, []);
  const validateVoicher = (data) => {
    let errors = {};

    if (!data.sortCodeId) {
      errors.sortCodeId = "Tür gereklidir.";
    }
    if (!data.currentId) {
      errors.currentId = "Cari gereklidir.";
    }

    return errors;
  };

  const validateVoicherRow = (data) => {
    let errors = {};

    if (!data.stockId) {
      errors.stockId = "Stok gereklidir.";
    }
    if (!data.parcel) {
      errors.parcel = "Parti gereklidir.";
    } else {
      const parcelControl = formData.voucherRows.find(
        (parti) => parti.parcel === data.parcel
      );

      if (parcelControl) {
        errors.parcel = "Aynı Parti Girilemez";
      }
    }
    if (!data.rollCount) {
      errors.rollCount = "TopSayısı gereklidir.";
    }
    if (!data.meters) {
      errors.meters = "Metre gereklidir.";
    }

    return errors;
  };
  return (
    <Dialog
      className="max-w-full w-11 md:w-11 xl:w-8"
      header={data ? "Mal Giriş Fişi Güncelle" : "Mal Giriş Fişi Ekle"}
      visible={visible}
      onHide={() => setVisible(false)}
    >
      <div>
        <div className="  formgrid grid">
          <div className="col-12 md:col-6">
            <div className="p-inputgroup flex-1 w-12 pb-2">
              <span className="p-inputgroup-addon w-7rem">Tarih</span>
              <Calendar
                id="date"
                className="p-inputgroup"
                value={new Date(formData.date)}
                onChange={handleDateChange}
                disabled={onay ? true : false}
              />
            </div>
          </div>
          <div className="col-12 md:col-6">
            <div className="p-inputgroup flex-1 pb-2">
              <span className="p-inputgroup-addon w-7rem">Giriş Türü</span>
              <Dropdown
                id="sortCodeId"
                name="sortCodeId"
                value={formData.sortCodeId}
                options={sortsOptions}
                onChange={(e) => handleDropdownChange("sortCodeId", e.value)}
                disabled={onay ? true : false}
              />
              {validationErrors.sortCodeId && (
                <span className="p-inputgroup-addon w-10rem">
                  <small className="p-error">
                    {validationErrors.sortCodeId}
                  </small>
                </span>
              )}
            </div>
          </div>
          <div className={`col-12 md:${data ? "col-6" : "col-9"}`}>
            <div className="p-inputgroup flex-1 pb-2">
              <span className="p-inputgroup-addon w-7rem">Cari</span>
              <Dropdown
                id="currentId"
                name="currentId"
                value={formData.currentId}
                options={cariData.map((item) => ({
                  label: item.title,
                  value: item.id,
                }))}
                onChange={(e) => handleDropdownChange("currentId", e.value)}
                optionLabel="label"
                optionValue="value"
                filter
                disabled={onay ? true : false}
              />
              {validationErrors.currentId && (
                <span className="p-inputgroup-addon w-10rem">
                  <small className="p-error">
                    {validationErrors.currentId}
                  </small>
                </span>
              )}
            </div>
          </div>
          {data ? (
            <div className={`col-12 md:col-6 `}>
              <div className="p-inputgroup flex-1 pb-2">
                <span className="p-inputgroup-addon w-7rem">BelgeNo</span>
                <InputText
                  id="voucherNo"
                  name="voucherNo"
                  value={formData.voucherNo}
                  disabled={onay ? true : false}
                />
                {validationErrors.currentId && (
                  <span className="p-inputgroup-addon w-10rem">
                    <small className="p-error">
                      {validationErrors.currentId}
                    </small>
                  </span>
                )}
              </div>
            </div>
          ) : (
            <div className="col-12 md:col-3">
              <Button
                className="w-full"
                severity="success"
                label={onay ? "Onaylandı" : "Onayla"}
                icon={onay ? "pi pi-check" : "pi pi-sort-down-fill"}
                outlined
                loading={isLoading}
                onClick={handleOnayClick}
                disabled={onay ? true : false}
              />
            </div>
          )}
        </div>
        <Divider className="mt-0 mb-2">
          Parti Ekle <i className="pi pi-sort-down" />
        </Divider>
        <div className="grid">
          <div className="col-12 ">
            <div className="flex-1 pb-1 	">
              <div className="surface-hover 	 pb-1 border-round-sm">
                <DataTable
                  size="small"
                  className="surface-border border-1 border-round-sm	"
                  scrollHeight="25vh"
                 rowHover
                  showGridlines
                  emptyMessage={"Kayıt Eklenmedi"}
                  stripedRows
                  value={formData.voucherRows}
                  tableStyle={{ minWidth: "50rem" }}
                  loading={isLoading}
                >
                  <Column
                    field="stock.name"
                    header="Stok"
                    body={stockNameTemplate}
                  ></Column>
                  <Column field="parcel" header="Parti"></Column>
                  <Column field="meters" header="Metre"></Column>
                  <Column field="kilos" header="Kilo"></Column>
                  <Column field="rollCount" header="Top"></Column>
                  <Column
                    header="/"
                    alignHeader={"center"}
                    align={"center"}
                    headerStyle={{ width: "8rem", textAlign: "center" }}
                    bodyStyle={{ textAlign: "center", overflow: "visible" }}
                    body={actionPartiRowTemplate}
                  />
                </DataTable>
              </div>
            </div>
          </div>
          <div className="col-12 md:col-7 ">
            <div className="p-inputgroup flex-1 pb-2">
              <span className="p-inputgroup-addon w-7rem">Stok</span>
              <Dropdown
                id="stockId"
                name="stockId"
                value={partiRow.stockId}
                options={stokData.map((item) => ({
                  label: item.name,
                  value: item.id,
                }))}
                onChange={handleDropdownPartiChange}
                optionLabel="label"
                optionValue="value"
                filter
                disabled={onay == false ? true : false}
              />
              {validationErrors2.stockId && (
                <span className="p-inputgroup-addon w-10rem">
                  <small className="p-error">{validationErrors2.stockId}</small>
                </span>
              )}
            </div>
          </div>
          <div className="col-12 md:col-5">
            <div className="p-inputgroup flex-1 pb-2">
              <span className="p-inputgroup-addon w-7rem">Parti</span>
              <InputNumber
                id="parcel"
                name="parcel"
                value={partiRow.parcel}
                onChange={(e) => handlePartiChange(e.value, "parcel")}
                disabled={onay == false ? true : false}
              />
              {validationErrors2.parcel && (
                <span className="p-inputgroup-addon w-10rem">
                  <small className="p-error">{validationErrors2.parcel}</small>
                </span>
              )}
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="p-inputgroup flex-1 pb-2">
              <span className="p-inputgroup-addon w-7rem md:w-4rem">Metre</span>
              <InputNumber
                id="meters"
                name="meters"
                value={partiRow.meters}
                onChange={(e) => handlePartiChange(e.value, "meters")}
                disabled={onay == false ? true : false}
              />
              {validationErrors2.meters && (
                <span className="p-inputgroup-addon w-10rem">
                  <small className="p-error">{validationErrors2.meters}</small>
                </span>
              )}
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="p-inputgroup flex-1 pb-2">
              <span className="p-inputgroup-addon w-7rem md:w-4rem">Kilo</span>
              <InputNumber
                id="kilos"
                name="kilos"
                value={partiRow.kilos}
                onChange={(e) => handlePartiChange(e.value, "kilos")}
                disabled={onay == false ? true : false}
              />
              {validationErrors2.kilos && (
                <span className="p-inputgroup-addon w-10rem">
                  <small className="p-error">{validationErrors2.kilos}</small>
                </span>
              )}
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="p-inputgroup flex-1 pb-2">
              <span className="p-inputgroup-addon w-7rem md:w-4rem">Top</span>
              <InputNumber
                id="rollCount"
                name="rollCount"
                value={partiRow.rollCount}
                onChange={(e) => handlePartiChange(e.value, "rollCount")}
                disabled={onay == false ? true : false}
              />
              {validationErrors2.rollCount && (
                <span className="p-inputgroup-addon w-10rem">
                  <small className="p-error">
                    {validationErrors2.rollCount}
                  </small>
                </span>
              )}
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="p-inputgroup flex-1 pb-2 ">
              <Button
                className="w-full"
                severity={editRow ? "success" : "info"}
                label={editRow ? "Güncelle" : "Ekle"}
                icon="pi pi-sort-up-fill"
                outlined
                onClick={editRow ? handlePartiUpdate : addDetail}
                disabled={onay == false ? true : false}
              />
              {editRow && (
                <Button
                  className="w-full"
                  severity="danger"
                  label="İptal"
                  icon="pi pi-times"
                  outlined
                  onClick={handleCancelClick}
                />
              )}
            </div>
          </div>
        </div>
        <Divider className="mt-2 mb-3"></Divider>
        <div className="grid">
          {data ? (
            <div className={` col-6    `}>
              <Button
                className="w-full"
                severity="danger"
                label="Sil"
                icon="pi pi-trash"
                outlined
                loading={isLoading}
                onClick={handleRemoveVoucher}
              />
            </div>
          ) : (
          <></>
          )}
          <div
            className={`col-6  p-d-flex p-jc-end   `}
          >
            <Button
              className="w-full"
              severity="help"
              label="Yazdır"
              icon="pi pi-print"
              outlined
              loading={isLoading}
              disabled ={formData.voucherRows.length>0?false:true}
            />
          </div>

          {data ? (
            <></>
          ) : (
            <div className={" p-d-flex p-jc-end col-6"}>
              <Button
                className="w-full"
                severity="info"
                label="Yenile"
                icon="pi pi-refresh"
                outlined
                loading={isLoading}
                onClick={handleRefreshClick}
              />
            </div>
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default AcceptGoodsAddEdit;
