import React from 'react';
import { useSelector } from 'react-redux';
import { ProgressBar } from 'primereact/progressbar'; 

const LoadingIndicator = () => {
  const isLoading = useSelector(state => state.loading.isLoading);

  if (!isLoading) return null;

  return (
    <div className="fixed   w-full  ">
      <ProgressBar  mode="indeterminate" style={{ height: '4px' }} />
    </div>
  );
};

export default LoadingIndicator;
