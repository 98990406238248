import React, { useEffect,useState  } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom"; 
import { loadUserFromStorage } from "../../reducers/userSlice";
import { ProgressSpinner } from "primereact/progressspinner";

const PrivateRoute = ({ children }) => {
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  const status = useSelector((state) => state.user.status);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (status === "idle") {
      dispatch(loadUserFromStorage());
    }
  }, [status, dispatch]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh", 
        }}
      >
        <ProgressSpinner />
      </div>
    );
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return children;
};

export default PrivateRoute;
